import { useCallback } from 'react';

import { useActions } from '../../../hooks/useActions';
import { useProcessing } from '../../../hooks/useProcessing';
import { groupsAction } from '../../../store/saga-slices/groups';
import { GROUP_CREATING_PROCESSING } from '../../../store/redux-slices/processes/constants';
import { useFormErrorsActions } from '../../FormErrorProvider/FormErrorProvider.hooks';
import { validateCreateGroupForm } from './createGroupForm.validation';

type UseCreateGroupFormProps = {
  onSentName?: (name: string) => void;
};

export const useCreateGroupForm = ({ onSentName }: UseCreateGroupFormProps) => {
  const SendCreateGroup = useActions(groupsAction.sendCreateGroup);
  const isLoading = useProcessing(GROUP_CREATING_PROCESSING);
  const { addErrors, validateErrors } = useFormErrorsActions();

  const validateForm = useCallback(
    (data: any) => validateCreateGroupForm(data, validateErrors(data)),
    [validateErrors],
  );

  const onSubmitHandler = useCallback(
    (values: any) => {
      const payload = {
        name: values.name,
        error: (err: any) => {
          addErrors(err, values);
        },
      };

      SendCreateGroup(payload);
    },
    [SendCreateGroup, addErrors],
  );

  return { onSubmitHandler, isLoading, validateForm };
};
