import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';

import { FormPage } from '../../../../components/UI/Form/Form';
import { Modal } from '../../../../components/Modal/Modal';
import { ModalTitle } from '../../../../components/Modal/modal.styles';
import { FormSubmitButton } from '../../../../components/UI/Form/FormSubmitButton/FormSubmitButton';
import FamilyMemberForm from '../../../../components/Forms/FamilyMemberForm/familyMemberForm.form';
import { validateFamilyMemberForm } from '../../../../components/Forms/FamilyMemberForm/familyMemberForm.validation';
import { useFamilyMemberForm } from '../../../../components/Forms/FamilyMemberForm/familyMemberForm.hooks';
import FormErrorActivator from '../../../../components/FormErrorProvider/FormErrorActivator/FormErrorActivator';

export const ADD_FAMILY_MEMBER_MODAL_NAME = 'ADD_FAMILY_MEMBER_MODAL';

export const AddFamilyMemberModal = () => {
  const { t } = useTranslation();

  const { onAddSubmitHandler, isLoading } = useFamilyMemberForm();

  return (
    <Modal name={ADD_FAMILY_MEMBER_MODAL_NAME}>
      <Form
        keepDirtyOnReinitialize
        onSubmit={onAddSubmitHandler}
        validate={validateFamilyMemberForm}
      >
        {({ values, dirty, valid, ...renderProps }) => (
          <FormErrorActivator>
            <FormPage onSubmit={renderProps.handleSubmit}>
              <ModalTitle>{t('headers.add-family-member')}</ModalTitle>
              <FamilyMemberForm disabled={isLoading} />
              <FormSubmitButton
                fillButton
                isLoading={isLoading}
                styleType="base-filled"
              >
                {isLoading ? t('buttons.processing') : t('buttons.save')}
              </FormSubmitButton>
            </FormPage>
          </FormErrorActivator>
        )}
      </Form>
    </Modal>
  );
};
