import { createSelector } from 'reselect';

import { RootState } from '../index';
import { CountryBriefIntelligence } from '../../../api/base-api';

export const countryBriefStateSelector = (state: RootState) =>
  state.countryBrief;

export const countryBriefSelector = (countryCode: string) =>
  createSelector(
    countryBriefStateSelector,
    (countryBrief): CountryBriefIntelligence => countryBrief[countryCode],
  );
