import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import moment from 'moment';

import { GetMemberLocationQueryResponse } from '../../../api/base-api';

export interface ILocalizationStatus extends GetMemberLocationQueryResponse {
  memberId: string;
}

interface LocationStateItem extends GetMemberLocationQueryResponse {
  lastCheckedAt?: string;
}

const initState: { [x: string]: LocationStateItem } = {};

const localizationSlice = createSlice({
  name: 'location',
  initialState: initState,
  reducers: {
    setLocation: (state, action: PayloadAction<ILocalizationStatus>) => {
      state[action.payload.memberId!] = {
        ...action.payload,
        lastCheckedAt: moment().toISOString(),
      };
    },
    setLocations: (
      state,
      action: PayloadAction<Array<ILocalizationStatus>>,
    ) => {
      action.payload.forEach((status) => {
        state[status.memberId] = {
          updatedAt: status.updatedAt,
          location: status.location,
          lastCheckedAt: moment().toISOString(),
          country: status.country,
        } as LocationStateItem;
      });
    },
  },
});

export const localizationActions = localizationSlice.actions;
export default localizationSlice.reducer;
