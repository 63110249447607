import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { call, put, select } from 'typed-redux-saga/macro';

import { PayloadAction } from '@reduxjs/toolkit';

import { updateProcessState } from '../../redux-slices/processes';
import {
  ACCEPT_INVITATION_PROCESSING,
  ACCESS_CODE_PROCESSING,
  GROUP_CREATING_PROCESSING,
  GROUP_DELETING_PROCESSING,
  GROUP_ENABLE_SHARE_PROCESSING,
  GROUP_LEAVING_PROCESSING,
  GROUP_PASS_OWNERSHIP_PROCESSING,
  GROUP_RENAMING_PROCESSING,
  GROUPS_LOADING_PROCESSING,
  GROUPS_RELOADING_PROCESSING,
} from '../../redux-slices/processes/constants';
import {
  AssignedEntityType,
  GroupMemberModel,
  GroupModel,
  GroupsApi,
  InvitationsApi,
  PlanType,
} from '../../../api/base-api';
import {
  addGroup,
  setAccessCode,
  setGroups,
  setLocationSharing,
} from '../../redux-slices/groups';
import {
  ISendAcceptInvitationPayload,
  ISendCreateGroupPayload,
  ISendDeleteGroupPayload,
  ISendGetAccessCodePayload,
  ISendLeaveGroupPayload,
  ISendPassOwnershipGroupPayload,
  ISendRemoveFromGroupPayload,
  ISendRenameGroupPayload,
  ISendShareLocationEnabledPayload,
} from './model';
import { updateModalState } from '../../redux-slices/modals';
import { LEAVE_GROUP_MODAL_NAME } from '../../../components/Modals/LeaveGroupModal/LeaveGroupModal';
import { RENAME_GROUP_MODAL_NAME } from '../../../components/Modals/RenameGroupModal/RenameGroupModal';
import { DELETE_GROUP_MODAL_NAME } from '../../../components/Modals/DeleteGroupModal/DeleteGroupModal';
import { INVITE_TO_GROUP_MODAL_NAME } from '../../../components/Modals/InviteToGroupModal/InviteToGroupModal';
import { PASS_OWNERSHIP_GROUP_MODAL_NAME } from '../../../components/Modals/PassOwnershipModal/PassOwnershipModal';
import { userSelector } from '../../redux-slices/user/selectors';
import { REMOVE_GROUP_MEMBER_MODAL_NAME } from '../../../components/Modals/RemoveGroupMemberModal/RemoveGroupMemberModal';
import { membersActions } from '../members';
import { checkInActions, ICheckInStatus } from '../../redux-slices/checkIn';
import { StringDict } from '../../../utils/stringDict';
import { membershipSelector } from 'store/redux-slices/membership/selectors';

const getFlatMembers = (groups: GroupModel[]): Array<GroupMemberModel> =>
  groups.map((group) => group.members).flatMap((x) => x!);

const getDistinctMembersIds = (groups: GroupModel[]): Array<string> => [
  ...new Set(groups.map((member) => member.id!)),
];

const getDistinctNames = (groups: GroupMemberModel[]): StringDict<string> =>
  groups.reduce((previousValue, member, currentIndex) => {
    if (previousValue.hasOwnProperty(member.id)) {
      return previousValue;
    }
    previousValue[member.id] = member.displayName!;
    return previousValue;
  }, {} as StringDict<string>);

export function* loadGroups() {
  const groupsApi = new GroupsApi();

  const { data } = yield* call(groupsApi.groupsGet);

  yield put(setGroups(data));

  const flatMembers = getFlatMembers(data);
  const distinctIds = getDistinctMembersIds(flatMembers);

  const distinctNames = getDistinctNames(flatMembers);

  const checkIns = flatMembers.map(
    (member) =>
      ({
        memberId: member.id,
        status: {
          checkInDate: member.checkInDate,
          checkInStatus: member.checkInStatus,
        },
      } as ICheckInStatus),
  );

  yield put(checkInActions.setCheckIns(checkIns));
  yield put(membersActions.loadMembersLocalization(distinctIds));
  yield put(membersActions.loadMembersPhoto(distinctIds));
  yield put(membersActions.loadMemberNames(distinctNames));
}

function* loadGroupsData() {
  try {
    yield put(updateProcessState(GROUPS_LOADING_PROCESSING));
    yield loadGroups();
  } catch (e: any) {
  } finally {
    yield put(updateProcessState(GROUPS_LOADING_PROCESSING));
  }
}

export function* reloadGroupsData() {
  try {
    yield put(updateProcessState(GROUPS_RELOADING_PROCESSING));

    yield loadGroups();
  } finally {
    yield put(updateProcessState(GROUPS_RELOADING_PROCESSING));
  }
}

const groupsSlice = createSliceSaga({
  name: 'groups-saga',
  caseSagas: {
    loadGroupsData,
    reloadGroupsData,
    *sendGetAccessCode(action: PayloadAction<ISendGetAccessCodePayload>) {
      try {
        yield put(updateProcessState(ACCESS_CODE_PROCESSING));

        const groupsApi = new GroupsApi();

        const { data } = yield* call(
          groupsApi.groupsGroupIdAccessCodeGet,
          action.payload.groupId,
        );

        yield put(
          setAccessCode({
            groupId: action.payload.groupId,
            accessCode: data,
          }),
        );

        action.payload.success?.();
      } catch (e) {
        action.payload.error?.();
      } finally {
        yield put(updateProcessState(ACCESS_CODE_PROCESSING));
      }
    },
    *sendRefreshAccessCode(action: PayloadAction<ISendGetAccessCodePayload>) {
      try {
        yield put(updateProcessState(ACCESS_CODE_PROCESSING));

        const groupsApi = new GroupsApi();

        const { data } = yield* call(
          groupsApi.groupsGroupIdAccessCodeActionsRefreshPost,
          action.payload.groupId,
        );

        yield put(
          setAccessCode({
            groupId: action.payload.groupId,
            accessCode: data,
          }),
        );

        action.payload.success?.();
      } catch (e) {
        action.payload.error?.();
      } finally {
        yield put(updateProcessState(ACCESS_CODE_PROCESSING));
      }
    },
    *sendRenameGroup(action: PayloadAction<ISendRenameGroupPayload>) {
      try {
        yield put(updateProcessState(GROUP_RENAMING_PROCESSING));

        const groupsApi = new GroupsApi();

        yield* call(groupsApi.groupsGroupIdPut, action.payload.groupId, {
          name: action.payload.name,
        });

        yield put(updateModalState(RENAME_GROUP_MODAL_NAME));
        yield reloadGroupsData();

        action.payload.success?.();
      } catch (e) {
        action.payload.error?.(e);
      } finally {
        yield put(updateProcessState(GROUP_RENAMING_PROCESSING));
      }
    },
    *sendCreateGroup(action: PayloadAction<ISendCreateGroupPayload>) {
      try {
        yield put(updateProcessState(GROUP_CREATING_PROCESSING));

        const groupsApi = new GroupsApi();

        const { data } = yield* call(groupsApi.groupsPost, {
          name: action.payload.name,
        });

        const user = yield* select(userSelector);

        yield put(
          addGroup({
            id: data,
            name: action.payload.name,
            members: [
              {
                id: user.id!,
                isOwner: true,
                displayName: `${user.given_name} ${user.family_name}`,
              },
            ],
          }),
        );

        yield put(updateModalState([INVITE_TO_GROUP_MODAL_NAME, { id: data }]));

        action.payload.success?.();
      } catch (e) {
        action.payload.error?.(e);
      } finally {
        yield put(updateProcessState(GROUP_CREATING_PROCESSING));
      }
    },
    *sendDeleteGroup(action: PayloadAction<ISendDeleteGroupPayload>) {
      try {
        yield put(updateProcessState(GROUP_DELETING_PROCESSING));

        const groupsApi = new GroupsApi();

        yield* call(groupsApi.groupsGroupIdDelete, action.payload.groupId);

        yield reloadGroupsData();
        yield put(updateModalState(DELETE_GROUP_MODAL_NAME));
        action.payload.success?.();
      } catch (e) {
        action.payload.error?.();
      } finally {
        yield put(updateProcessState(GROUP_DELETING_PROCESSING));
      }
    },
    *sendLeaveGroup(action: PayloadAction<ISendLeaveGroupPayload>) {
      try {
        yield put(updateProcessState(GROUP_LEAVING_PROCESSING));

        const groupsApi = new GroupsApi();

        yield* call(
          groupsApi.groupsGroupIdActionsLeavePost,
          action.payload.groupId,
          { newOwnerId: action.payload.newOwnerId },
        );

        yield reloadGroupsData();
        yield put(updateModalState(LEAVE_GROUP_MODAL_NAME));
        action.payload.success?.();
      } catch (e) {
        action.payload.error?.();
      } finally {
        yield put(updateProcessState(GROUP_LEAVING_PROCESSING));
      }
    },
    *sendRemoveFromGroup(action: PayloadAction<ISendRemoveFromGroupPayload>) {
      try {
        yield put(updateProcessState(GROUP_LEAVING_PROCESSING));

        const groupsApi = new GroupsApi();

        yield* call(
          groupsApi.groupsGroupIdMembersMemberIdDelete,
          action.payload.groupId,
          action.payload.memberId,
        );

        yield reloadGroupsData();
        yield put(updateModalState(REMOVE_GROUP_MEMBER_MODAL_NAME));
        action.payload.success?.();
      } catch (e) {
        action.payload.error?.();
      } finally {
        yield put(updateProcessState(GROUP_LEAVING_PROCESSING));
      }
    },
    *sendEnableLocationSharing(
      action: PayloadAction<ISendShareLocationEnabledPayload>,
    ) {
      try {
        yield put(updateProcessState(GROUP_ENABLE_SHARE_PROCESSING));

        const groupsApi = new GroupsApi();

        yield* call(
          groupsApi.groupsGroupIdSettingsEnableMyLocationSharingPut,
          action.payload.id,
          action.payload.body,
        );

        yield put(setLocationSharing(action.payload));

        action.payload.success?.();
      } catch (e) {
        action.payload.error?.();
      } finally {
        yield put(updateProcessState(GROUP_ENABLE_SHARE_PROCESSING));
      }
    },
    *sendAcceptInvitation(action: PayloadAction<ISendAcceptInvitationPayload>) {
      try {
        yield put(updateProcessState(ACCEPT_INVITATION_PROCESSING));
        const invitationsApi = new InvitationsApi();

        const inviteDetails = yield* call(
          invitationsApi.invitationsAccessCodeGet,
          action.payload.accessCode,
        );
        if (
          inviteDetails.data.invitationType === AssignedEntityType.FamilyMember
        ) {
          const membership = yield* select(membershipSelector);
          if (
            membership.plan.planType === PlanType.Family ||
            membership.plan.planType === PlanType.Individual
          ) {
            throw new Error('You already have an active Membership');
          }
        }

        if (inviteDetails.data) {
          yield call(
            invitationsApi.invitationsAccessCodeActionsAcceptInvitationPost,
            action.payload.accessCode,
          );
        }

        yield reloadGroupsData();
        action.payload.success?.(inviteDetails);
      } catch (e) {
        action.payload.error?.(e);
      } finally {
        yield put(updateProcessState(ACCEPT_INVITATION_PROCESSING));
      }
    },
    *sendPassOwnershipGroup(
      action: PayloadAction<ISendPassOwnershipGroupPayload>,
    ) {
      try {
        yield put(updateProcessState(GROUP_PASS_OWNERSHIP_PROCESSING));

        const groupApi = new GroupsApi();

        yield* call(
          groupApi.groupsGroupIdActionsPassOwnershipPost,
          action.payload.groupId,
          { newOwnerId: action.payload.memberId },
        );

        yield reloadGroupsData();
        yield put(updateModalState(PASS_OWNERSHIP_GROUP_MODAL_NAME));
        action.payload.success?.();
      } catch (e) {
        action.payload.error?.();
      } finally {
        yield put(updateProcessState(GROUP_PASS_OWNERSHIP_PROCESSING));
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default groupsSlice.saga;
export const { actions: groupsAction } = groupsSlice;
