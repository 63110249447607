import i18next from 'i18next';

import { Wizard } from '../models';
import { RoutePath } from '../../../../models/RoutePath';

export enum WizardStepsCn {
  PaymentPage = 'membership-payment-page',
}

export const UPGRADE_MEMBERSHIP_WIZARD_NAME = 'UPGRADE_MEMBERSHIP_WIZARD_NAME';

export const upgradeMembershipWizard: Wizard = {
  steps: [
    {
      order: 0,
      caption: i18next.t('wizard.add-membership.payment-page'),
      canonicalName: WizardStepsCn.PaymentPage,
      url: RoutePath.MembershipUpgradePaymentStep,
      allowSkip: false,
      isValid: false,
      isAvailable: true,
      isAuth: false,
    },
  ],
  activeStep: WizardStepsCn.PaymentPage,
};
