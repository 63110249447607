import { components } from 'react-select';

import { Thumbnail } from '../../../Thumbnail/Thumbnail';
import { OptionText } from './iconOption.styles';

export const IconOption = (props: any) => {
  const { Option } = components;
  return (
    <Option {...props}>
      <Thumbnail id={props.value} type="mini" hideActivityStatus />
      <OptionText>{props.label}</OptionText>
    </Option>
  );
};
