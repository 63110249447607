import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useCallback } from 'react';

import { useAlert } from 'react-alert';

import { modalDetailsSelector } from '../../../../../store/redux-slices/modals/selectors';
import { REMOVE_EMERGENCY_CONTACT_MODAL_NAME } from './RemoveEmergencyContactModal';
import { useActions } from '../../../../../hooks/useActions';

import { updateModalState } from '../../../../../store/redux-slices/modals';
import { emergencyContactActions } from '../../../../../store/saga-slices/emergencyContacts';

export const useRemoveEmergencyContactModal = () => {
  const { success } = useAlert();
  const { t } = useTranslation();
  const { emergencyContactId, emergencyContactName } = useSelector(
    modalDetailsSelector(REMOVE_EMERGENCY_CONTACT_MODAL_NAME),
  );
  const UpdateModalState = useActions(updateModalState);

  const removeContact = useActions(emergencyContactActions.sendRemove);

  const onRemove = useCallback(() => {
    const payload = {
      emergencyContactId,
      success: () =>
        success(
          t('phrase.emergency-contact-removed-success', {
            name: emergencyContactName,
          }),
        ),
    };

    removeContact(payload);
  }, [emergencyContactId, emergencyContactName, removeContact, success, t]);

  const onCancel = useCallback(() => {
    UpdateModalState(REMOVE_EMERGENCY_CONTACT_MODAL_NAME);
  }, [UpdateModalState]);

  return {
    t,
    emergencyContactId,
    onRemove,
    onCancel,
    emergencyContactName,
  };
};
