import { FieldRenderProps } from 'react-final-form';

import { useFormSelect } from './formSelect.hooks';
import { MainSelectPropType } from '../../MainSelect/mainSelect.types';
import { FormFieldWrapper } from '../Form';
import { MainSelect } from '../../MainSelect/MainSelect';
import { ErrorLabel } from '../../Field/Field';

interface IFormSelectProps<T>
  extends FieldRenderProps<T>,
    MainSelectPropType<T> {
  setDefaultValue?: boolean;
  customStyles?: any;
}

export const FormSelect = <T,>({
  className,
  disabled,
  input: { onChange, value, name, onBlur },
  inputId,
  isLabelHidden,
  label,
  meta: { error, touched },
  options,
  placeholder,
  selectAdditionalClassName,
  setDefaultValue,
  labelAnimation = true,
  ...props
}: IFormSelectProps<T>) => {
  const { onChangeHandler, preparedPlaceholder } = useFormSelect({
    onChange,
    options,
    labelAnimation,
    name,
    placeholder,
    promiseOptions: props.promiseOptions,
    setDefaultValue,
    value,
  });

  return (
    <FormFieldWrapper className={className} error={touched && error}>
      <MainSelect
        currentValue={value}
        isDisabled={disabled}
        onChange={onChangeHandler as any}
        options={options}
        label={label}
        isError={touched && error}
        placeholder={preparedPlaceholder}
        onBlur={onBlur}
        {...props}
      />
      {touched && error && <ErrorLabel>{error}</ErrorLabel>}
    </FormFieldWrapper>
  );
};
