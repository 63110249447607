import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { call, delay, put, putResolve, select } from 'typed-redux-saga/macro';

import { NotificationsApi, NotificationType } from '../../../api/base-api';
import {
  addNotifications,
  markReceived,
} from '../../redux-slices/notifications';
import { reloadGroupsData } from '../groups';
import { allNotificationsSelector } from '../../redux-slices/notifications/selectors';
import { loadCheckInRequests } from '../members';
import { checkInSagaActions } from '../checkIn';
import { userSelector } from '../../redux-slices/user/selectors';
import { NOTIFICATION_FETCH_COUNT } from '../../redux-slices/notifications/model';
import { updateFriends } from '../../redux-slices/friends';
import { friendsSelector } from '../../redux-slices/friends/selectors';

function* lastNotReadedGroupNotification(notifications) {
  const lastNotReadedNotification = notifications.find(
    (notify) =>
      (notify.type === NotificationType.MemberAddedToGroup ||
        notify.type === NotificationType.MemberRemovedFromGroup ||
        notify.type === NotificationType.GroupDeleted) &&
      !notify.isRead &&
      !notify.received,
  );

  if (!!lastNotReadedNotification) {
    yield put(markReceived(lastNotReadedNotification.id!));
    yield reloadGroupsData();
  }
}

function* lastNotReadedFriendNotifications(notifications, friends) {
  const lastNotReadedFriendsNotification = notifications.find(
    (notify) =>
      notify.type === NotificationType.MemberAddedToFriends &&
      !notify.isRead &&
      !notify.received,
  );

  const checkIfFriendExistInList = friends.find(
    (friend) => friend.id === lastNotReadedFriendsNotification?.relatedMemberId,
  );

  if (!!lastNotReadedFriendsNotification && !checkIfFriendExistInList) {
    const newFriend = {
      id: lastNotReadedFriendsNotification.relatedMemberId,
      displayName: lastNotReadedFriendsNotification.title,
      checkInDate: null,
      checkInStatus: null,
    };
    yield put(updateFriends(newFriend));
  }
}

function* lastNotReadedStatusNotifications(notifications) {
  const lastNotReadedStatusNotification = notifications.find(
    (notify) =>
      (notify.type === NotificationType.CheckInRequest ||
        notify.type === NotificationType.CheckInStatusUpdate) &&
      !notify.isRead &&
      !notify.received,
  );

  if (!!lastNotReadedStatusNotification) {
    yield put(markReceived(lastNotReadedStatusNotification.id!));

    yield put(
      checkInSagaActions.updateCheckIn({
        memberId: lastNotReadedStatusNotification.relatedMemberId!,
        status: {
          checkInDate: lastNotReadedStatusNotification.date,
          checkInStatus:
            lastNotReadedStatusNotification.relatedMemberSafetyStatus,
        },
      }),
    );
  }
}

function* loadNotifications() {
  const user = yield* select(userSelector);

  if (!user.id) {
    yield delay(5000);
    yield put({ type: 'continuously-saga/loadNotifications' });
  } else {
    try {
      let notifications = yield* select(allNotificationsSelector);
      const notificationsApi = new NotificationsApi();
      const { data } = yield* call(
        notificationsApi.notificationsGet,
        NOTIFICATION_FETCH_COUNT,
        notifications[0]?.id,
      );
      yield putResolve(addNotifications(data));
      // if (data.length < NOTIFICATION_FETCH_COUNT) {
      //   yield put(setMemberRelatedFullLoaded());
      //   yield put(setRiskAlertFullLoaded());
      // }

      if (data.length !== 0) {
        notifications = yield* select(allNotificationsSelector);
        const friends = yield* select(friendsSelector);

        yield lastNotReadedGroupNotification(notifications);
        yield lastNotReadedFriendNotifications(notifications, friends);
        yield lastNotReadedStatusNotifications(notifications);
      }
    } catch (e) {
      console.log(e);
    } finally {
      yield delay(5000);
      yield put({ type: 'continuously-saga/loadNotifications' });
    }
  }
}

function* loadCheckIns() {
  const user = yield* select(userSelector);
  if (!user.id) {
    yield delay(5000);
    yield put({ type: 'continuously-saga/loadCheckIns' });
  } else {
    try {
      yield call(loadCheckInRequests);
    } catch (e) {
    } finally {
      yield delay(5000);
      yield put({ type: 'continuously-saga/loadCheckIns' });
    }
  }
}

const continuouslySlice = createSliceSaga({
  name: 'continuously-saga',
  caseSagas: {
    loadNotifications,
    loadCheckIns,
  },
  sagaType: SagaType.TakeLatest,
});

export default continuouslySlice.saga;
export const continuouslyActions = continuouslySlice.actions;
export const { actions } = continuouslySlice;
