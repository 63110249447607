import {
  ActiveDot,
  CircleText,
  CircleWrapper,
  ThumbnailCircle,
  ThumbnailImage,
  ThumbnailImageWrapper,
  ThumbnailText,
} from './thumbnail.styles';
import { useThumbnail } from './thumbnail.hooks';

export type ThumbnailType =
  | 'mini'
  | 'medium'
  | 'default'
  | 'large'
  | 'huge'
  | 'big';

type ThumbnailProps = {
  id: string | number;
  type?: ThumbnailType;
  contentType?: 'string';
  hideActivityStatus?: boolean;
};

export const Thumbnail = ({
  id,
  type,
  contentType,
  hideActivityStatus,
}: ThumbnailProps) => {
  const { image, shortName, color, backgroundColor, isActive } =
    useThumbnail(id);

  if (!image) {
    return (
      <CircleWrapper style={{ backgroundColor }} type={type}>
        <CircleText style={{ color }}>{shortName}</CircleText>
        {!hideActivityStatus && <ActiveDot type={type} active={isActive} />}
      </CircleWrapper>
    );
  }

  return (
    <ThumbnailImageWrapper type={type}>
      {typeof image != 'number' && (
        <>
          <ThumbnailImage type={type} src={image} />
          {!hideActivityStatus && <ActiveDot type={type} active={isActive} />}
        </>
      )}
      {typeof image == 'number' && (
        <ThumbnailCircle>
          <ThumbnailText>{image}+</ThumbnailText>
        </ThumbnailCircle>
      )}
    </ThumbnailImageWrapper>
  );
};
