import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RiskAlertModel } from '../../../api/base-api';
import { IAddRiskAlertsPayload } from './model';

interface RiskAlertState {
  [x: string]: RiskAlertModel[];
}

const initState: RiskAlertState = {};

const riskAlertsSlice = createSlice({
  name: 'risk-alerts',
  initialState: initState,
  reducers: {
    addCountryAlerts: (state, action: PayloadAction<IAddRiskAlertsPayload>) => {
      state[action.payload.countryCode] = action.payload.riskAlerts;
    },
  },
});

export const riskAlertActions = riskAlertsSlice.actions;
export default riskAlertsSlice.reducer;
