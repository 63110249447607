import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import backend from 'i18next-http-backend';
import translationsEn from './locale/en/translations.json';

i18n
  .use(backend)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationsEn,
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      format(value, format) {
        if (format === 'uppercase') return value.toUpperCase();
        if (value instanceof Date) return moment(value).format(format);
        return value;
      },
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
