// @flow
import * as React from 'react';
import { Trans } from 'react-i18next';

import { useDeleteGroupModal } from './deleteGroupModal.hooks';
import { ActionModal } from '../templates/ActionModal';
import { DescriptionText } from '../templates/styles';

export const DELETE_GROUP_MODAL_NAME = 'DELETE_GROUP_MODAL';

export const DeleteGroupModal = () => {
  const { t, name, onConfirm, isLoading } = useDeleteGroupModal();

  return (
    <ActionModal
      title={t('headers.delete-group')}
      confirmText={t('buttons.confirm-delete')}
      name={DELETE_GROUP_MODAL_NAME}
      type="error-filled"
      isLoading={isLoading}
      onConfirm={onConfirm}
    >
      <Trans>
        <DescriptionText>
          {t('phrase.confirm-you-want-delete', { name })}
        </DescriptionText>
      </Trans>
    </ActionModal>
  );
};
