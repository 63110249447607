import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import CreateGroupFormPage from '../../Forms/CreateGroupForm/CreateGroupForm';
import { mq } from '../../../common/utils/mediaQueries';
import { typography } from '../../../common/consts/typography';

export const StyledCreateGroupForm = styled(CreateGroupFormPage)`
  padding: 2.5rem 0;
  margin: 0 1rem;

  ${mq.xSmall(css`
    margin: 0 2.5rem;
  `)}
`;

export const CodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
`;

export const CodeCell = styled.div`
  width: 57px;
  height: 57px;
  border: 1px solid ${({ theme }) => theme.color.primary_200};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${typography.bodyL};
  color: ${({ theme }) => theme.color.primary_900};
`;

export const ButtonsContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 16px;
`;
