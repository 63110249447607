import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetPersonalDataResponse } from '../../../api/base-api';

export type UserModel = GetPersonalDataResponse & {
  coords?: google.maps.LatLngLiteral;
  photo?: string;
  id?: string;
  family_name?: string;
  given_name?: string;
  emailVerified?: boolean;
};

const initState: UserModel = {};

const userSlice = createSlice({
  name: 'user',
  initialState: initState,
  reducers: {
    updateUserData: (state, action: PayloadAction<UserModel>) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
    setUserCoords: (
      state,
      action: PayloadAction<google.maps.LatLngLiteral>,
    ) => {
      state.coords = action.payload;
    },
    resetUser: (state) => {},
  },
});

export const { updateUserData, setUserCoords, resetUser } = userSlice.actions;
export default userSlice.reducer;
