import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getPropertyName } from '../../../../utils/getPropertyName';

type UseFormSelect = {
  onChange: (event: any) => void;
  value?: any;
  promiseOptions?: () => Promise<any>;
  items: any;
  setDefaultValue?: boolean;
  labelAnimation?: boolean;
  placeholder?: ReactNode;
  name: string;
};

export const useFormRadioGroup = ({
  onChange,
  value,
  promiseOptions,
  setDefaultValue,
  labelAnimation,
  placeholder,
  items,
  name,
}: UseFormSelect) => {
  const { t } = useTranslation();

  const onChangeHandler = useCallback(
    (option: string) => {
      onChange(option);
    },
    [onChange],
  );

  useEffect(() => {
    if (!promiseOptions && !items) {
      onChange(null);
    }
  }, [items, onChange, promiseOptions]);

  useEffect(() => {
    if (
      !setDefaultValue ||
      !items ||
      items.length !== 1 ||
      value === items[0]
    ) {
      return;
    }

    setTimeout(() => onChangeHandler(items[0]), 0);
  }, [value, onChange, items, setDefaultValue, onChangeHandler]);

  const preparedPlaceholder = useMemo(
    () =>
      labelAnimation
        ? ''
        : placeholder || t(`fields.${getPropertyName(name)}.placeholder`),
    [labelAnimation, name, placeholder, t],
  );

  return { preparedPlaceholder, onChangeHandler };
};
