import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { usePassOwnershipForm } from '../../Forms/PassOwnershipForm/passOwnershipForm.hooks';
import { membersGroupWithoutUserSelector } from '../../../store/redux-slices/groups/selectors';
import { GROUP_PASS_OWNERSHIP_PROCESSING } from '../../../store/redux-slices/processes/constants';
import { useProcessing } from '../../../hooks/useProcessing';

export const usePassOwnershipModal = () => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const { onPassOwnershipSubmitHandler } = usePassOwnershipForm();
  const isLoading = useProcessing(GROUP_PASS_OWNERSHIP_PROCESSING);

  const members = useSelector(membersGroupWithoutUserSelector(groupId!));

  return {
    t,
    // eslint-disable-next-line no-restricted-globals
    name,
    id: groupId,
    onSubmitHandler: onPassOwnershipSubmitHandler,
    members,
    isLoading,
  };
};
