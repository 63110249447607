import { Trans } from 'react-i18next';

import { useLeaveGroupModal } from './leaveGroupModal.hooks';
import { ActionModal } from '../templates/ActionModal';
import { DescriptionText } from '../templates/styles';

export const LEAVE_GROUP_MODAL_NAME = 'LEAVE_GROUP_MODAL';

export const LeaveGroupModal = () => {
  const { t, name, onLeave, isLoading } = useLeaveGroupModal();

  return (
    <ActionModal
      title={t('headers.leave-group')}
      name={LEAVE_GROUP_MODAL_NAME}
      type="error-filled"
      isLoading={isLoading}
      onConfirm={onLeave}
    >
      <Trans>
        <DescriptionText>
          {t('phrase.confirm-you-want-leave', { name })}
        </DescriptionText>
      </Trans>
    </ActionModal>
  );
};
