import { useCallback } from 'react';

type UseRadioGroupContentProps = {
  checked: string;
};

export const useRadioGroupContent = ({
  checked,
}: UseRadioGroupContentProps) => {
  const isChecked = useCallback((id: string) => id === checked, [checked]);

  const shouldContentVisible = useCallback(
    (id: string, contentHiddenIfNoActive: boolean) =>
      isChecked(id) || (!isChecked(id) && !contentHiddenIfNoActive),
    [isChecked],
  );

  return { isChecked, shouldContentVisible };
};
