import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { call, put, putResolve, select } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import moment from 'moment';

import { CardNumberElement } from '@stripe/react-stripe-js';

import { membershipActions as membershipReduxActions } from '../../redux-slices/membership';
import { changeActiveWizard, changeToNextStep } from '../wizard';
import {
  disposeWizard,
  resetWizard,
  setActiveWizard,
  updateAvailableSteps,
} from '../../redux-slices/wizard';
import { stepsDetailsSelector } from '../../redux-slices/wizard/selectors';
import {
  ISendAddFamilyMemberPayload,
  ISendConfirmPaymentPayload,
  ISendEditAddressPayload,
  ISendEditFamilyMemberPayload,
  ISendValidateAddressPayload,
  ISendValidateDateOPfBirthPayload,
} from './model';
import {
  FamilyMemberModel,
  MembershipApi,
  PlanType,
} from '../../../api/base-api';
import { updateProcessState } from '../../redux-slices/processes';
import {
  ADD_MEMBERSHIP_ADDRESS_PROCESSING,
  ADD_MEMBERSHIP_DATE_OF_BIRTH_PROCESSING,
  ADD_MEMBERSHIP_PAYMENT_PROCESSING,
  MEMBERSHIP_ADDRESS_PROCESSING,
  MEMBERSHIP_ADDRESS_RELOADING,
  MEMBERSHIP_CARD_DETAILS_RELOADING,
  MEMBERSHIP_FAMILY_MEMBER_PROCESSING,
  MEMBERSHIP_SUMMARY_RELOADING,
  MEMBERSHIP_UPGRADE_PROCESSING,
  UPDATE_MEMBERSHIP_PAYMENT_PROCESSING,
} from '../../redux-slices/processes/constants';
import { membersActions } from '../members';
import {
  ADD_MEMBERSHIP_WIZARD_NAME,
  WizardStepsCn,
} from '../../redux-slices/wizard/wizards/AddMembership';
import { shouldUpdateMembershipSelector } from '../../redux-slices/membership/selectors';
import { userSelector } from '../../redux-slices/user/selectors';
import { updateModalState } from '../../redux-slices/modals';
import { EDIT_FAMILY_MEMBER_MODAL_NAME } from '../../../pages/membership/Modals/EditFamilyMemberModal/EditFamilyMemberModal';
import { UPGRADE_MEMBERSHIP_WIZARD_NAME } from '../../redux-slices/wizard/wizards/UpgradeMembership';
import { FAMILY_MEMBER_MODAL_NAME } from '../../../pages/signUp/FamilyMembersPage/modals/AddFamilyMemberModal/AddFamilyMemberModal';
import { StringDict } from '../../../utils/stringDict';

const getDistinctMembersIds = (
  members: Array<FamilyMemberModel>,
): Array<string> => [...new Set(members.map((member) => member.memberId!))];

const getDistinctNames = (
  members: Array<FamilyMemberModel>,
): StringDict<string> =>
  members.reduce((previousValue, member, currentIndex) => {
    if (previousValue.hasOwnProperty(member.memberId)) {
      return previousValue;
    }
    previousValue[member.memberId] = member.personalInformation.displayName!;
    return previousValue;
  }, {} as StringDict<string>);

export function* fetchMembershipSummaryData() {
  try {
    const membershipApi = new MembershipApi();
    const { data: membershipData } = yield* call(
      membershipApi.membershipsCurrentSummaryGet,
    );

    yield put(disposeWizard());
    yield put(membershipReduxActions.setPlan(membershipData));
  } catch (e) {
    yield put(
      membershipReduxActions.setPlan({
        planType: PlanType.Free,
      }),
    );
    yield put(setActiveWizard(ADD_MEMBERSHIP_WIZARD_NAME));
  }
}

export function* fetchMembershipAddressData() {
  try {
    const membershipApi = new MembershipApi();
    const { data: membershipData } = yield* call(
      membershipApi.membershipsCurrentAddressGet,
    );

    yield put(
      membershipReduxActions.setAddress({
        billingAddress: membershipData.billingAddress!,
        residentialAddress: membershipData.residentialAddress!,
      }),
    );
  } catch (e) {}
}

export function* fetchMembershipPaymentData() {
  try {
    const membershipApi = new MembershipApi();
    const { data: membershipData } = yield* call(
      membershipApi.membershipsCurrentPaymentMethodGet,
    );

    yield put(membershipReduxActions.setPayment(membershipData));
  } catch (e) {}
}

export function* fetchFamilyMembersData() {
  try {
    const membershipApi = new MembershipApi();
    const { data: membersData } = yield* call(
      membershipApi.membershipsCurrentFamilyMembersGet,
    );

    yield put(membershipReduxActions.setFamilyMembers(membersData));

    const distinctIds = getDistinctMembersIds(membersData.members);
    const distinctNames = getDistinctNames(membersData.members);
    distinctNames[membersData.owner.memberId] = membersData.owner.displayName;

    yield put(membersActions.loadMembersPhoto(distinctIds));
    yield put(membersActions.loadMemberNames(distinctNames));
  } catch (e) {}
}

const membershipSlice: any = createSliceSaga({
  name: 'membership-saga',
  caseSagas: {
    *reloadSummary() {
      yield put(updateProcessState(MEMBERSHIP_SUMMARY_RELOADING));
      const shouldUpdate = yield* select(shouldUpdateMembershipSelector);
      if (shouldUpdate) {
        yield fetchMembershipSummaryData();
      }
      yield put(updateProcessState(MEMBERSHIP_SUMMARY_RELOADING));
    },
    *reloadAddress() {
      yield put(updateProcessState(MEMBERSHIP_ADDRESS_RELOADING));
      yield fetchMembershipAddressData();
      yield put(updateProcessState(MEMBERSHIP_ADDRESS_RELOADING));
    },
    *reloadCard() {
      yield put(updateProcessState(MEMBERSHIP_CARD_DETAILS_RELOADING));
      yield fetchMembershipPaymentData();
      yield put(updateProcessState(MEMBERSHIP_CARD_DETAILS_RELOADING));
    },
    *reloadFamilyMembers() {
      yield put(updateProcessState(MEMBERSHIP_CARD_DETAILS_RELOADING));
      const shouldUpdate = yield* select(shouldUpdateMembershipSelector);
      if (shouldUpdate) {
        yield fetchFamilyMembersData();
      }
      yield put(updateProcessState(MEMBERSHIP_CARD_DETAILS_RELOADING));
    },
    *submitPlanTypeStep(action: PayloadAction<{ [x: string]: any }>) {
      try {
        if (window.innerWidth < 500) {
          yield putResolve(
            updateAvailableSteps([
              {
                canonicalName: WizardStepsCn.DescriptionPage,
                isAvailable: true,
              },
            ]),
          );
          yield put(changeToNextStep(action.payload));
          yield put(
            updateAvailableSteps([
              { canonicalName: WizardStepsCn.PlanPage, isAvailable: false },
            ]),
          );
        } else {
          yield put(changeToNextStep(action.payload));
        }
      } catch (err: any) {
        console.log(err);
      } finally {
      }
    },
    *submitAddressStep(action: PayloadAction<ISendValidateAddressPayload>) {
      try {
        yield put(updateProcessState(ADD_MEMBERSHIP_ADDRESS_PROCESSING));

        const membershipApi = new MembershipApi();

        yield* call(membershipApi.membershipsActionsValidateAddressPost, {
          residentialAddress: action.payload.residentialAddress,
          billingAddress: action.payload.billingAddress,
        });
        yield put(changeToNextStep(action.payload));
      } catch (err: any) {
        action.payload.error?.(err);
      } finally {
        yield put(updateProcessState(ADD_MEMBERSHIP_ADDRESS_PROCESSING));
      }
    },
    *submitDateOfBirthStep(
      action: PayloadAction<ISendValidateDateOPfBirthPayload>,
    ) {
      try {
        yield put(updateProcessState(ADD_MEMBERSHIP_DATE_OF_BIRTH_PROCESSING));
        const membershipApi = new MembershipApi();

        // yield* call(membershipApi.membershipsActionsValidateDateOfBirthPost, {
        //   dateOfBirth: action.payload.dateOfBirth,
        // });

        const details = yield* select(stepsDetailsSelector);

        const response = yield* call(membershipApi.membershipsPost, {
          planType: details.planType,
          dateOfBirth: moment(action.payload.dateOfBirth).toISOString(),
          billingAddress: {
            street: details.billingAddress.street,
            number: details.billingAddress.number,
            city: details.billingAddress.city,
            country: details.billingAddress.country,
            state: details.billingAddress.state,
            zipCode: details.billingAddress.zipCode,
          },
          residentialAddress: {
            street: details.residentialAddress.street,
            number: details.residentialAddress.number,
            city: details.residentialAddress.city,
            country: details.residentialAddress.country,
            state: details.residentialAddress.state,
            zipCode: details.residentialAddress.zipCode,
          },
        });

        yield put(
          changeToNextStep({
            ...action.payload,
            clientSecret: response.data.clientSecret,
          }),
        );
      } catch (err: any) {
        action.payload.error?.(err);
      } finally {
        yield put(updateProcessState(ADD_MEMBERSHIP_DATE_OF_BIRTH_PROCESSING));
      }
    },
    *submitActivateMembership(
      action: PayloadAction<ISendConfirmPaymentPayload>,
    ) {
      try {
        yield put(updateProcessState(ADD_MEMBERSHIP_PAYMENT_PROCESSING));

        const { elements, stripe, clientSecret } = action.payload;

        const result = yield* call(stripe.confirmCardPayment, clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement)!,
          },
        });

        if (result.error) {
          // Show error to your customer (for example, payment details incomplete)
          console.log(result.error.message);
          action.payload.error?.(result.error);
        } else {
          const details = yield* select(stepsDetailsSelector);
          const user = yield* select(userSelector);
          yield put(
            membershipReduxActions.setPlan({
              planType: details.planType,
              expirationDate: moment().toISOString(),
              ownerMemberId: user.id,
            }),
          );
          yield put(membershipReduxActions.setNextUpdate());
          const { sameAsBillingAddress, ...residentialAddress } =
            details.residentialAddress;
          const billingAddress = sameAsBillingAddress
            ? residentialAddress
            : details.billingAddress;

          yield put(
            membershipReduxActions.setAddress({
              billingAddress: {
                street: billingAddress.street,
                number: billingAddress.number,
                city: billingAddress.city,
                country: billingAddress.country,
                state: billingAddress.state,
                zipCode: billingAddress.zipCode,
              },
              residentialAddress: {
                street: residentialAddress.street,
                number: residentialAddress.number,
                city: residentialAddress.city,
                country: residentialAddress.country,
                state: residentialAddress.state,
                zipCode: residentialAddress.zipCode,
              },
            }),
          );

          yield put(resetWizard());
          action.payload.success?.();
        }
      } catch (e) {
        action.payload.error?.(e);
      } finally {
        yield put(updateProcessState(ADD_MEMBERSHIP_PAYMENT_PROCESSING));
      }
      // navigate(RoutePath.MembershipAbsolute);
    },
    *submitUpgradeMembership(
      action: PayloadAction<ISendConfirmPaymentPayload>,
    ) {
      try {
        yield put(updateProcessState(UPDATE_MEMBERSHIP_PAYMENT_PROCESSING));

        const { elements, stripe, clientSecret } = action.payload;

        const result = yield* call(stripe.confirmCardPayment, clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement)!,
          },
        });

        if (result.error) {
          // Show error to your customer (for example, payment details incomplete)
          console.log(result.error.message);
          action.payload.error?.(result.error);
        } else {
          const details = yield* select(stepsDetailsSelector);
          const user = yield* select(userSelector);
          yield put(
            membershipReduxActions.setPlan({
              planType: details.planType,
              expirationDate: moment().toISOString(),
              ownerMemberId: user.id,
            }),
          );
          yield put(membershipReduxActions.setNextUpdate());

          yield put(resetWizard());
          yield put(disposeWizard());
          action.payload.success?.();
        }
      } catch (e) {
        action.payload.error?.(e);
      } finally {
        yield put(updateProcessState(UPDATE_MEMBERSHIP_PAYMENT_PROCESSING));
      }
      // navigate(RoutePath.MembershipAbsolute);
    },
    *submitStep(action: PayloadAction<{ [x: string]: any }>) {
      try {
        yield put(changeToNextStep(action.payload));
      } catch (err: any) {
      } finally {
      }
    },
    *sendAddFamilyMember(action: PayloadAction<ISendAddFamilyMemberPayload>) {
      // TODO: Call Api
      const { error, success, familyMember } = action.payload;

      try {
        yield put(updateProcessState(MEMBERSHIP_FAMILY_MEMBER_PROCESSING));
        const membershipApi = new MembershipApi();
        const response = yield* call(
          membershipApi.membershipsCurrentFamilyMembersPost,
          familyMember,
        );

        yield put(
          membershipReduxActions.addFamilyMember({
            ...familyMember,
            familyMemberId: response.data.familyMemberId,
          }),
        );
        yield put(
          membersActions.loadMembersPhoto([response.data.familyMemberId!]),
        );
        yield put(
          membersActions.loadMemberNames({
            [familyMember.familyMemberId!]:
              familyMember!.personalInformation!.displayName!,
          }),
        );
        success?.();
        yield put(updateModalState(FAMILY_MEMBER_MODAL_NAME));
      } catch (e) {
        error?.(e);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_FAMILY_MEMBER_PROCESSING));
      }
    },
    *sendEditFamilyMember(action: PayloadAction<ISendEditFamilyMemberPayload>) {
      // TODO: Call Api
      const { error, success, familyMember } = action.payload;

      try {
        yield put(updateProcessState(MEMBERSHIP_FAMILY_MEMBER_PROCESSING));
        const membershipApi = new MembershipApi();

        const response = yield* call(
          membershipApi.membershipsCurrentFamilyMembersFamilyMemberIdPut,
          familyMember.familyMemberId!,
          familyMember,
        );
        yield put(membershipReduxActions.editFamilyMember(familyMember));
        yield put(
          membersActions.loadMemberNames({
            [familyMember.familyMemberId!]:
              familyMember.personalInformation.displayName,
          }),
        );

        success?.();
        yield put(updateModalState(EDIT_FAMILY_MEMBER_MODAL_NAME));
      } catch (e) {
        error?.(e);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_FAMILY_MEMBER_PROCESSING));
      }
    },
    *sendEditAddressMember(action: PayloadAction<ISendEditAddressPayload>) {
      // TODO: Call Api
      const { error, success, residentialAddress, billingAddress } =
        action.payload;

      try {
        yield put(updateProcessState(MEMBERSHIP_ADDRESS_PROCESSING));

        const membershipApi = new MembershipApi();

        yield* call(membershipApi.membershipsCurrentAddressPut, {
          residentialAddress,
          billingAddress,
        });
        yield put(
          membershipReduxActions.editAddress({
            billingAddress,
            residentialAddress,
          }),
        );

        success?.();
      } catch (e) {
        error?.(e);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_ADDRESS_PROCESSING));
      }
    },
    *switchToUpgradeAccount() {
      try {
        yield put(updateProcessState(MEMBERSHIP_UPGRADE_PROCESSING));

        const { data } = yield* call(
          new MembershipApi().membershipsCurrentActionsUpgradePut,
        );

        yield put(
          changeActiveWizard({
            canonicalName: UPGRADE_MEMBERSHIP_WIZARD_NAME,
            steps: [
              {
                canonicalName: WizardStepsCn.PaymentPage,
                details: {
                  planType: PlanType.Family,
                  clientSecret: data.clientSecret,
                },
              },
            ],
          }),
        );
      } catch (e) {
      } finally {
        yield put(updateProcessState(MEMBERSHIP_UPGRADE_PROCESSING));
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default membershipSlice.saga;
export const membershipActions = membershipSlice.actions;
export const { actions } = membershipSlice;
