import { AnySchema } from 'ajv';

import { NotificationSettingsFields } from './notificationSettingsForm.models';
import { FieldErrorMessage } from '../../../models/interfaces/FieldErrorMessage';
import { CreateGroupCommand } from '../../../api/base-api';
import { validateFormData } from '../../../utils/validations/validateFormData';

export const notificationSettingsFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [NotificationSettingsFields.MinimalRiskLevel]: {
      type: ['string', 'number'],
    },
    [NotificationSettingsFields.CurrentCountry]: {
      type: 'boolean',
    },
    [NotificationSettingsFields.SubscribeCountry]: {
      type: 'boolean',
    },
    [NotificationSettingsFields.FamilyMemberLocation]: {
      type: 'boolean',
    },
  },

  required: [],
};

export const validateNotificationSettingsForm = (
  data: CreateGroupCommand,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, notificationSettingsFormSchema);
