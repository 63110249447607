import { AlertComponentPropsWithStyle } from 'react-alert';

import { Trans } from 'react-i18next';

import { CloseButton, Wrapper } from './alertTemplate.styles';

import { ReactComponent as MailSend } from '../../assets/circle-check-in.svg';

export type IAlertTemplateProps = AlertComponentPropsWithStyle;

const AlertTemplate = ({
  close,
  message,
  options,
  style,
}: IAlertTemplateProps) => (
  <Wrapper type={options.type} style={style}>
    <Trans>
      {options.type === 'success' && <MailSend />}
      {message}
      <CloseButton onClick={close} />
    </Trans>
  </Wrapper>
);

export default AlertTemplate;
