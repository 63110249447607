import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { StyledInput } from '../../Input/input.styles';
import { PhoneInput } from '../../PhoneInput/PhoneInput';

type StyledFormInputType = {
  error?: boolean;
};

export const StyledFormPhoneInput = styled(PhoneInput)<StyledFormInputType>`
  width: 100%;
  border-radius: 12px;

  ${({ error, theme }) =>
    error &&
    css`
      ${StyledInput} {
        border-color: ${theme.color.error_500};
      }
    `}
`;
