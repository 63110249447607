import { createSelector } from 'reselect';

import { RootState } from '../index';
import { RiskAlertModel } from '../../../api/base-api';
import { subcribedCountriesSelector } from '../countries/selectors';
import { ALL_SUBSCRIBED_COUNTRIES } from '../../../pages/riskAlerts/riskAlerts.consts';

export const riskAlertStateSelector = (state: RootState) => state.riskAlerts;

export const riskAlertsSelector = (countryCode: string) =>
  createSelector(
    riskAlertStateSelector,
    subcribedCountriesSelector,
    (riskAlerts, subscribedCountries): RiskAlertModel[] => {
      if (countryCode === ALL_SUBSCRIBED_COUNTRIES) {
        return subscribedCountries
          .map((subCountry) => riskAlerts?.[subCountry.code])
          .flatMap((x) => x)
          .filter((x) => x);
      } else {
        return riskAlerts[countryCode] || [];
      }
    },
  );
