import { InputHTMLAttributes } from 'react';

import { Alignment } from '../../../models/types/Alignment';
import { CustomField } from '../../../models/types/CustomField';
import { CustomFieldLabel } from '../../../models/types/CustomFieldLabel';
import { FieldWrapper } from '../Field/Field';
import FieldLabel from '../Field/FieldLabel';
import { useInput } from './input.hooks';
import { StyledInput } from './input.styles';

export interface IInputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    CustomField,
    CustomFieldLabel {
  alignment?: Alignment;
  charsBeforeValue?: string;
  onlyNumber?: boolean;
  name?: string;
  placeholder?: string;
  id?: string;
}

export const Input = ({
  alignment = 'left',
  charsBeforeValue,
  className,
  disabled,
  value,
  isLabelHidden,
  labelAnimation,
  label,
  ...props
}: IInputProps) => {
  const { onChangeHandler } = useInput(props);

  return (
    <FieldWrapper className={className}>
      {!isLabelHidden && (
        <FieldLabel disabled={disabled} autoLabelAnimation={labelAnimation}>
          {label}
        </FieldLabel>
      )}
      <StyledInput
        {...props}
        onChange={onChangeHandler}
        disabled={disabled}
        value={`${charsBeforeValue ?? ''}${value}`}
      />
    </FieldWrapper>
  );
};
