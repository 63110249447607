import styled from '@emotion/styled/macro';

import { typography } from '../../../common/consts/typography';

import { StandardText } from '../../Typography/Typography';
import { FormRadioGroup } from '../../UI/Form/FormRadioGroup/FormRadioGroup';
import { Label, VisibleRadio } from '../../UI/Radio/radio.styles';

export const Description = styled(StandardText)`
  text-align: center;
`;

export const customCheckmark = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: ${({ theme }) => theme.color.primary_500};
`;

export const GroupInput = styled(FormRadioGroup)`
  ${VisibleRadio} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 2px solid ${({ theme, disabled }) => theme.color.primary_500};

    &[disabled] {
      border: 2px solid ${({ theme, disabled }) => theme.color.primary_200};

      ${customCheckmark} {
        background-color: ${({ theme, checked }) => theme.color.primary_200};
      }
    }
  }

  ${Label} {
    ${typography.bodyXS};
    color: ${({ theme, checked }) => theme.color.neutral_2_900};

    &[disabled] {
      color: ${({ theme, checked }) => theme.color.neutral_2_400};
    }
  }
`;
