import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import {
  AuthenticationResult,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import i18n from './i18n/i18n';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { Provider as AlertProvider } from 'react-alert';

import { PersistGate } from 'redux-persist/integration/react';

import { configureStore } from './store';
import { muiTheme, THEME } from './common/consts/theme';
import { msalConfig } from './common/config/auth';
import reportWebVitals from './reportWebVitals';
import App from './App';
import AlertTemplate from './components/AlertTemplate/AlertTemplate';

import '@fontsource/poppins/100.css';
import '@fontsource/poppins/200.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/source-sans-pro';

import { StylesProvider } from '@mui/styles';
import { userActions } from './store/saga-slices/user';
import { I18nextProvider } from 'react-i18next';

export const PCA = new PublicClientApplication(msalConfig);
export const { store, persistor } = configureStore();

const accounts = PCA.getAllAccounts();
if (accounts.length > 0) {
  PCA.setActiveAccount(accounts[0]);
}

PCA.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    const payload = event?.payload as AuthenticationResult;
    const account = payload.account;
    PCA.setActiveAccount(account);
    if (account) {
      store.dispatch(userActions.loadUserData(null));
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <MsalProvider instance={PCA}>
          <MuiThemeProvider theme={muiTheme}>
            <StylesProvider injectFirst>
              <ThemeProvider theme={THEME}>
                <AlertProvider
                  template={AlertTemplate}
                  position="top right"
                  timeout={3000}
                  containerStyle={{ right: '24px', top: '90px', left: 'unset' }}
                >
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </AlertProvider>
              </ThemeProvider>
            </StylesProvider>
          </MuiThemeProvider>
        </MsalProvider>
      </I18nextProvider>
    </PersistGate>
  </Provider>,
);
reportWebVitals();
