import { Field } from 'react-final-form';

import { CustomForm } from '../../../models/types/CustomForm';
import { SectionWrapper } from '../../UI/Form/Form';
import { FormColumn } from '../../UI/Form/FormColumn/FormColumn';
import { FormRow } from '../../UI/Form/FormRow/FormRow';
import { FormInput } from '../../UI/Form/FormInput/FormInput';
import { EmergencyContactFields } from './emergencyContactForm.models';
import { getFieldPropertiesForGroup } from '../../../utils/formGroupFieldHelpers';
import { FormPhoneInput } from '../../UI/Form/FormPhoneInput/FormPhoneInput';

type EmergencyContactFormProps = CustomForm & {
  name?: string;
};

const EmergencyContactForm = ({
  defaultValues,
  className,
  disabled,
  name,
}: EmergencyContactFormProps) => (
  <SectionWrapper>
    <FormColumn className={className}>
      <FormRow>
        <Field
          component={FormInput}
          disabled={disabled}
          defaultValue={defaultValues?.[EmergencyContactFields.FirstName]}
          {...getFieldPropertiesForGroup({
            inputId: 'emergency-contact-firstname-input',
            name: EmergencyContactFields.FirstName,
            groupName: name,
          })}
        />
      </FormRow>
      <FormRow>
        <Field
          component={FormInput}
          disabled={disabled}
          defaultValue={defaultValues?.[EmergencyContactFields.LastName]}
          {...getFieldPropertiesForGroup({
            inputId: 'emergency-contact-lastname-input',
            name: EmergencyContactFields.LastName,
            groupName: name,
          })}
        />
      </FormRow>
      <FormRow>
        <Field
          component={FormInput}
          disabled={disabled}
          defaultValue={defaultValues?.[EmergencyContactFields.Email]}
          {...getFieldPropertiesForGroup({
            inputId: 'emergency-contact-email-input',
            name: EmergencyContactFields.Email,
            groupName: name,
          })}
        />
      </FormRow>
      <FormRow>
        <Field
          component={FormPhoneInput}
          disabled={disabled}
          defaultValue={defaultValues?.[EmergencyContactFields.PhoneNumber]}
          {...getFieldPropertiesForGroup({
            inputId: 'emergency-contact-phone-input',
            name: EmergencyContactFields.PhoneNumber,
            groupName: name,
          })}
        />
      </FormRow>
    </FormColumn>
  </SectionWrapper>
);

export default EmergencyContactForm;
