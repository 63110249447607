import { Form } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';

import { useRenameGroupForm } from './renameGroupForm.hooks';

import { Description, SubmitButton } from './renameGroupForm.styles';
import { FormPage } from '../../UI/Form/Form';
import RenameGroupForm from './renameGroupForm.form';
import { SectionTitle } from '../../Typography/Typography';
import FormErrorActivator from '../../FormErrorProvider/FormErrorActivator/FormErrorActivator';

const RenameGroupFormPage = () => {
  const { t } = useTranslation();

  const { onSubmitHandler, isLoading, defaultValue, validateForm } =
    useRenameGroupForm();

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={onSubmitHandler}
      validate={validateForm}
    >
      {({ values, dirty, valid, ...renderProps }) => (
        <FormErrorActivator>
          <FormPage onSubmit={renderProps.handleSubmit}>
            <SectionTitle>{t('headers.rename-group')}</SectionTitle>
            <Trans>
              <Description>
                {t('phrase.enter-your-new-group-name', { name: defaultValue })}
              </Description>
            </Trans>
            <RenameGroupForm disabled={isLoading} defaultValue={defaultValue} />
            <SubmitButton isLoading={isLoading} styleType="base-filled">
              {t('buttons.rename')}
            </SubmitButton>
          </FormPage>
        </FormErrorActivator>
      )}
    </Form>
  );
};

export default RenameGroupFormPage;
