import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EmergencyContactModel } from '../../../api/base-api';

const initState: { emergencyContacts: Array<EmergencyContactModel> } = {
  emergencyContacts: [],
};

const emergencyContactsSlice = createSlice({
  name: 'emergencyContacts',
  initialState: initState,
  reducers: {
    setEmergencyContacts: (
      state,
      action: PayloadAction<Array<EmergencyContactModel>>,
    ) => {
      state.emergencyContacts = action.payload;
    },
    addEmergencyContacts: (
      state,
      action: PayloadAction<EmergencyContactModel>,
    ) => {
      state.emergencyContacts.push(action.payload);
    },
    editEmergencyContacts: (
      state,
      action: PayloadAction<EmergencyContactModel>,
    ) => {
      const emergencyIndex = state.emergencyContacts.findIndex(
        (contact) => contact.id === action.payload.id,
      );
      state.emergencyContacts[emergencyIndex] = action.payload;
    },
    removeEmergencyContact: (state, action: PayloadAction<string>) => {
      state.emergencyContacts = state.emergencyContacts.reduce(function (
        prevValue: any[],
        value: EmergencyContactModel,
        index,
      ) {
        if (value.id !== action.payload) {
          prevValue.push(value);
        }
        return prevValue;
      },
      []);
    },
  },
});

export const {
  addEmergencyContacts,
  setEmergencyContacts,
  removeEmergencyContact,
  editEmergencyContacts,
} = emergencyContactsSlice.actions;
export default emergencyContactsSlice.reducer;
