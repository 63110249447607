import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { call, put, putResolve, select } from 'typed-redux-saga/macro';
import { updateProcessState } from '../../redux-slices/processes';
import {
  ALL_MORE_LOADING,
  NOTIFICATIONS_LOADING_PROCESSING,
  PEOPLE_MORE_LOADING,
  RISKALERT_MORE_LOADING,
} from '../../redux-slices/processes/constants';
import { NotificationsApi } from '../../../api/base-api';
import { ICallbackSaga } from '../../../models/interfaces/CallbackSaga';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  addMemberRelatedNotifications,
  addNotifications,
  addRiskAlertNotifications,
  setMemberRelatedFullLoaded,
  setNotificationReaded,
  setRiskAlertFullLoaded,
} from '../../redux-slices/notifications';
import {
  latestAllNotificationsIdSelector,
  latestMemberRelationNotificationsIdSelector,
  latestRiskAlertNotificationsIdSelector,
} from '../../redux-slices/notifications/selectors';
import { NOTIFICATION_FETCH_COUNT } from '../../redux-slices/notifications/model';

const notificationsSlice = createSliceSaga({
  name: 'notifications-saga',
  caseSagas: {
    *loadMoreAll(action: PayloadAction<ICallbackSaga>) {
      try {
        yield put(updateProcessState(ALL_MORE_LOADING));
        const lastId = yield* select(latestAllNotificationsIdSelector);
        const { data } = yield* call(
          new NotificationsApi().notificationsAllGet,
          NOTIFICATION_FETCH_COUNT,
          undefined,
          lastId,
        );
        if (data.length) {
          yield putResolve(addNotifications(data));
          if (data.length < NOTIFICATION_FETCH_COUNT) {
            yield putResolve(setMemberRelatedFullLoaded());
            yield putResolve(setRiskAlertFullLoaded());
          }
        } else {
          yield putResolve(setMemberRelatedFullLoaded());
          yield putResolve(setRiskAlertFullLoaded());
        }
        action.payload?.success?.();
      } catch (e) {
        action.payload?.error?.();
      } finally {
        yield put(updateProcessState(ALL_MORE_LOADING));
      }
    },
    *loadMorePeople(action: PayloadAction<ICallbackSaga>) {
      try {
        yield put(updateProcessState(PEOPLE_MORE_LOADING));
        const lastId = yield* select(
          latestMemberRelationNotificationsIdSelector,
        );

        const { data } = yield* call(
          new NotificationsApi().notificationsMemberRelatedGet,
          NOTIFICATION_FETCH_COUNT,
          undefined,
          lastId,
        );

        if (data.length) {
          yield putResolve(addMemberRelatedNotifications(data));

          if (data.length < NOTIFICATION_FETCH_COUNT) {
            yield putResolve(setMemberRelatedFullLoaded());
          }
        } else {
          yield putResolve(setMemberRelatedFullLoaded());
        }
        action.payload?.success?.();
      } catch (e) {
        action.payload?.error?.();
      } finally {
        yield put(updateProcessState(PEOPLE_MORE_LOADING));
      }
    },
    *loadMoreRiskAlerts(action: PayloadAction<ICallbackSaga>) {
      try {
        yield put(updateProcessState(RISKALERT_MORE_LOADING));
        const lastId = yield* select(latestRiskAlertNotificationsIdSelector);

        const { data } = yield* call(
          new NotificationsApi().notificationsRiskAlertsGet,
          NOTIFICATION_FETCH_COUNT,
          undefined,
          lastId,
        );

        if (data.length) {
          yield putResolve(addRiskAlertNotifications(data));

          if (data.length < NOTIFICATION_FETCH_COUNT) {
            yield putResolve(setRiskAlertFullLoaded());
          }
        } else {
          yield putResolve(setRiskAlertFullLoaded());
        }
        action.payload?.success?.();
      } catch (e) {
        action.payload?.error?.();
      } finally {
        yield put(updateProcessState(RISKALERT_MORE_LOADING));
      }
    },
    *markNotificationReaded(action: PayloadAction<number>) {
      try {
        yield put(updateProcessState(NOTIFICATIONS_LOADING_PROCESSING));

        const notificationsApi = new NotificationsApi();

        yield* call(
          notificationsApi.notificationsNotificationIdActionsReadPost,
          action.payload,
        );
        yield put(setNotificationReaded(action.payload));
        // action.payload?.success?.()
      } catch (e) {
        // action.payload?.error?.()
      } finally {
        yield put(updateProcessState(NOTIFICATIONS_LOADING_PROCESSING));
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default notificationsSlice.saga;
export const {
  loadMoreAll,
  loadMorePeople,
  loadMoreRiskAlerts,
  markNotificationReaded,
} = notificationsSlice.actions;
export const { actions } = notificationsSlice;
