import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { put, select } from 'typed-redux-saga/macro';

import {
  setCoords,
  setDefaultMembers,
  setMembers,
} from '../../redux-slices/map';
import { membersGroupSelector } from '../../redux-slices/groups/selectors';
import { defaultMembersSelector } from '../../redux-slices/map/selectors';

const mapSlice = createSliceSaga({
  name: 'map-saga',
  caseSagas: {
    *navigateToCoordinate(action: PayloadAction<google.maps.LatLngLiteral>) {
      yield put(setCoords(action.payload));
    },
    *setMapMembers(action: PayloadAction<string[]>) {
      yield put(setDefaultMembers(action.payload));
      yield put(setMembers(action.payload));
    },
    *selectGroupMembers(action: PayloadAction<string | undefined>) {
      if (action.payload) {
        const members = yield* select(membersGroupSelector(action.payload));
        const ids = members?.map((member) => member.id) || [];
        yield put(setMembers(ids!));
      } else {
        const defaultMembers = yield* select(defaultMembersSelector);
        yield put(setMembers(defaultMembers));
      }
    },
    *selectMember(action: PayloadAction<string | undefined>) {
      if (action.payload) {
        yield put(setMembers([action.payload]));
      } else {
        const defaultMembers = yield* select(defaultMembersSelector);
        yield put(setMembers(defaultMembers));
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default mapSlice.saga;
export const {
  navigateToCoordinate,
  setMapMembers,
  selectGroupMembers,
  selectMember,
} = mapSlice.actions;
export const { actions } = mapSlice;
