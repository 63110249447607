import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { call, put, select } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import moment from 'moment';

import { CardNumberElement } from '@stripe/react-stripe-js';

import { membershipActions as membershipReduxActions } from '../../redux-slices/membership';
import { changeToNextStep } from '../wizard';
import { stepsDetailsSelector } from '../../redux-slices/wizard/selectors';
import {
  ISendAddFamilyMemberPayload,
  ISendConfirmPaymentPayload,
  ISendValidateAddressPayload,
  ISendValidateDateOPfBirthPayload,
} from './model';
import {
  AssignedEntityType,
  InvitationsApi,
  MembershipApi,
  PlanType,
} from '../../../api/base-api';
import { updateProcessState } from '../../redux-slices/processes';
import {
  ADD_MEMBERSHIP_ADDRESS_PROCESSING,
  ADD_MEMBERSHIP_DATE_OF_BIRTH_PROCESSING,
  ADD_MEMBERSHIP_PAYMENT_PROCESSING,
  MEMBERSHIP_FAMILY_MEMBER_PROCESSING,
  SETUP_ACCOUNT_PROCESSING,
} from '../../redux-slices/processes/constants';
import { userSelector } from '../../redux-slices/user/selectors';
import { updateUserImage } from '../user';
import {
  setActiveWizard,
  updateAvailableSteps,
  updateDetailWizard,
} from '../../redux-slices/wizard';
import { WizardStepsCn } from '../../redux-slices/wizard/wizards/SignUp';
import { updateModalState } from '../../redux-slices/modals';
import { ADD_FAMILY_MEMBER_MODAL_NAME } from '../../../pages/membership/Modals/AddFamilyMemberModal/AddFamilyMemberModal';
import { membershipSelector } from '../../redux-slices/membership/selectors';
import { ADD_MEMBERSHIP_WIZARD_NAME } from '../../redux-slices/wizard/wizards/AddMembership';
import i18n from 'i18next';
import { fetchMembershipSummaryData } from '../membership';

const signUpSlice: any = createSliceSaga({
  name: 'signup-saga',
  caseSagas: {
    *setSignUpPlan(action: PayloadAction<string>) {
      yield put(updateDetailWizard({ planType: action.payload }));

      if (action.payload === 'Family') {
        yield put(
          updateAvailableSteps([
            {
              isAvailable: true,
              canonicalName: WizardStepsCn.FamilyMembersPage,
            },
          ]),
        );
      }

      if (action.payload === 'Free') {
        yield put(
          updateAvailableSteps([
            { canonicalName: WizardStepsCn.AddressPage, isAvailable: false },
            {
              canonicalName: WizardStepsCn.DateOfBirthPage,
              isAvailable: false,
            },
            {
              canonicalName: WizardStepsCn.PaymentPage,
              isAvailable: false,
            },
          ]),
        );
        yield put(signUpActions.checkUsageCode());
      }
    },
    *submitAddressStep(action: PayloadAction<ISendValidateAddressPayload>) {
      try {
        yield put(updateProcessState(ADD_MEMBERSHIP_ADDRESS_PROCESSING));

        const membershipApi = new MembershipApi();

        yield* call(membershipApi.membershipsActionsValidateAddressPost, {
          residentialAddress: action.payload.residentialAddress,
          billingAddress: action.payload.billingAddress,
        });
        yield put(changeToNextStep(action.payload));
      } catch (err: any) {
        action.payload.error?.(err);
      } finally {
        yield put(updateProcessState(ADD_MEMBERSHIP_ADDRESS_PROCESSING));
      }
    },
    *submitDateOfBirthStep(
      action: PayloadAction<ISendValidateDateOPfBirthPayload>,
    ) {
      try {
        yield put(updateProcessState(ADD_MEMBERSHIP_DATE_OF_BIRTH_PROCESSING));
        const membershipApi = new MembershipApi();

        // yield* call(membershipApi.membershipsActionsValidateDateOfBirthPost, {
        //   dateOfBirth: action.payload.dateOfBirth,
        // });

        const details = yield* select(stepsDetailsSelector);

        const response = yield* call(membershipApi.membershipsPost, {
          planType: details.planType,
          dateOfBirth: moment(action.payload.dateOfBirth).toISOString(),
          billingAddress: {
            street: details.billingAddress.street,
            number: details.billingAddress.number,
            city: details.billingAddress.city,
            country: details.billingAddress.country,
            state: details.billingAddress.state,
            zipCode: details.billingAddress.zipCode,
          },
          residentialAddress: {
            street: details.residentialAddress.street,
            number: details.residentialAddress.number,
            city: details.residentialAddress.city,
            country: details.residentialAddress.country,
            state: details.residentialAddress.state,
            zipCode: details.residentialAddress.zipCode,
          },
        });

        yield put(
          changeToNextStep({
            ...action.payload,
            clientSecret: response.data.clientSecret,
          }),
        );
      } catch (err: any) {
        action.payload.error?.(err);
      } finally {
        yield put(updateProcessState(ADD_MEMBERSHIP_DATE_OF_BIRTH_PROCESSING));
      }
    },
    *submitPaymentStep(action: PayloadAction<ISendConfirmPaymentPayload>) {
      try {
        yield put(updateProcessState(ADD_MEMBERSHIP_PAYMENT_PROCESSING));

        const { elements, stripe, clientSecret } = action.payload;

        const result = yield* call(stripe.confirmCardPayment, clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement)!,
          },
        });

        if (result.error) {
          // Show error to your customer (for example, payment details incomplete)
          console.log(result.error.message);
          action.payload.error?.(result.error);
        } else {
          const details = yield* select(stepsDetailsSelector);
          yield put(
            membershipReduxActions.setPlan({
              planType: details.planType,
              expirationDate: moment().toISOString(),
            }),
          );
          yield put(membershipReduxActions.setNextUpdate());

          yield put(changeToNextStep({}));
          action.payload.success?.();
        }
      } catch (e) {
        action.payload.error?.(e);
      } finally {
        yield put(updateProcessState(ADD_MEMBERSHIP_PAYMENT_PROCESSING));
      }
      // navigate(RoutePath.MembershipAbsolute);
    },
    *submitSetupAccountStep(action: PayloadAction<{ [x: string]: any }>) {
      try {
        yield put(updateProcessState(SETUP_ACCOUNT_PROCESSING));
        const userData = yield* select(userSelector);

        const updateImagePayload = {
          memberId: userData.id!,
          image: action.payload.photo,
        };

        yield updateUserImage(updateImagePayload);

        yield put(changeToNextStep(action.payload));
      } catch (err: any) {
      } finally {
        yield put(updateProcessState(SETUP_ACCOUNT_PROCESSING));
      }
    },
    *submitSuccessStep() {
      const membership = yield* select(membershipSelector);
      if (membership.plan.planType === PlanType.Free) {
        yield put(setActiveWizard(ADD_MEMBERSHIP_WIZARD_NAME));
      }
    },
    *submitStep(action: PayloadAction<{ [x: string]: any }>) {
      try {
        yield put(changeToNextStep(action.payload));
      } catch (err: any) {
      } finally {
      }
    },
    *sendAddFamilyMember(action: PayloadAction<ISendAddFamilyMemberPayload>) {
      // TODO: Call Api
      const { error, success, familyMember } = action.payload;

      try {
        yield put(updateProcessState(MEMBERSHIP_FAMILY_MEMBER_PROCESSING));
        const membershipApi = new MembershipApi();
        const response = yield* call(
          membershipApi.membershipsCurrentFamilyMembersPost,
          familyMember,
        );

        yield put(
          membershipReduxActions.addFamilyMember({
            ...familyMember,
            familyMemberId: response.data.familyMemberId,
          }),
        );
        yield put(updateModalState(ADD_FAMILY_MEMBER_MODAL_NAME));
        success?.();
      } catch (e) {
        error?.(e);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_FAMILY_MEMBER_PROCESSING));
      }
    },
    *checkUsageCode() {
      const inviteCode = sessionStorage.getItem('inviteCode');
      if (!inviteCode) {
        return;
      }

      try {
        const { data: invitationDetails } = yield* call(
          new InvitationsApi().invitationsAccessCodeGet,
          inviteCode,
        );
        const { data } = yield* call(
          new InvitationsApi().invitationsAccessCodeActionsAcceptInvitationPost,
          inviteCode,
        );

        if (
          invitationDetails.invitationType == AssignedEntityType.FamilyMember
        ) {
          yield fetchMembershipSummaryData();
        }
      } catch (e) {
        yield* put(
          updateDetailWizard({
            validationCodeMessage: i18n.t('phrase.invite-code-invalid'),
          }),
        );
      } finally {
        sessionStorage.removeItem('inviteCode');
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default signUpSlice.saga;
export const signUpActions = signUpSlice.actions;
