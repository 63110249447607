import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Trans } from 'react-i18next';

import { THEME } from '../../../common/consts/theme';
import { mqMax } from '../../../common/utils/mediaQueries';

import { CustomComponent } from '../../../models/types/CustomComponent';

export const FieldWrapper = styled('div')<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  background: transparent;
  border-radius: 12px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      filter: blur(0.25rem);
    `}
`;

export const ErrorLabel = styled(({ children, className }: CustomComponent) => (
  <div className={className}>
    <Trans>{children}</Trans>
  </div>
))`
  margin-top: 0.25rem;
  margin-left: 0.5rem;
  font: 0.875rem/1.5rem ${({ theme }) => theme.font.poppins};
  color: ${({ theme }) => theme.color.error_500};
  text-overflow: ellipsis;
  overflow: hidden;
`;

type FieldLabelType = {
  disabled?: boolean;
  autoLabelAnimation?: boolean;
  labelActive?: boolean;
  inputLabelOffset?: number;
};

type FieldLabelStyleProps = FieldLabelType & {
  theme: Theme;
};

export const fieldLabelInActiveStyle = ({ theme }: FieldLabelStyleProps) => css`
  top: 1.5rem;
  left: 0;
  z-index: 0;
  font-weight: 500;
  font-size: 0.875rem;
  color: ${theme.color.neutral_2_500};
`;

export const fieldLabelActiveStyle = ({ theme }: FieldLabelStyleProps) => css`
  top: 1.5rem;
  left: 0;
  z-index: 2;
  font-size: 0.875rem;
  font-weight: 500;
  color: ${theme.color.neutral_2_500};
`;

export const StyledFieldLabel = styled.label<FieldLabelType>`
  font-family: ${({ theme }) => theme.font.poppins};
  ${fieldLabelActiveStyle};
  transition: top 200ms ease-in-out, left 200ms ease-in-out;
  cursor: text;
  outline: none;

  ${mqMax.smallIp(css`
    color: ${THEME.color.white};
  `)}

  ${({ autoLabelAnimation, labelActive, ...props }) =>
    (autoLabelAnimation || !labelActive) && fieldLabelInActiveStyle(props)}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.color.dark2};
    `}

  ${({ autoLabelAnimation, ...props }) =>
    autoLabelAnimation &&
    css`
            ${FieldWrapper}:focus-within > &,
            ${FieldWrapper}:not(:focus-within) > :not(:placeholder-shown) ~ & {
              ${fieldLabelActiveStyle(props)}
          `};
`;

export const LabelContent = styled.span``;
