import { Trans, useTranslation } from 'react-i18next';

import { Form } from 'react-final-form';

import { Modal } from '../../Modal/Modal';
import {
  ModalButtonsContainer,
  ModalDescription,
  ModalTitle,
} from '../../Modal/modal.styles';
import { usePassOwnershipModal } from './passOwnershipModal.hooks';

import { validatePassOwnershipForm } from '../../Forms/PassOwnershipForm/passOwnershipForm.validation';
import { FormPage } from '../../UI/Form/Form';
import { FormSubmitButton } from '../../UI/Form/FormSubmitButton/FormSubmitButton';
import PassOwnershipForm from '../../Forms/PassOwnershipForm/passOwnershipForm.form';
import { DescriptionText } from '../templates/styles';

export const PASS_OWNERSHIP_GROUP_MODAL_NAME = 'PASS_OWNERSHIP_GROUP_MODAL';

export const PassOwnershipModal = () => {
  const { name, onSubmitHandler, members, isLoading } = usePassOwnershipModal();
  const { t } = useTranslation();

  return (
    <Modal name={PASS_OWNERSHIP_GROUP_MODAL_NAME}>
      <ModalTitle>{t('headers.pass-ownership')}</ModalTitle>
      <Form
        keepDirtyOnReinitialize
        onSubmit={onSubmitHandler}
        validate={validatePassOwnershipForm}
      >
        {({ values, dirty, valid, ...renderProps }) => (
          <FormPage onSubmit={renderProps.handleSubmit}>
            <ModalDescription>
              <Trans>
                <DescriptionText>
                  {t('phrase.transfer-group-ownership', { name })}
                </DescriptionText>
              </Trans>
              <PassOwnershipForm members={members!} />
            </ModalDescription>
            <DescriptionText>
              {t('phrase.action-cannot-be-undone', { name })}
            </DescriptionText>
            <ModalButtonsContainer>
              <FormSubmitButton
                fillButton
                isLoading={isLoading}
                disabled={!valid}
                styleType="base-filled"
              >
                {t('buttons.pass-ownership')}
              </FormSubmitButton>
            </ModalButtonsContainer>
          </FormPage>
        )}
      </Form>
    </Modal>
  );
};
