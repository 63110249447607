import FormControlLabel, {
  FormControlLabelClassKey,
} from '@mui/material/FormControlLabel';
import ReactSwitch, { SwitchClassKey, SwitchProps } from '@mui/material/Switch';

import { CustomField } from '../../../models/types/CustomField';

import { StyledSwitch as Switch } from './switch.styles';

export interface ISwitchProps extends SwitchProps, CustomField {
  defaultChecked?: boolean;
  classesSwitch?: Record<SwitchClassKey | any, string>;
  classesWrapper?: Record<FormControlLabelClassKey | any, string>;
  label?: string;
}

export const SwitchNoStyled = ({
  classesWrapper,
  defaultChecked,
  checked,
  disabled,
  onChange,
  classesSwitch,
  label,
  className,
}: ISwitchProps) => (
  <FormControlLabel
    className={className}
    classes={{
      ...classesWrapper,
    }}
    control={
      <ReactSwitch
        checked={checked}
        classes={{
          ...classesSwitch,
        }}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={onChange}
      />
    }
    label={label!}
  />
);

export default Switch;
