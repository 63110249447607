import { all } from 'redux-saga/effects';

import continuouslySaga from './continuously/index';
import userSaga from './user/index';
import friendsSaga from './friends/index';
import groupsSaga from './groups/index';
import mapSaga from './map/index';
import emergencyContactSaga from './emergencyContacts';
import notificationsSlice from './notifications';
import settingSaga from './settings';
import checkInSaga from './checkIn';
import membersSaga from './members';
import membershipSaga from './membership';
import wizardSaga from './wizard';
import riskAlertsSaga from './riskAlerts';
import countriesSaga from './countries';
import countryBriefsSaga from './countryBriefs';
import accessCodesSaga from './accessCodes';

import signUpSaga from './signUp';

export default function* rootSaga() {
  yield all([
    continuouslySaga(),
    userSaga(),
    friendsSaga(),
    groupsSaga(),
    mapSaga(),
    emergencyContactSaga(),
    notificationsSlice(),
    settingSaga(),
    checkInSaga(),
    membersSaga(),
    membershipSaga(),
    countriesSaga(),
    riskAlertsSaga(),
    accessCodesSaga(),
    countryBriefsSaga(),
    wizardSaga(),
    signUpSaga(),
  ]);
}
