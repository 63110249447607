import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';

import { Modal } from '../../../../../components/Modal/Modal';
import { FormPage } from '../../../../../components/UI/Form/Form';
import { ModalTitle } from '../../../../../components/Modal/modal.styles';
import { FormSubmitButton } from '../../../../../components/UI/Form/FormSubmitButton/FormSubmitButton';
import EmergencyContactForm from '../../../../../components/Forms/EmergencyContactForm/emergencyContactForm.form';
import { useEmergencyContactForm } from '../../../../../components/Forms/EmergencyContactForm/emergencyContactForm.hooks';
import { validateEmergencyContactForm } from '../../../../../components/Forms/EmergencyContactForm/emergencyContactForm.validation';

export const EDIT_EMERGENCY_CONTACT_MODAL_NAME = 'EDIT_EMERGENCY_CONTACT_MODAL';

export const EditEmergencyContactModal = () => {
  const { t } = useTranslation();

  const { onEditSubmitHandler, isLoading, defaultValues } =
    useEmergencyContactForm();

  return (
    <Modal name={EDIT_EMERGENCY_CONTACT_MODAL_NAME}>
      <Form
        keepDirtyOnReinitialize
        onSubmit={onEditSubmitHandler}
        validate={validateEmergencyContactForm}
      >
        {({ values, dirty, valid, ...renderProps }) => (
          <FormPage onSubmit={renderProps.handleSubmit}>
            <ModalTitle>{t('headers.edit-emergency-contact')}</ModalTitle>
            <EmergencyContactForm
              disabled={isLoading}
              defaultValues={defaultValues}
            />
            <FormSubmitButton
              fillButton
              isLoading={isLoading}
              disabled={!valid}
              styleType="base-filled"
            >
              {t('buttons.save')}
            </FormSubmitButton>
          </FormPage>
        )}
      </Form>
    </Modal>
  );
};
