import { ReactNode, useEffect, useMemo } from 'react';

import TabOption, { ITabOptionProps } from './TabOption/TabOption';
import { Wrapper } from './tabOptions.styles';

export interface ITabOptionsProps {
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  readonly?: boolean;
  onChange: (value: string) => void;
  options: ITabOptionProps[];
  value?: string;
  divider?: () => ReactNode;
}

const TabOptions = ({
  className,
  defaultValue,
  disabled,
  onChange,
  options,
  value,
  divider,
  readonly,
}: ITabOptionsProps) => {
  useEffect(() => {
    if (defaultValue) {
      onChange(defaultValue);
    }
  });

  const renderOptions = useMemo(
    () =>
      options.map((option, index) => (
        <>
          <TabOption
            {...option}
            key={option.value}
            disabled={disabled}
            isActive={option.value === value}
            readOnly={readonly}
            onClick={onChange}
          />
          {index < options.length - 1 && divider?.()}
        </>
      )),
    [disabled, divider, onChange, options, readonly, value],
  );
  return <Wrapper className={className}>{renderOptions}</Wrapper>;
};

export default TabOptions;
