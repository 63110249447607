import { Label, Wrapper } from './tabOption.styles';

export interface ITabOptionProps {
  icon?: JSX.Element;
  label: string;
  value: string;
  isActive?: boolean;
  onClick?: (value: string) => void;
  disabled?: boolean;
  readOnly?: boolean;
}

const TabOption = ({
  icon,
  label,
  value,
  onClick,
  ...props
}: ITabOptionProps) => (
  <Wrapper
    {...props}
    onClick={() => (!props.disabled && !props.readOnly ? onClick(value) : {})}
  >
    <Label {...props}>{label}</Label>
  </Wrapper>
);

export default TabOption;
