import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useActions } from '../../../hooks/useActions';
import { groupsAction } from '../../../store/saga-slices/groups';
import { RoutePath } from '../../../models/RoutePath';
import { groupSelector } from '../../../store/redux-slices/groups/selectors';
import { processSelector } from '../../../store/redux-slices/processes/selectors';
import { GROUP_LEAVING_PROCESSING } from '../../../store/redux-slices/processes/constants';
import { RootState } from '../../../store/redux-slices';
import { ExtendGroupModel } from '../../../store/redux-slices/groups';

export const useLeaveGroupModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { groupId } = useParams();
  const group = useSelector<RootState>((state) =>
    groupSelector(state, groupId!),
  ) as ExtendGroupModel;
  const isLoading = useSelector(processSelector(GROUP_LEAVING_PROCESSING));

  const leaveGroup = useActions(groupsAction.sendLeaveGroup);

  const onLeave = useCallback(() => {
    leaveGroup({
      groupId: group!.id!,
      success: () => {
        navigate(RoutePath.HomeAbsolute);
      },
    });
  }, [group, leaveGroup, navigate]);

  return {
    t,
    name: group?.name,
    id: group?.id!,
    onLeave,
    isLoading,
  };
};
