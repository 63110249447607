import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from '../../../hooks/useActions';

import { notificationSettingsSelector } from '../../../store/redux-slices/settings/selectors';
import { loadNotificationSettings } from '../../../store/saga-slices/settings';

export const useNotificationSettingsModal = () => {
  const LoadNotificationSettings = useActions(loadNotificationSettings);
  const settings = useSelector(notificationSettingsSelector);

  useEffect(() => {
    LoadNotificationSettings();
  }, [LoadNotificationSettings]);

  const initialState = useMemo(
    () => ({
      currentCountry: settings.currentCountryEnabled,
      familyMemberLocation: settings.familyMembersLocationEnabled,
      minimalRiskLevel: settings.minimalAlertRiskLevel,
      subscribeCountry: settings.allSubscribedCountriesEnabled,
    }),
    [
      settings.allSubscribedCountriesEnabled,
      settings.currentCountryEnabled,
      settings.familyMembersLocationEnabled,
      settings.minimalAlertRiskLevel,
    ],
  );

  return {
    ...settings,
    initialState,
  };
};
