import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import ReactModal from 'react-modal';

import hexToRgba from 'hex-to-rgba';

import Button from '../UI/Button/Button';
import { SectionTitle } from '../Typography/Typography';
import { StyledSectionTitleProps } from './modal.model';

export const modalOverlayClass = css`
  height: 100%;
  overflow: auto;
  background: linear-gradient(
    360deg,
    ${hexToRgba('#8ABBFF', 0.85)} -100.37%,
    ${hexToRgba('#468CEF', 0.85)} -1.23%,
    ${hexToRgba('#005CDE', 0.85)} 100%
  );
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
  padding: 1rem;
`;

export const StyledModal = styled(ReactModal)(
  ({ theme }) => css`
    background: ${theme.color.neutral_2_50};
    border-radius: 1.5rem;
    min-width: 18rem;
    max-width: 34.5rem;
    margin: auto;
    outline: none;
    position: relative;
  `,
);

export const ModalContainer = styled.div`
  background-color: ${({ theme }) => theme.color.neutral_2_50};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  padding: 40px 40px 43px 40px;
  max-width: 550px;
  min-width: 550px;
  border-radius: 24px;
  box-shadow: 0 4px 44px rgba(10, 38, 78, 0.4);
`;

export const StyledButton = styled(Button)`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
`;

export const GlobalStyles = (isOpen: boolean) =>
  css`
    body {
      ${isOpen &&
      css`
        position: fixed;
        overflow: hidden;
        touch-action: none;
        width: 100%;
      `}
    }
  `;

export const ModalTitle = styled(SectionTitle)<StyledSectionTitleProps>`
  color: ${({ theme }) => theme.color.neutral_2_800};
  text-align: center;

  ${({ theme, styledType }) => {
    switch (styledType) {
      case 'base':
        return css`
          color: ${theme.color.accent_500};
        `;
      case 'success':
        return css`
          color: ${theme.color.success_500};
        `;
      case 'error':
        return css`
          color: ${theme.color.error_500};
        `;
    }
  }}
`;

export const ModalDescription = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  white-space: pre;
  gap: 8px;
  width: 100%;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
`;

export const ModalLeftDescription = styled(ModalDescription)`
  align-items: flex-start;
  width: 100%;
`;

export const ModalButtonsContainer = styled.div`
  display: inline-flex;
  gap: 16px;
  width: 100%;
`;
