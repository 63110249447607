import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../../../common/utils/mediaQueries';

type DefaultType = {
  isActive?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
};

export const Wrapper = styled.div<DefaultType>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  min-width: 76px;
  padding: 16px 0;
  margin: 0 8px;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  ${({ isActive, disabled, theme }) =>
    !isActive &&
    !disabled &&
    css`
      &:hover {
        background-color: ${theme.color.white};
      }
    `}

  ${({ isActive, disabled, theme }) => {
    if (disabled && isActive) {
      return css`
        background-color: ${theme.color.accent_500};
      `;
    }

    if (isActive) {
      return css`
        background-color: transparent;
        opacity: 1;
        ${mq.small(css`
          background-color: ${theme.color.secondary3};
        `)}
        border-bottom: 2px solid ${theme.color.accent_500};
      `;
    }
  }}
`;

export const Label = styled.label<DefaultType>`
  font-size: 12px;
  cursor: pointer;
  line-height: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.neutral_2_900};

  ${({ isActive, theme }) => {
    if (isActive) {
      return css`
        color: ${theme.color.accent_500};
      `;
    }
  }}
`;;
