import { createSliceSaga } from 'redux-toolkit-saga';
import { call, put, select } from 'typed-redux-saga/macro';

import { PayloadAction } from '@reduxjs/toolkit';

import { all } from 'redux-saga/effects';

import { updateProcessState } from '../../redux-slices/processes';

import {
  LOAD_COUNTRY_BRIEF_PROCESSING,
  LOAD_SUBSCRIBED_COUNTRIES_RISK_ALERTS_PROCESSING,
} from '../../redux-slices/processes/constants';
import { CountriesApi } from '../../../api/base-api';
import { ILoadCountryAlerts, ILoadSubscribedCountriesAlerts } from './models';
import { subcribedCountriesSelector } from '../../redux-slices/countries/selectors';
import { countryBriefActions as countryBriefReduxActions } from '../../redux-slices/countryBrief';
import { riskAlertActions as riskAlertReduxActions } from '../../redux-slices/riskAlerts';
import { countryBriefSelector } from '../../redux-slices/countryBrief/selectors';

const riskAlerts = createSliceSaga({
  name: 'risk-alerts',
  caseSagas: {
    *loadSubscribedCountriesBriefs(
      action: PayloadAction<ILoadSubscribedCountriesAlerts>,
    ) {
      try {
        yield put(
          updateProcessState(LOAD_SUBSCRIBED_COUNTRIES_RISK_ALERTS_PROCESSING),
        );
        const countriesApi = new CountriesApi();
        const subscribedCountries = yield* select(subcribedCountriesSelector);
        const calls = [];
        subscribedCountries.forEach((country) =>
          calls.push(
            call(countriesApi.countriesCodeRiskAlertsGet, country.code),
          ),
        );
        const response = yield all(calls);
        const data = [];

        (response as any)?.forEach((item) => {
          data.push(...item.data);
        });
      } catch (e) {
      } finally {
        yield put(
          updateProcessState(LOAD_SUBSCRIBED_COUNTRIES_RISK_ALERTS_PROCESSING),
        );
      }
    },
    *loadCountryBrief(action: PayloadAction<ILoadCountryAlerts>) {
      const countryBrief = yield* select(
        countryBriefSelector(action.payload.countryCode),
      );
      if (!countryBrief) {
        yield put(updateProcessState(LOAD_COUNTRY_BRIEF_PROCESSING));
      }

      try {
        const countriesApi = new CountriesApi();
        const { countryCode } = action.payload;
        const countryBriefResponse = yield* call(
          countriesApi.countriesCodeBriefGet,
          countryCode,
        );

        yield put(
          countryBriefReduxActions.addCountryBrief({
            countryCode: countryCode,
            countryBrief: countryBriefResponse.data,
          }),
        );

        const riskAlertsResponse = yield* call(
          countriesApi.countriesCodeRiskAlertsGet,
          countryCode,
        );

        yield put(
          riskAlertReduxActions.addCountryAlerts({
            countryCode: countryCode,
            riskAlerts: riskAlertsResponse.data,
          }),
        );
      } catch (e) {
      } finally {
        if (!countryBrief) {
          yield put(updateProcessState(LOAD_COUNTRY_BRIEF_PROCESSING));
        }
      }
    },
  },
});

export default riskAlerts.saga;
export const countryBriefActions = riskAlerts.actions;
export const { actions } = riskAlerts;
