import moment from 'moment';

export const formatCvv = (cvv: string) => {
  if (!cvv) return cvv;
  const digits = cvv.replace(/[^\d]/g, '');
  return digits.slice(0, 4);
};

export const formatCardNumber = (cardNumber: string) => {
  if (!cardNumber) return cardNumber;
  const digits = cardNumber.replace(/[^\d]/g, '');
  return digits.slice(0, 16);
};
export const parseCardNumber = (cardNumber: string) => {
  if (!cardNumber) return cardNumber;
  const digits = cardNumber.replace(/[^\d]/g, '');
  return digits.slice(0, 16);
};

export const DEFAULT_EXPIRATION_DATE_CARD_FORMAT = 'MM/YY';
export const DEFAULT_EXPIRATION_DATE_CARD_PARSE = 'MM/YY';

export const parseDate = (date: string) => {
  if (!date) return date;
  return moment(date).format(DEFAULT_EXPIRATION_DATE_CARD_FORMAT);
};

export const formatDate = (date: string) => {
  if (!date) return date;
  return moment(date, DEFAULT_EXPIRATION_DATE_CARD_FORMAT).toString();
};
