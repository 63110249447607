import { Trans } from 'react-i18next';

import { Modal } from '../../Modal/Modal';
import { ModalDescription, ModalTitle } from '../../Modal/modal.styles';
import Button from '../../UI/Button/Button';
import { useInviteToGroupModal } from './inviteToGroupModal.hooks';
import {
  ButtonsContainer,
  CodeCell,
  CodeContainer,
} from './inviteToGroupModal.styles';
import { StandardText } from '../../Typography/Typography';

import { ReactComponent as Copy } from '../../../assets/copy.svg';
import { ReactComponent as Link } from '../../../assets/link.svg';
import { ReactComponent as Mail } from '../../../assets/mail_blue.svg';
import { ReactComponent as Check } from '../../../assets/check-blue.svg';

export const INVITE_TO_GROUP_MODAL_NAME = 'INVITE_TO_GROUP_MODAL';

export const InviteToGroupModal = () => {
  const {
    t,
    inviteCode,
    sendMail,
    copyCode,
    copyLink,
    expirationIn,
    onRefresh,
    name,
    codeIsCopied,
    linkIsCopied,
    isExpired,
  } = useInviteToGroupModal();

  return (
    <Modal name={INVITE_TO_GROUP_MODAL_NAME}>
      <ModalTitle>{t('headers.share-group-invitation')}</ModalTitle>
      <ModalDescription>
        <Trans>
          <StandardText>
            {t('phrase.share-group-invitation', { name })}
          </StandardText>
        </Trans>
        <CodeContainer>
          {inviteCode?.accessCode
            ?.split('')
            .map?.((char: string, index: number) => (
              <CodeCell key={`${index}${char}`}>{char}</CodeCell>
            ))}
        </CodeContainer>
        <ButtonsContainer>
          <Button
            disabled={isExpired}
            fillButton
            styleType="white-blue"
            onClick={copyCode}
          >
            {codeIsCopied ? <Check /> : <Copy />}
            {codeIsCopied ? t('phrase.code-copied') : t('buttons.copy-code')}
          </Button>
          <Button
            disabled={isExpired}
            fillButton
            styleType="white-blue"
            onClick={copyLink}
          >
            {linkIsCopied ? <Check /> : <Link />}
            {linkIsCopied ? t('phrase.link-copied') : t('buttons.copy-link')}
          </Button>
          <Button
            disabled={isExpired}
            fillButton
            styleType="white-blue"
            onClick={sendMail}
          >
            <Mail />
            {t('buttons.send-via-mail')}
          </Button>
        </ButtonsContainer>
        <StandardText>
          {expirationIn >= 0 &&
            t('phrase.code-active', { count: expirationIn })}
          {expirationIn < 0 &&
            t('phrase.code-expired', { count: expirationIn })}
        </StandardText>
        <Button styleType="link" onClick={onRefresh}>
          {t('buttons.refresh-code')}
        </Button>
      </ModalDescription>
    </Modal>
  );
};
