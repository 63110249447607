import { createSelector } from 'reselect';

import { RootState } from '../index';

export const accessCodesSelector = (state: RootState) => state.accessCodes;

export const friendAccessCodeSelector = createSelector(
  accessCodesSelector,
  (accessCodes) => accessCodes.friend,
);

export const groupAccessCodeSelector = (groupId) =>
  createSelector(
    accessCodesSelector,
    (accessCodes) => accessCodes.groups[groupId],
  );

export const familyMemberAccessCodeSelector = (memberId) =>
  createSelector(
    accessCodesSelector,
    (accessCodes) => accessCodes.family[memberId],
  );
