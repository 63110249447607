// @flow
import { useSelector } from 'react-redux';

import {
  modalDetailsSelector,
  modalStateSelector,
} from '../store/redux-slices/modals/selectors';
import { useActions } from './useActions';
import { updateModalState } from '../store/redux-slices/modals';

export const useModal = (name: string, callback?: () => void) => {
  const modalDetails = useSelector(modalDetailsSelector(name));
  const UpdateModalState = useActions(updateModalState);
  const isOpen = useSelector(modalStateSelector);

  return {
    isOpen,
    updateModalState: (details?: any) => {
      UpdateModalState([name, { ...details }]);
    },
    details: modalDetails,
  };
};
