import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { Input } from '../../Input/Input';
import { StyledInput } from '../../Input/input.styles';

type StyledFormInputType = {
  error?: boolean;
};

export const StyledFormInput = styled(Input)<StyledFormInputType>`
  width: 100%;
  border-radius: 12px;

  ${({ error, theme }) =>
    error &&
    css`
      ${StyledInput} {
        border-color: ${theme.color.error_500};
      }
    `}
`;
