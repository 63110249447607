import { createContext, ReactNode, useMemo } from 'react';

import { FieldErrorMessage } from '../../models/interfaces/FieldErrorMessage';

import { useFormErrors } from './FormErrorProvider.hooks';

type FormErrorInfoState = {
  errors: FieldErrorMessage[];
};

type FormErrorActionableState<T = any> = {
  addErrors: (apiErrors: any, values: T) => void;
  addError: (apiError: any, value: T) => void;
  removeErrors: (propertiesNames: string[]) => void;
  validateErrors: (values: T) => FieldErrorMessage[];
  clearErrors: () => void;
};

type FormErrorProviderProps = {
  children?: ReactNode;
  initErrorGroups?: string[];
};

export const FormErrorInfoContext = createContext<FormErrorInfoState>({
  errors: [],
});

export const FormErrorActionableContext =
  createContext<FormErrorActionableState>({
    clearErrors: null,
    validateErrors: null,
    addError: null,
    addErrors: null,
    removeErrors: null,
  });

const FormErrorProvider = ({
  children,
  initErrorGroups,
}: FormErrorProviderProps) => {
  const {
    errors,
    validateErrors,
    clearErrors,
    addError,
    addErrors,
    removeErrors,
  } = useFormErrors({ initErrorGroups });

  const actionableValue = {
    clearErrors,
    validateErrors,
    addError,
    addErrors,
    removeErrors,
  };

  const infoValue = useMemo(
    () => ({
      errors,
    }),
    [errors],
  );

  return (
    <FormErrorInfoContext.Provider value={infoValue}>
      <FormErrorActionableContext.Provider value={actionableValue}>
        {children}
      </FormErrorActionableContext.Provider>
    </FormErrorInfoContext.Provider>
  );
};

export default FormErrorProvider;
