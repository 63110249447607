import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import CreateGroupFormPage from '../../Forms/CreateGroupForm/CreateGroupForm';
import { mq } from '../../../common/utils/mediaQueries';
import { typography } from '../../../common/consts/typography';

export const StyledCreateGroupForm = styled(CreateGroupFormPage)`
  padding: 2.5rem 0;
  margin: 0 1rem;

  ${mq.xSmall(css`
    margin: 0 2.5rem;
  `)}
`;

export const Title = styled.div(
  ({ theme }) => css`
    color: ${theme.color.neutral_2_900};
    ${typography.bodySm};
  `,
);

export const SectionGroupTitle = styled.div(
  ({ theme }) => css`
    color: ${theme.color.neutral_2_500};
    ${typography.bodyXS};
    padding-bottom: 8px;
  `,
);
