import React from 'react';
import { Global } from '@emotion/react';

import globalStyles from './common/consts/globalStyles';

import { useApp } from './app.hooks';
import loadable from '@loadable/component';
import './i18n/i18n';
import { enablePremium } from './common/config/enviroment';

const AppRoutes = loadable(() => import('./AppRoutes'));
const EmergencyRequestModal = loadable(
  () =>
    import('./components/Modals/EmergencyRequestModal/EmergencyRequestModal'),
);
const ContactingResponseTeamModal = loadable(
  () =>
    import(
      './components/Modals/ContactingResponseTeamModal/ContactingResponseTeamModal'
    ),
);
const ContactingResponseTeamSuccessModal = loadable(
  () =>
    import(
      './components/Modals/ContactingResponseTeamSuccessModal/ContactingResponseTeamSuccessModal'
    ),
);
const ContactingResponseTeamErrorModal = loadable(
  () => import('./components/Modals/ContactingResponseTeamErrorModal'),
);
function App() {
  useApp();

  return (
    <div className="App">
      <Global styles={globalStyles} />
      <AppRoutes />
      {enablePremium && (
        <>
          <EmergencyRequestModal />
          <ContactingResponseTeamModal />
          <ContactingResponseTeamSuccessModal />
          <ContactingResponseTeamErrorModal />
        </>
      )}
    </div>
  );
}

export default App;
