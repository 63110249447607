import { Trans, useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';

import { Modal } from '../../Modal/Modal';
import { FormPage } from '../../UI/Form/Form';
import { FormSubmitButton } from '../../UI/Form/FormSubmitButton/FormSubmitButton';
import { ModalLeftDescription, ModalTitle } from '../../Modal/modal.styles';
import NotificationSettingsForm from '../../Forms/NotificationSettingsForm/notificationSettingsForm.form';
import { Title } from './notificationSettingsModal.styles';
import { useNotificationSettingsForm } from '../../Forms/NotificationSettingsForm/notificationSettingsForm.hooks';
import { validateNotificationSettingsForm } from '../../Forms/NotificationSettingsForm/notificationSettingsForm.validation';
import { useNotificationSettingsModal } from './notificationSettingsModal.hooks';

export const NOTIFICATION_SETTINGS_MODAL_NAME = 'NOTIFICATION_SETTINGS_MODAL';

export const NotificationSettingsModal = () => {
  const { t } = useTranslation();

  const { onSubmitHandler, isLoading } = useNotificationSettingsForm();
  const { initialState } = useNotificationSettingsModal();

  return (
    <Modal name={NOTIFICATION_SETTINGS_MODAL_NAME}>
      <Form
        keepDirtyOnReinitialize
        onSubmit={onSubmitHandler}
        validate={validateNotificationSettingsForm}
        initialValues={initialState}
      >
        {({ values, dirty, valid, ...renderProps }) => (
          <FormPage onSubmit={renderProps.handleSubmit}>
            <ModalTitle>{t('headers.notification-settings')}</ModalTitle>
            <ModalLeftDescription>
              <Trans>
                <Title>{t('headers.risk-alerts')}</Title>
              </Trans>
            </ModalLeftDescription>
            <NotificationSettingsForm />
            <FormSubmitButton
              styleType="base-filled"
              fillButton
              disabled={false}
              isLoading={isLoading}
            >
              {t('buttons.save')}
            </FormSubmitButton>
          </FormPage>
        )}
      </Form>
    </Modal>
  );
};
