import { Field, FieldRenderProps, useField } from 'react-final-form';

import moment from 'moment';

import { useSelector } from 'react-redux';

import { CustomForm } from '../../../models/types/CustomForm';
import { SectionWrapper } from '../../UI/Form/Form';
import { FormColumn } from '../../UI/Form/FormColumn/FormColumn';
import { FormRow } from '../../UI/Form/FormRow/FormRow';
import { FormInput } from '../../UI/Form/FormInput/FormInput';
import {
  FamilyMemberFields,
  familyRelationOptions,
} from './familyMemberForm.models';
import { getFieldPropertiesForGroup } from '../../../utils/formGroupFieldHelpers';
import FormDatePicker from '../../UI/Form/FormDatePicker/FormDatePicker';
import { customCheckmark, GroupInput } from './familyMemberForm.styles';
import {
  familyAdultCountSelector,
  familyChildrenCountSelector,
} from '../../../store/redux-slices/membership/selectors';
import { Relation } from '../../../api/base-api';

type EmergencyContactFormProps = CustomForm & {
  name?: string;
};

const FamilyMemberForm = ({
  defaultValues,
  className,
  disabled,
  name,
}: EmergencyContactFormProps) => {
  const {
    input: { value: relation },
  }: FieldRenderProps<string> = useField(FamilyMemberFields.Relation);
  const max = moment()
    .subtract(relation === Relation.Child ? 0 : 26, 'year')
    .format('YYYY-MM-DD');
  const min = moment().subtract(75, 'year').format('YYYY-MM-DD');

  const childrenCount = useSelector(familyChildrenCountSelector);
  const adultCount = useSelector(familyAdultCountSelector);

  return (
    <SectionWrapper>
      <FormColumn className={className}>
        <FormRow>
          <Field
            component={FormInput}
            disabled={disabled}
            // defaultValue={
            //   defaultValues?.personalInformation?.[FamilyMemberFields.FirstName]
            // }
            {...getFieldPropertiesForGroup({
              inputId: 'family-member-firstname-input',
              name: FamilyMemberFields.FirstName,
              groupName: name,
            })}
          />
        </FormRow>
        <FormRow>
          <Field
            component={FormInput}
            disabled={disabled}
            // defaultValue={
            //   defaultValues?.personalInformation?.[FamilyMemberFields.LastName]
            // }
            {...getFieldPropertiesForGroup({
              inputId: 'family-member-lastname-input',
              name: FamilyMemberFields.LastName,
              groupName: name,
            })}
          />
        </FormRow>
        <FormRow>
          <Field
            component={GroupInput}
            disabled={disabled}
            items={familyRelationOptions(
              adultCount < 2,
              childrenCount <= 6,
              !!defaultValues,
            )}
            customCheckmark={customCheckmark}
            // defaultValue={defaultValues?.[FamilyMemberFields.Relation]}
            {...getFieldPropertiesForGroup({
              inputId: 'family-member-email-input',
              name: FamilyMemberFields.Relation,
              groupName: name,
            })}
          />
        </FormRow>
        <FormRow>
          <Field
            component={FormDatePicker}
            disabled={disabled}
            max={max}
            min={min}
            // defaultValue={
            //   defaultValues?.[FamilyMemberFields.DateOfBirth] &&
            //   moment(defaultValues?.[FamilyMemberFields.DateOfBirth]).format(
            //     'YYYY-MM-DD',
            //   )
            // }
            {...getFieldPropertiesForGroup({
              inputId: 'family-member-birth-of-date',
              name: FamilyMemberFields.DateOfBirth,
              groupName: name,
            })}
          />
        </FormRow>
      </FormColumn>
    </SectionWrapper>
  );
};

export default FamilyMemberForm;
