import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useCallback } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { modalDetailsSelector } from '../../../store/redux-slices/modals/selectors';
import { useActions } from '../../../hooks/useActions';
import { groupsAction } from '../../../store/saga-slices/groups';
import { RoutePath } from '../../../models/RoutePath';

import { processSelector } from '../../../store/redux-slices/processes/selectors';
import { GROUP_DELETING_PROCESSING } from '../../../store/redux-slices/processes/constants';
import { REMOVE_GROUP_MEMBER_MODAL_NAME } from './RemoveGroupMemberModal';

export const useRemoveGroupMemberModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { name, groupName, memberId } = useSelector(
    modalDetailsSelector(REMOVE_GROUP_MEMBER_MODAL_NAME),
  );
  const { groupId } = useParams();
  const isLoading = useSelector(processSelector(GROUP_DELETING_PROCESSING));

  const removeFromGroup = useActions(groupsAction.sendRemoveFromGroup);

  const onConfirm = useCallback(() => {
    removeFromGroup({
      memberId,
      groupId: groupId!,
      success: () => {
        navigate(RoutePath.HomeAbsolute);
      },
    });
  }, [groupId, memberId, navigate, removeFromGroup]);

  return {
    t,
    name,
    groupName,
    groupId,
    onConfirm,
    isLoading,
  };
};
