import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { settingsActions } from '../../../store/saga-slices/settings';
import { useActions } from '../../../hooks/useActions';
import { processSelector } from '../../../store/redux-slices/processes/selectors';
import { NOTIFICATIONS_LOADING_PROCESSING } from '../../../store/redux-slices/processes/constants';

export const useNotificationSettingsForm = () => {
  const updateNotificationSettings = useActions(
    settingsActions.updateNotificationSettings,
  );

  const isLoading = useSelector(
    processSelector(NOTIFICATIONS_LOADING_PROCESSING),
  );

  const onSubmitHandler = useCallback(
    ({
      currentCountry,
      familyMemberLocation,
      minimalRiskLevel,
      subscribeCountry,
    }: any) => {
      updateNotificationSettings({
        minimalAlertRiskLevel: minimalRiskLevel,
        allSubscribedCountriesEnabled: subscribeCountry,
        currentCountryEnabled: currentCountry,
        familyMembersLocationEnabled: familyMemberLocation,
      });
    },
    [updateNotificationSettings],
  );

  return { onSubmitHandler, isLoading };
};
