import PhoneNumberInput from 'react-phone-number-input';
import styled from '@emotion/styled';

import { typography } from '../../../common/consts/typography';

export default styled(PhoneNumberInput)`
  &.PhoneInput {
    width: 100%;

    input:focus {
      outline: none;
    }
  }

  .PhoneInputCountryIcon {
    width: 16px;
    height: 11px;
  }

  .PhoneInputCountry {
    padding: 8px 16px;
    background-color: ${({ theme }) => theme.color.primary_50};
    border: 0.063rem solid ${({ theme }) => theme.color.neutral_2_400};
    margin: 0;
    gap: 16px;
    border-radius: 12px 0 0 12px;
  }

  .PhoneInputInput {
    color: ${({ theme }) => theme.color.primary_900};
    padding: 0.5rem 1rem;
    border: 0.063rem solid ${({ theme }) => theme.color.neutral_2_400};
    border-radius: 0 0.75rem 0.75rem 0;
    box-sizing: border-box;
    z-index: 1;
    background-color: ${({ theme }) => theme.color.primary_50};
    height: 100%;
    width: 100%;
    opacity: 1;
    -webkit-text-fill-color: ${({ theme }) => theme.color.primary_900};
    ${typography.bodyXS};
  }
`;
