import { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useAlert } from 'react-alert';

import { useTranslation } from 'react-i18next';

import { useActions } from '../../../hooks/useActions';
import { useProcessing } from '../../../hooks/useProcessing';
import { MEMBERSHIP_FAMILY_MEMBER_PROCESSING } from '../../../store/redux-slices/processes/constants';

import { modalDetailsSelector } from '../../../store/redux-slices/modals/selectors';
import { EDIT_FAMILY_MEMBER_MODAL_NAME } from '../../../pages/membership/Modals/EditFamilyMemberModal/EditFamilyMemberModal';
import { membershipActions } from '../../../store/saga-slices/membership';
import { FamilyMemberFields } from './familyMemberForm.models';

export const useFamilyMemberForm = () => {
  const addFamilyMember = useActions(membershipActions.sendAddFamilyMember);
  const editFamilyMember = useActions(membershipActions.sendEditFamilyMember);
  const isLoading = useProcessing(MEMBERSHIP_FAMILY_MEMBER_PROCESSING);
  const { success } = useAlert();
  const defaultValues = useSelector(
    modalDetailsSelector(EDIT_FAMILY_MEMBER_MODAL_NAME),
  );
  const { t } = useTranslation();

  const onAddSubmitHandler = useCallback(
    ({ firstName, lastName, relation, dateOfBirth }: any) => {
      addFamilyMember({
        familyMember: {
          personalInformation: {
            firstName,
            lastName,
            displayName: `${firstName} ${lastName}`,
          },
          relation,
          dateOfBirth,
        },
        success: () => {
          success(
            t('phrase.family-member-added-success', {
              name: `${firstName} ${lastName}`,
            }),
          );
        },
      });
    },
    [addFamilyMember, success, t],
  );

  const onEditSubmitHandler = useCallback(
    ({ firstName, lastName, relation, dateOfBirth }: any) => {
      editFamilyMember({
        familyMember: {
          familyMemberId: defaultValues?.familyMemberId,
          personalInformation: {
            firstName,
            lastName,
            displayName: `${firstName} ${lastName}`,
          },
          relation,
          dateOfBirth,
        },
        success: () => {
          success(
            t('phrase.family-member-edited-success', {
              name: `${firstName} ${lastName}`,
            }),
          );
        },
      });
    },
    [defaultValues?.familyMemberId, editFamilyMember, success, t],
  );

  const initValues = useMemo(
    () => ({
      firstName:
        defaultValues?.personalInformation?.[FamilyMemberFields.FirstName],
      lastName:
        defaultValues?.personalInformation?.[FamilyMemberFields.LastName],
      relation: defaultValues?.relation,
      dateOfBirth: defaultValues?.dateOfBirth,
    }),
    [defaultValues],
  );

  return {
    onAddSubmitHandler,
    onEditSubmitHandler,
    isLoading,
    defaultValues,
    initValues,
  };
};
