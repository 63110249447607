/* eslint-disable no-undefined */
import { applyMiddleware, createStore } from 'redux';

import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistConfig } from 'redux-persist/es/types';

// import userManager from '../utils/userManager';
import rootSaga from './saga-slices';
import rootReducer from './redux-slices';
import countries from './redux-slices/countries';
import riskAlerts from './redux-slices/riskAlerts';
import countryBrief from './redux-slices/countryBrief';

const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage,
  whitelist: [
    'user',
    'groups',
    'friends',
    'membership',
    'members',
    'wizard',
    'countries',
    'riskAlerts',
    'countryBrief',
    'checkIn',
    'pictures',
  ],
};

export const configureStore = (preloadedState?: any) => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware /* loggerMiddleware, thunkMiddleware*/];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  //
  const enhancers = [middlewareEnhancer /* monitorReducersEnhancer*/];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const reducers = (state: any, action: any) => {
    if (action.type === 'LOGOUT') {
      storage.removeItem('persist:root');
      state = undefined;
    }
    return persistedReducer(state, action);
  };

  const store = createStore(reducers, preloadedState, composedEnhancers);

  // loadUser(store, userManager);

  if (process.env.NODE_ENV !== 'production' && (module as any).hot) {
    (module as any).hot.accept('./redux-slices', () =>
      store.replaceReducer(reducers),
    );
  }

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
};
