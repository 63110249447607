import { useCallback, useMemo, useState } from 'react';
import useCopyToClipboard from './useCopyToClipboard';
import { AssignedEntityType } from '../api/base-api';
import { useTranslation } from 'react-i18next';
import { RoutePath } from '../models/RoutePath';
import moment from 'moment';

interface CopyInviteLinkProps {
  code: string;
  validTo: string;
  inviter: string;
  type: AssignedEntityType;
  name?: string;
}

export const useInvite = ({
  name,
  code,
  inviter,
  type,
  validTo,
}: CopyInviteLinkProps) => {
  const { t } = useTranslation();
  const [_, copy] = useCopyToClipboard();

  const [codeIsCopied, setCodeCopied] = useState(false);
  const [linkIsCopied, setLinkCopied] = useState(false);

  const copyCode = useCallback(() => {
    copy(code);
    setCodeCopied(true);
    setLinkCopied(false);
    setTimeout(() => {
      setCodeCopied(false);
    }, 3000);
  }, [copy, code]);

  const prepareParams = useCallback(() => {
    const params = {
      type,
      by: inviter,
      name,
    };

    return Object.keys(params)
      .filter((key) => params[key])
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join('&');
  }, [type, inviter, name]);

  const copyLink = useCallback(() => {
    const params = prepareParams();
    const inviteLink = `${
      window.location.host
    }${RoutePath.InvitationsAbsolute.replace(':accessCode', code)}?${params}`;
    copy(inviteLink);
    setLinkCopied(true);
    setCodeCopied(false);
    setTimeout(() => {
      setLinkCopied(false);
    }, 3000);
  }, [code, copy, inviter]);

  const emailMessage = useMemo(
    () =>
      t('phrase.invite-text-friends', {
        code,
        context: type,
      }),
    [code, t],
  );

  const sendMail = useCallback(() => {
    const link = `mailto:?subject=${encodeURIComponent(
      t(`phrase.email-invite`, { context: type }),
    )}&body=${encodeURIComponent(emailMessage)}`;

    window.location.href = link;
  }, [emailMessage, t]);

  const expirationIn = useMemo(() => {
    const current = moment().startOf('day');
    const validToDate = moment(validTo);

    return validToDate.diff(current, 'days', false);
  }, [code]);

  const isExpired = useMemo(() => {
    return expirationIn < 0;
  }, [expirationIn]);

  return {
    codeIsCopied,
    linkIsCopied,
    copyCode,
    copyLink,
    sendMail,
    expirationIn,
    isExpired,
  };
};
