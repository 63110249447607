import { FC, ReactNode, useEffect, useRef, useState } from 'react';

import LoadingColor from './icons/LoadingColor.svg';
import LoadingWhite from './icons/LoadingWhite.svg';

import {
  Icon,
  SpinnerContainer,
  SpinnerOverlay,
  SpinnerWrapper,
} from './spinner.styles';

type SpinnerMode = 'dark' | 'light';

interface ISpinnerProps {
  className?: string;
  delaySpinner?: number;
  isVisible?: boolean;
  label?: string;
  mode?: SpinnerMode;
  size?: string;
  children?: ReactNode | ReactNode[];
}

const Spinner: FC<ISpinnerProps> = ({
  children,
  delaySpinner,
  isVisible = false,
  label,
  mode = 'dark',
  size = '100%',
}) => {
  const [spinnerIsVisible, setSpinnerIsVisible] = useState(false);
  const timer = useRef<any>();

  useEffect(() => {
    timer.current = setTimeout(() => {
      setSpinnerIsVisible(isVisible);
    }, delaySpinner || 300);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [delaySpinner, isVisible]);

  if (!children && !spinnerIsVisible) {
    return null;
  }

  return (
    <SpinnerWrapper>
      {(isVisible || spinnerIsVisible) && children && <SpinnerOverlay />}
      {spinnerIsVisible && (
        <SpinnerContainer
          hasChildren={!!children}
          style={{ height: `${size}` }}
        >
          <Icon
            alt="spinner__icon"
            src={mode === 'dark' ? LoadingColor : LoadingWhite}
          />
          {label && <div className="spinner__label">{label}</div>}
        </SpinnerContainer>
      )}
      {children}
    </SpinnerWrapper>
  );
};

export default Spinner;
