import styled from '@emotion/styled/macro';

import { css } from '@emotion/react';

import { StandardText } from '../../Typography/Typography';
import { FormColumn } from '../../UI/Form/FormColumn/FormColumn';

export const Description = styled(StandardText)`
  text-align: center;
`;

export const Divider = styled.div(
  ({ theme }) => css`
    background-color: ${theme.color.primary_200};
    height: 16px;
    width: 1px;
  `,
);

export const DividerHorizontal = styled.div(
  ({ theme }) => css`
    background-color: ${theme.color.neutral_2_100};
    height: 1px;
    width: 100%;
  `,
);

export const Column = styled(FormColumn)(
  ({ theme }) => css`
    gap: 0;
  `,
);

export const NotificationFormColumn = styled(Column)(
  ({ theme }) => css`
    gap: 12px;
  `,
);
