import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { AlertType } from 'react-alert';

import Button from '../UI/Button/Button';
import { ButtonProps } from '../UI/Button/button.models';
import { ReactComponent as CloseIcon } from '../../assets/close-white.svg';
import { typography } from '../../common/consts/typography';

type WrapperType = {
  type: AlertType;
};

export const Wrapper = styled.div<WrapperType>`
  background-color: ${({ theme }) => theme.color.neutral_2_50};
  min-width: 20rem;
  padding: 0.75rem 1rem 0.75rem 1rem;
  margin: 104px 142px 0 0;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.color.neutral_2_900};
  font-size: 1rem;
  position: relative;
  box-shadow: 0 8px 20px ${({ theme }) => theme.color.neutral_2_500};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  ${({ type, theme }) => {
    switch (type) {
      case 'success':
        return css`
          background-color: ${theme.color.success_400};
          color: ${theme.color.white};

          path {
            &[fill] {
              fill: ${theme.color.white};
            }

            &[stroke] {
              stroke: ${theme.color.white};
            }
          }
        `;
      case 'info':
        return css`
          background-color: ${theme.color.neutral_2_50};
        `;
      case 'error':
        return css`
          background-color: ${theme.color.error_500};
          color: ${theme.color.white};
        `;
    }
  }};
`;

export const ContentWrapper = styled.div<WrapperType>`
  background-color: ${({ theme }) => theme.color.neutral_2_50};
  min-width: 20rem;
  padding: 0.75rem 1rem 0.75rem 1rem;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.color.neutral_2_900};
  font-size: 1rem;
  position: relative;
  box-shadow: 0 8px 20px ${({ theme }) => theme.color.neutral_2_500};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  ${({ type, theme }) => {
    switch (type) {
      case 'success':
        return css`
          background-color: ${theme.color.success_400};
          color: ${theme.color.white};

          path {
            &[fill] {
              fill: ${theme.color.white};
            }

            &[stroke] {
              stroke: ${theme.color.white};
            }
          }
        `;
      case 'info':
        return css`
          background-color: ${theme.color.neutral_2_50};
        `;
      case 'error':
        return css`
          background-color: ${theme.color.error_500};
          color: ${theme.color.white} path {
            &[fill] {
              fill: ${theme.color.white};
            }

            &[stroke] {
              stroke: ${theme.color.white};
            }
          }
        `;
    }
  }};

  ${typography.bodySm};
`;

export const CloseButton = styled((props: ButtonProps) => (
  <Button {...props} styleType="icon">
    <CloseIcon />
  </Button>
))`
  top: auto;
  bottom: auto;
`;
