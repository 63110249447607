import styled from '@emotion/styled/macro';

import { ButtonProps } from '../../Button/button.models';
import Button from '../../Button/Button';

export const FormSubmitButton = styled((props: ButtonProps) => (
  <Button {...props} type="submit" />
))`
  width: 100%;
`;
