import { createSlice } from '@reduxjs/toolkit';
import { CountryModel } from '../../../api/base-api';

interface CountriesState {
  countries: CountryModel[];
  subscribedCountries: string[];
}

const initState: CountriesState = {
  countries: [],
  subscribedCountries: [],
};

const countriesSlice = createSlice({
  name: 'countries',
  initialState: initState,
  reducers: {
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
    setSubscribedCountries: (state, action) => {
      state.subscribedCountries = action.payload;
    },
    removeSubscribedCountry: (state, action) => {
      state.subscribedCountries = state.subscribedCountries.filter(
        (country) => country !== action.payload,
      );
    },
    addSubscribedCountry: (state, action) => {
      state.subscribedCountries.push(action.payload);
    },
  },
});

export const countryActions = countriesSlice.actions;
export default countriesSlice.reducer;
