import { useEffect } from 'react';
import { useForm } from 'react-final-form';

import { CustomComponent } from '../../../models/types/CustomComponent';
import { useFormErrorsValues } from '../FormErrorProvider.hooks';

const FormErrorActivator = ({ children }: CustomComponent) => {
  const { errors } = useFormErrorsValues();
  const form = useForm();

  useEffect(() => {
    if (!!errors?.length) {
      setTimeout(() => form.submit(), 0);
    }
  }, [errors?.length, form]);

  return <>{children}</>;
};

export default FormErrorActivator;
