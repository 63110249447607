import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { call, put, select } from 'typed-redux-saga/macro';

import { PayloadAction } from '@reduxjs/toolkit';

import moment from 'moment';

import { updateProcessState } from '../../redux-slices/processes';
import { REQUESTED_FRIEND_PROCESSING } from '../../redux-slices/processes/constants';
import {
  FriendsApi,
  GroupMemberModel,
  GroupsApi,
  MemberModel,
} from '../../../api/base-api';
import { RequestCheckIn } from './model';
import { membersGroupSelector } from '../../redux-slices/groups/selectors';
import { userSelector } from '../../redux-slices/user/selectors';
import { checkInActions, ICheckInStatus } from '../../redux-slices/checkIn';

const checkInSlice = createSliceSaga({
  name: 'checkin-saga',
  caseSagas: {
    *updateCheckIns(action: PayloadAction<(MemberModel | GroupMemberModel)[]>) {
      try {
        const checkIns = action.payload.map(
          (member) =>
            ({
              memberId: member.id,
              status: {
                checkInDate: member.checkInDate,
                checkInStatus: member.checkInStatus,
              },
            } as ICheckInStatus),
        );

        yield put(checkInActions.setCheckIns(checkIns));
      } catch (e) {}
    },
    *updateCheckIn(action: PayloadAction<ICheckInStatus>) {
      try {
        yield put(checkInActions.setCheckIn(action.payload));
      } catch (e) {}
    },
    *sendRequestCheckIn(action: PayloadAction<RequestCheckIn>) {
      try {
        yield put(updateProcessState(REQUESTED_FRIEND_PROCESSING));

        if (action.payload.type === 'friend') {
          yield* call(
            new FriendsApi().friendsFriendIdActionsRequestCheckInPost,
            action.payload.memberId!,
          );
        }
        if (action.payload.type === 'memberGroup') {
          yield* call(
            new GroupsApi().groupsGroupIdActionsRequestCheckInPost,
            action.payload.memberId!,
          );
        }

        yield put(
          checkInActions.setCheckIn({
            memberId: action.payload.memberId!,
            status: {
              checkInDate: moment().toISOString(),
              checkInStatus: 'Pending',
            },
          }),
        );

        action.payload?.success?.();
      } catch (e) {
        action.payload?.error?.();
      } finally {
        yield put(updateProcessState(REQUESTED_FRIEND_PROCESSING));
      }
    },
    *sendGroupRequestCheckIn(action: PayloadAction<RequestCheckIn>) {
      try {
        yield put(updateProcessState(REQUESTED_FRIEND_PROCESSING));

        const members = yield* select(
          membersGroupSelector(action.payload.groupId!),
        );
        const user = yield* select(userSelector);
        yield* call(
          new GroupsApi().groupsGroupIdActionsRequestCheckInPost,
          action.payload.groupId!,
        );

        const checkIns: Array<ICheckInStatus> = members
          ?.map((member) => member.id!)!
          .filter((id) => id !== user.id)!
          .map((memberId) => ({
            memberId,
            status: {
              checkInDate: moment().toISOString(),
              checkInStatus: 'Pending',
            },
          }))!;

        yield put(checkInActions.setCheckIns(checkIns));

        action.payload?.success?.();
      } catch (e) {
        action.payload?.error?.();
      } finally {
        yield put(updateProcessState(REQUESTED_FRIEND_PROCESSING));
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default checkInSlice.saga;
export const checkInSagaActions = checkInSlice.actions;
export const { actions } = checkInSlice;
