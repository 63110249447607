import { createSelector } from 'reselect';

import { RootState } from '../index';
import { userSelector } from '../user/selectors';
import { ExtendGroupModel } from './index';
import { compareStrings } from '../../../utils/compareStrings';

const stateGroupsSelector = (state: RootState) => state.groups;

export const groupsSelector = createSelector(
  stateGroupsSelector,
  (groupsState) =>
    [...groupsState.groups].sort((a, b) => compareStrings(a.name!, b.name!)),
);
export const groupSelector = createSelector(
  [groupsSelector, (state, id: string) => id],
  (groups, id): ExtendGroupModel => groups.find((group) => group.id === id)!,
);

export const membersGroupSelector = (id: string) =>
  createSelector(
    groupsSelector,
    (groups) => groups.find((group) => group.id === id)?.members,
  );

export const membersGroupWithoutUserSelector = (id: string) =>
  createSelector(groupsSelector, userSelector, (groups, user) =>
    groups
      .find((group) => group.id === id)
      ?.members?.filter((member) => member.id !== user.id),
  );

export const isOwnerGroupSelector = (groupId: string) =>
  createSelector(
    membersGroupSelector(groupId),
    userSelector,
    (members, user) =>
      members?.find((member) => member.isOwner)?.id === user.id,
  );

export const accessCodeSelector = (id: string) =>
  createSelector(
    groupsSelector,
    (groups) => groups.find((group) => group.id === id)?.accessCode,
  );

export const groupsWhereIsOwnerOptionsSelector = (excludeMemberId: string) =>
  createSelector(groupsSelector, (groups) => {
    return groups
      .filter((group) => group.isOwner)
      .filter(
        (group) =>
          group.members.findIndex((member) => member.id === excludeMemberId) ===
          -1,
      )
      .map((group) => ({
        label: group.name,
        value: group.id,
      }));
  });
