import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { clearState } from '../../globalActions';
import {
  Details,
  IWizardAvailableStep,
  IWizardDetailsStep,
  IWizardManagerSate,
  IWizardValidStep,
} from './models';
import { addMembershipWizard } from './wizards/AddMembership';
import { SIGN_UP_WIZARD_NAME, signUpWizard } from './wizards/SignUp';
import { upgradeMembershipWizard } from './wizards/UpgradeMembership';

const initState: IWizardManagerSate = {
  wizards: {
    ADD_MEMBERSHIP_WIZARD_NAME: addMembershipWizard,
    UPGRADE_MEMBERSHIP_WIZARD_NAME: upgradeMembershipWizard,
    SIGN_UP_WIZARD_NAME: signUpWizard,
  },
  activeWizard: SIGN_UP_WIZARD_NAME,
};

const wizardSlice = createSlice({
  name: 'wizard',
  initialState: initState,
  reducers: {
    setActiveWizard: (state, action: PayloadAction<string>) => {
      state.activeWizard = action.payload;
      state.wizards[action.payload]!.activeStep =
        state.wizards[action.payload]!.steps[0].canonicalName;
    },
    updateActiveStep: (state, action: PayloadAction<string>) => {
      state.wizards[state.activeWizard]!.activeStep = action.payload;
    },
    updateValidSteps: (state, action: PayloadAction<IWizardValidStep[]>) => {
      action.payload.forEach((step) => {
        const index = state.wizards[state.activeWizard]!.steps.findIndex(
          (stateStep) => {
            return stateStep.canonicalName === step.canonicalName;
          },
        );
        state.wizards[state.activeWizard]!.steps[index] = {
          ...state.wizards[state.activeWizard]!.steps[index],
          isValid: step.isValid,
        };
      });
    },
    updateDetailSteps: (state, action: PayloadAction<IWizardDetailsStep[]>) => {
      action.payload.forEach((step) => {
        const index = state.wizards[state.activeWizard]!.steps.findIndex(
          (stateStep) => stateStep.canonicalName === step.canonicalName,
        );
        state.wizards[state.activeWizard]!.steps[index] = {
          ...state.wizards[state.activeWizard]!.steps[index],
          details: {
            ...(state.wizards[state.activeWizard]!.steps[index].details || {}),
            ...step.details,
          },
        };
      });
    },
    updateDetailWizard: (state, action: PayloadAction<Details>) => {
      state.wizards[state.activeWizard]!.details = {
        ...state.wizards[state.activeWizard]!.details,
        ...action.payload,
      };
    },
    updateValidStep: (state, action: PayloadAction<IWizardValidStep>) => {
      const index = state.wizards[state.activeWizard]!.steps.findIndex(
        (step) => step.canonicalName === action.payload.canonicalName,
      );
      state.wizards[state.activeWizard]!.steps[index] = {
        ...state.wizards[state.activeWizard]!.steps[index],
        isValid: action.payload.isValid,
      };
    },
    updateAvailableSteps: (
      state,
      action: PayloadAction<IWizardAvailableStep[]>,
    ) => {
      action.payload.forEach((step) => {
        const index = state.wizards[state.activeWizard]!.steps.findIndex(
          (stateStep) => stateStep.canonicalName === step.canonicalName,
        );
        state.wizards[state.activeWizard]!.steps[index].isAvailable =
          step.isAvailable;
      });
    },
    resetWizard: (state) => {
      state.wizards[state.activeWizard]!.activeStep =
        initState.wizards[state.activeWizard]!.activeStep;
      state.wizards[state.activeWizard]!.steps.forEach((step) => {
        step.isValid = false;
        step.details = {};
      });
    },
    disposeWizard: (state) => {
      state.activeWizard = SIGN_UP_WIZARD_NAME;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearState, () => initState);
  },
});

export const {
  setActiveWizard,
  resetWizard,
  disposeWizard,
  updateActiveStep,
  updateAvailableSteps,
  updateValidStep,
  updateValidSteps,
  updateDetailSteps,
  updateDetailWizard,
} = wizardSlice.actions;
export default wizardSlice.reducer;
export const wizardReducerName = wizardSlice.name;
