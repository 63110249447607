import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2.813rem;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RadioContent = styled.div`
  display: flex;
  flex-direction: column;
`;
