import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { SectionTitle as Title } from '../../Typography/Typography';
import { Column } from '../Column';
import { FieldWrapper, StyledFieldLabel } from '../Field/Field';
import { mq, mqMax } from '../../../common/utils/mediaQueries';

export const FormPage = styled.form`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;

  ${mq.medium(css`
    padding: 0;
  `)}

  ${mqMax.smallIp(css`
    padding: 16px;
  `)}
`;

export const SectionWrapper = styled.div`
  width: 100%;
`;

export const InputBasicWrapper = styled.div`
  width: 100%;
  border-radius: 0.75rem;
  padding: 0.5rem 1rem;

  background-color: ${({ theme }) => theme.color.primary_50};

  ${({ theme }) => css`
    ${FormFieldWrapper}:first-of-type {
      input {
        border-radius: 12px 0 0 12px;
      }
    }

    ${FormFieldWrapper}:last-child {
      input {
        border-radius: 0 12px 12px 0;
      }
    }
  `}
`;

export const InputTopWrapper = styled(InputBasicWrapper)`
  border-bottom: 0.063rem solid ${({ theme }) => theme.color.neutral_2_400};
  border-radius: 0.75rem 0.75rem 0 0;
`;

export const InputBottomWrapper = styled(InputBasicWrapper)`
  display: grid;
  padding: 0;
  grid-template-columns: 1fr 1fr;
  border-radius: 0 0 12px 12px;
`;

export const InputPureWrapper = styled.div`
  width: 100%;
  padding: 0.5rem 1rem;

  &:first-of-type {
    border-right: 0.063rem solid ${({ theme }) => theme.color.neutral_2_400};
  }
`;

export const InputWrapper = styled(InputBasicWrapper)`
  border: 0.063rem solid ${({ theme }) => theme.color.neutral_2_400};
  border-radius: 0.75rem;
`;

export const GroupInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  ${mqMax.smallIp(css`
    grid-template-columns: 1fr;
    gap: 16px;
  `)}

  ${({ theme }) => css`
    ${FormFieldWrapper}:first-of-type {
      input {
        border-radius: 12px 0 0 12px;

        ${mqMax.smallIp(css`
          border-radius: 12px;
        `)}
      }
    }

    ${FormFieldWrapper}:last-child {
      input {
        border-radius: 0 12px 12px 0;
      }
    }
  `}
`;

export const GroupStripeInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  border: 0.063rem solid ${({ theme }) => theme.color.neutral_2_400};
  border-radius: 0.75rem;
  padding: 0.5rem 1rem;

  ${({ theme }) => css`
    .StripeElement:first-of-type {
      border-right: 0.063rem solid ${theme.color.neutral_2_400};
    }

    .StripeElement:last-child {
      input {
        border-radius: 0 12px 12px 0;
      }
    }
  `}
`;

export const ColumnSectionWrapper = styled(Column)`
  max-width: 500px;
  gap: 16px;
  height: 100%;
  align-items: center;
`;

export const ColumnGroupWrapper = styled(Column)`
  border: 0.063rem solid ${({ theme }) => theme.color.neutral_2_400};
  border-radius: 12px;
`;

export const SectionTitle = styled(Title)`
  margin-bottom: 1.5rem;
`;

type FormFieldWrapperType = {
  error?: boolean;
};

export const FormFieldWrapper = styled.div<FormFieldWrapperType>`
  position: relative;
  width: 100%;
  ${({ error, theme }) =>
    error &&
    css`
      ${FieldWrapper} > :not(:placeholder-shown) ~ ${StyledFieldLabel},
            ${FieldWrapper} > ${StyledFieldLabel},
            ${StyledFieldLabel} {
        color: ${theme.color.error};
      }
    `}
`;
