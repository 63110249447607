import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  FamilyMemberModel,
  GetFamilyMembersResponse,
  GetMembershipAddressResult,
  GetMembershipPaymentMethodResult,
  GetMembershipSummaryResult,
  PlanType,
  RequestAddress,
} from '../../../api/base-api';
import { StringDict } from '../../../utils/stringDict';
import moment from 'moment';

interface IPlan extends GetMembershipSummaryResult {}

export interface IAddressState {
  residentialAddress?: RequestAddress;
  billingAddress?: RequestAddress;
}
export type IPaymentState = GetMembershipPaymentMethodResult;

export interface MembershipState {
  plan: IPlan;
  address: IAddressState;
  family: StringDict<FamilyMemberModel>;
  payment: IPaymentState;
  lastUpdate: string;
}

const initState: MembershipState = {
  plan: {
    planType: PlanType.Free,
  },
  address: {
    residentialAddress: undefined,
    billingAddress: undefined,
  },
  payment: {},
  family: {},
  lastUpdate: '',
};

const membershipSlice = createSlice({
  name: 'membership',
  initialState: initState,
  reducers: {
    setPlan: (state, action: PayloadAction<IPlan>) => {
      state.plan = action.payload;
      state.lastUpdate = moment().toISOString();
    },
    setAddress: (state, action: PayloadAction<IAddressState>) => {
      state.address = action.payload;
    },
    setPayment: (state, action: PayloadAction<IPaymentState>) => {
      state.payment = action.payload;
    },
    setNextUpdate: (state) => {
      state.lastUpdate = moment().toISOString();
    },
    setFamilyMembers: (
      state,
      action: PayloadAction<GetFamilyMembersResponse>,
    ) => {
      state.family = {};
      action.payload.members.forEach((member) => {
        state.family[member.familyMemberId] = member;
      });

      state.family[action.payload.owner.memberId] = {
        memberId: action.payload.owner.memberId,
        personalInformation: {
          displayName: action.payload.owner.displayName,
        },
      };
    },
    addFamilyMember: (state, action: PayloadAction<FamilyMemberModel>) => {
      state.family[action.payload.familyMemberId!] = action.payload;
    },
    editFamilyMember: (state, action: PayloadAction<FamilyMemberModel>) => {
      state.family[action.payload.familyMemberId!] = action.payload;
    },
    editAddress: (state, action: PayloadAction<GetMembershipAddressResult>) => {
      state.address = action.payload;
    },
    removeFamilyMember: (state, action: PayloadAction<string>) => {
      delete state.family[action.payload];
    },
  },
});

export const membershipActions = membershipSlice.actions;
export default membershipSlice.reducer;
export const modalReducerName = membershipSlice.name;
