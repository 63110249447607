import { Modal } from '../../Modal/Modal';
import FormErrorProvider from '../../FormErrorProvider/FormErrorProvider';
import RenameGroupFormPage from '../../Forms/RenameGroupForm/RenameGroupForm';

export const RENAME_GROUP_MODAL_NAME = 'RENAME_GROUP_MODAL';

export const RenameGroupModal = () => (
  <Modal name={RENAME_GROUP_MODAL_NAME}>
    <FormErrorProvider>
      <RenameGroupFormPage />
    </FormErrorProvider>
  </Modal>
);
