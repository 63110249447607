import { Field } from 'react-final-form';

import { t } from 'i18next';

import { CustomForm } from '../../../models/types/CustomForm';
import { SectionWrapper } from '../../UI/Form/Form';
import { FormColumn } from '../../UI/Form/FormColumn/FormColumn';
import { FormRow } from '../../UI/Form/FormRow/FormRow';
import { FormInput } from '../../UI/Form/FormInput/FormInput';
import { RenameGroupFields } from './renameGroupForm.models';
import { getFieldPropertiesForGroup } from '../../../utils/formGroupFieldHelpers';

type CreateGroupFormProps = CustomForm & {
  name?: string;
};

const CreateGroupForm = ({
  defaultValue,
  className,
  disabled,
  name,
}: CreateGroupFormProps) => (
  <SectionWrapper>
    <FormColumn className={className}>
      <FormRow>
        <Field
          component={FormInput}
          disabled={disabled}
          defaultValue={defaultValue}
          label={t('fields.newName.label')}
          {...getFieldPropertiesForGroup({
            inputId: 'create-group-name-input',
            name: RenameGroupFields.Name,
            groupName: name,
          })}
        />
      </FormRow>
    </FormColumn>
  </SectionWrapper>
);

export default CreateGroupForm;
