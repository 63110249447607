import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';

type Props = {
  isMenuOpen: boolean;
};

export const Indicator = styled('div', {
  shouldForwardProp: isPropValid,
})`
  width: 24px;
  height: 24px;
  transition: all 0.2s ease;
  ${({ isMenuOpen }: Props) =>
    isMenuOpen
      ? `background: url(${
          require('../../../../assets/chevron-down.svg').default
        }) 50% no-repeat;`
      : `background: url(${
          require('../../../../assets/chevron-down.svg').default
        }) 50% no-repeat;`}
`;
