import { createSelector } from 'reselect';

import { RootState } from '../index';

export const userSelector = (state: RootState) => state.user!;

export const userCoordsSelector = createSelector(
  userSelector,
  (user) => user.coords,
);

export const userImageSelector = createSelector(
  userSelector,
  (user) => user.photo,
);
