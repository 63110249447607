import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { call, put } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { updateProcessState } from '../../redux-slices/processes';
import {
  FRIENDS_LOADING_PROCESSING,
  FRIEND_REMOVE_PROCESSING,
} from '../../redux-slices/processes/constants';
import { FriendsApi } from '../../../api/base-api';
import { removeFriend, setFriends } from '../../redux-slices/friends';
import { membersActions } from '../members';
import { IRemoveFriendModel } from './model';

export function* loadFriends() {
  const friendsApi = new FriendsApi();

  const { data } = yield* call(friendsApi.friendsGet, 0, 100);

  yield put(
    membersActions.loadMembersLocalization(data.map((friend) => friend.id!)),
  );
  yield put(membersActions.loadMembersPhoto(data.map((friend) => friend.id!)));
  yield put(setFriends(data));
}

const friendsSlice = createSliceSaga({
  name: 'friends-saga',
  caseSagas: {
    *loadFriendsData() {
      try {
        yield put(updateProcessState(FRIENDS_LOADING_PROCESSING));

        yield* loadFriends();
      } catch (e) {
      } finally {
        yield put(updateProcessState(FRIENDS_LOADING_PROCESSING));
      }
    },
    *removeFriend(action: PayloadAction<IRemoveFriendModel>) {
      try {
        yield put(updateProcessState(FRIEND_REMOVE_PROCESSING));
        const friendsApi = new FriendsApi();
        const { friendId } = action.payload;

        yield* call(friendsApi.friendsFriendIdDelete, friendId);
        yield put(removeFriend(friendId));

        action.payload.success?.();
      } catch (e) {
      } finally {
        yield put(updateProcessState(FRIEND_REMOVE_PROCESSING));
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default friendsSlice.saga;
export const friendsActions = friendsSlice.actions;
export const { actions } = friendsSlice;
