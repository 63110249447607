import { ChangeEvent, useCallback, useEffect } from 'react';

import { isPropertyEmpty } from '../../../../utils/propertyHelpers';

type UseFormSwitchProps = {
  onChange: (event: any) => void;
  checked: boolean;
};

export const useFormSwitch = ({ onChange, checked }: UseFormSwitchProps) => {
  useEffect(() => {
    if (isPropertyEmpty(checked)) {
      onChange(false);
    }
  }, [checked, onChange]);

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event);
    },
    [onChange],
  );

  return { onChangeHandler };
};
