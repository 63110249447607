import { useMemo } from 'react';

import Radio from '../Radio/Radio';
import { useRadioGroupContent } from './radioGroupContent.hooks';
import { RadioGroupContentProps } from './radioGroupContent.models';
import {
  GroupWrapper,
  RadioContainer,
  RadioContent,
  Wrapper,
} from './radioGroupContent.styles';
import FieldLabel from '../Field/FieldLabel';

const RadioGroupContent = ({
  items,
  checked,
  disabled,
  onChange,
  label,
  labelAnimation,
  isLabelHidden,
  customCheckmark,
}: RadioGroupContentProps) => {
  const { isChecked, shouldContentVisible } = useRadioGroupContent({
    checked: checked!,
  });

  const itemsComponents = useMemo(
    () =>
      items.map(
        ({
          label,
          contentRenderer,
          disabled: itemDisabled,
          contentHiddenIfNoActive,
        }) => (
          <RadioContainer key={label}>
            <Radio
              disabled={disabled || itemDisabled}
              label={label}
              onChange={() => {
                onChange(label);
              }}
              customCheckmark={customCheckmark}
              checked={isChecked(label)}
            />
            {shouldContentVisible(label, contentHiddenIfNoActive!) && (
              <RadioContent>
                {contentRenderer &&
                  contentRenderer({
                    checked: isChecked(label),
                    disabled,
                  })}
              </RadioContent>
            )}
          </RadioContainer>
        ),
      ),
    [
      customCheckmark,
      disabled,
      isChecked,
      items,
      onChange,
      shouldContentVisible,
    ],
  );

  return (
    <Wrapper>
      {!isLabelHidden && (
        <FieldLabel disabled={disabled} autoLabelAnimation={labelAnimation}>
          {label}
        </FieldLabel>
      )}
      <GroupWrapper>{itemsComponents}</GroupWrapper>
    </Wrapper>
  );
};

export default RadioGroupContent;
