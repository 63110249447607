import { CustomComponent } from '../../../models/types/CustomComponent';
import { LabelContent, StyledFieldLabel } from './Field';

type FieldLabelProps = CustomComponent & {
  disabled?: boolean;
  autoLabelAnimation?: boolean;
  labelActive?: boolean;
  inputLabelOffset?: number;
};

const FieldLabel = ({ children, ...props }: FieldLabelProps) => (
  <StyledFieldLabel {...props}>
    <LabelContent>{children}</LabelContent>
  </StyledFieldLabel>
);

export default FieldLabel;
