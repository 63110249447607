import { combineReducers } from 'redux';

import friends from './friends';
import processes from './processes';
import user from './user';
import groups from './groups';
import map from './map';
import modals from './modals';
import emergencyContacts from './emergencyContacts';
import notifications from './notifications';
import settings from './settings';
import members from './members';
import membership from './membership';
import checkIn from './checkIn';
import locations from './locations';
import wizard from './wizard';
import pictures from './pictures';
import riskAlerts from './riskAlerts';
import countries from './countries';
import countryBrief from './countryBrief';
import accessCodes from './accessCodes';

const rootReducer = combineReducers({
  processes,
  user,
  friends,
  groups,
  map,
  modals,
  emergencyContacts,
  notifications,
  settings,
  membership,
  checkIn,
  countries,
  riskAlerts,
  accessCodes,
  countryBrief,
  locations,
  wizard,
  pictures,
  members,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
