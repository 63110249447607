import { useCallback, useContext, useRef, useState } from 'react';

import { getIn, setIn } from 'final-form';

import { useAlert } from 'react-alert';

import {
  FormErrorActionableContext,
  FormErrorInfoContext,
} from './FormErrorProvider';
import { preparePathFromResponse } from '../../utils/pathHelpers';
import { FieldErrorMessage } from '../../models/interfaces/FieldErrorMessage';

type UseFormErrorType = {
  initErrors?: IApiErrors[];
  initErrorGroups?: string[];
};

interface IApiErrors {
  PropertyName: string;
  Message: string;
}

export function useFormErrors<T>({
  initErrors,
  initErrorGroups,
}: UseFormErrorType = {}) {
  const prevValues = useRef({});

  const prepareFieldErrorMessages = useCallback(
    (additionalErrors?: IApiErrors[]): FieldErrorMessage[] => {
      if (!additionalErrors || additionalErrors.length === 0) {
        return [];
      }

      return additionalErrors.map((err) => ({
        field: preparePathFromResponse(err.PropertyName),
        message: err.Message,
      }));
    },
    [],
  );

  const [errorGroups] = useState<string[]>(initErrorGroups || []);

  const [errors, setErrors] = useState<FieldErrorMessage[]>(
    prepareFieldErrorMessages(initErrors),
  );

  const validateErrors = useCallback(
    (values: T) => {
      const newErrors: FieldErrorMessage[] = [];

      if (errors === null || prevValues.current === null) {
        return null;
      }

      errors.forEach((error: FieldErrorMessage) => {
        if (
          getIn(prevValues.current, error.field) ===
          getIn(values as Record<string, unknown>, error.field)
        ) {
          newErrors.push(error);
        }
      });
      return newErrors;
    },
    [errors],
  );

  const updateValue = (value: any, propertyPath: string) => {
    prevValues.current = setIn(prevValues.current, propertyPath, value) || {};
  };

  const addError = useCallback(
    (apiError: any, value: T) => {
      const error = {
        ...apiError,
        PropertyName: preparePathFromResponse(apiError.PropertyName),
      };

      updateValue(value, error.PropertyName);

      setErrors((prevState) => {
        const newState = prevState.filter(
          (x) => error.PropertyName !== x.field,
        );
        return [...newState, ...prepareFieldErrorMessages([error])];
      });
    },
    [prepareFieldErrorMessages],
  );

  const mapErrorsToGroup = useCallback(
    (apiErrors: any) => {
      const mappedErrors = Object.keys(apiErrors.response.data.errors).map(
        (error) => ({
          PropertyName: error,
          Message: apiErrors.response.data.errors[error][0],
        }),
      );
      const resultErrors: any = [];
      if (!errorGroups || errorGroups.length === 0) {
        return mappedErrors;
      }

      mappedErrors.forEach((error) => {
        const groupIndex = errorGroups.findIndex((group) =>
          error.PropertyName.toLocaleLowerCase().includes(
            group.toLocaleLowerCase(),
          ),
        );
        if (groupIndex === -1) {
          resultErrors.push(error);
          return;
        }
        const currentErrorIndex = resultErrors.findIndex(
          (currentError) =>
            currentError.PropertyName === errorGroups[groupIndex],
        );

        if (currentErrorIndex !== -1) {
          return;
        }

        resultErrors.push({ ...error, PropertyName: errorGroups[groupIndex] });
      });
      return resultErrors;
    },
    [errorGroups],
  );

  const addErrors = useCallback(
    (apiErrors: any, values: any) => {
      mapErrorsToGroup(apiErrors).forEach((error: any) => {
        const value = getIn(
          values,
          preparePathFromResponse(error.PropertyName),
        );
        addError(error, value);
      });
    },
    [addError, mapErrorsToGroup],
  );

  const removeErrors = useCallback((propertiesNames: string[]) => {
    setErrors((prevState) => {
      const newState = prevState.filter((x) =>
        propertiesNames.some((y) => y !== x.field),
      );
      return [...newState];
    });
  }, []);

  const clearErrors = useCallback(() => setErrors([]), [setErrors]);

  return {
    validateErrors,
    clearErrors,
    errors,
    addError,
    removeErrors,
    addErrors,
  };
}

export function useFormErrorsActions() {
  const { addErrors, addError, removeErrors, validateErrors, clearErrors } =
    useContext(FormErrorActionableContext);

  return { addErrors, addError, removeErrors, validateErrors, clearErrors };
}

export function useFormErrorsValues() {
  const { errors } = useContext(FormErrorInfoContext);

  return { errors };
}

export function useApiResponseError() {
  const { error } = useAlert();

  const handleResponseError = (err: any) => {
    if (!err || !err.response.data || err.response.data.length === 0) {
      return false;
    }

    const errorsPath = err.response.data.errors;
    const errors = Object.keys(errorsPath);

    const errorsToMap = errors.map((errorItem: any) => ({
      message: errorsPath[errorItem][0],
    }));

    error(
      <>
        {errorsToMap.map((errorItem) => (
          <div key={errorItem.message}>{errorItem.message}</div>
        ))}
      </>,
    );

    return true;
  };

  return {
    handleResponseError,
  };
}
