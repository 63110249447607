import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalState {
  details?: {
    [x: string]: any;
  };
  isOpen: boolean;
}

export interface Modals {
  [x: string]: ModalState;
}

const initState: Modals = {};

const modalSlice = createSlice({
  name: 'modals',
  initialState: initState,
  reducers: {
    updateModalState: (
      state,
      action: PayloadAction<string | [string, any]>,
    ) => {
      if (typeof action.payload === 'string') {
        if (!Object.hasOwnProperty.call(state, action.payload)) {
          state[action.payload] = {
            isOpen: true,
          };

          Object.keys(state).forEach((key) => {
            if (key !== action.payload) {
              state[key].isOpen = false;
            }
          });
          return;
        }

        Object.keys(state).forEach((key) => {
          if (key === action.payload) {
            state[key].isOpen = !state[key].isOpen;
          } else {
            state[key].isOpen = false;
          }
        });
      } else {
        const [name, details] = action.payload;
        if (!Object.hasOwnProperty.call(state, name)) {
          state[name] = {
            isOpen: true,
            details,
          };

          Object.keys(state).forEach((key) => {
            if (key !== name) {
              state[key].isOpen = false;
            }
          });
          return;
        }

        Object.keys(state).forEach((key) => {
          if (key === name) {
            state[key] = {
              isOpen: !state[key].isOpen,
              details,
            };
          } else {
            state[key].isOpen = false;
          }
        });
      }
    },
  },
});

export const { updateModalState } = modalSlice.actions;
export default modalSlice.reducer;
export const modalReducerName = modalSlice.name;
