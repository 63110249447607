import { AnySchema } from 'ajv';

import { CreateGroupFields } from './createGroupForm.models';
import { CreateGroupCommand } from '../../../api/base-api';
import { validateFormData } from '../../../utils/validations/validateFormData';
import { FieldErrorMessage } from '../../../models/interfaces/FieldErrorMessage';

export const createGroupFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [CreateGroupFields.Name]: {
      type: 'string',
    },
  },

  required: [CreateGroupFields.Name],
};

export const validateCreateGroupForm = (
  data: CreateGroupCommand,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, createGroupFormSchema);
