import { createSelector } from 'reselect';

import { RootState } from '../index';
import { Processes } from '.';

export const processingSelector = (state: RootState) => state.processes;

export const processSelector = (name: string | string[]) =>
  createSelector(processingSelector, (processes: Processes): boolean => {
    if (Array.isArray(name)) {
      return name.some((n) => processes[n]);
    }
    return !!processes[name];
  });
