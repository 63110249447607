import { AnySchema } from 'ajv';

import { RenameGroupFields } from './renameGroupForm.models';
import { FieldErrorMessage } from '../../../models/interfaces/FieldErrorMessage';
import { CreateGroupCommand } from '../../../api/base-api';
import { validateFormData } from '../../../utils/validations/validateFormData';

export const createGroupFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [RenameGroupFields.Name]: {
      type: 'string',
    },
  },

  required: [RenameGroupFields.Name],
};

export const validateRenameGroupForm = (
  data: CreateGroupCommand,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, createGroupFormSchema);
