import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { call, put } from 'typed-redux-saga/macro';

import { PayloadAction } from '@reduxjs/toolkit';

import { updateProcessState } from '../../redux-slices/processes';
import {
  LOAD_ENDPOINTS_PROCESSING,
  SETTINGS_NOTIFICATION_PROCESSING,
} from '../../redux-slices/processes/constants';
import {
  DiscoveryApi,
  SettingsApi,
  SettingsModel,
} from '../../../api/base-api';

import {
  updateEndpoints,
  updateNotificationSettings,
} from '../../redux-slices/settings';

import { updateModalState } from '../../redux-slices/modals';
import { NOTIFICATION_SETTINGS_MODAL_NAME } from '../../../components/Modals/NotificationSettingsModal/NotificationSettingsModal';

const settingsSlice = createSliceSaga({
  name: 'settings-saga',
  caseSagas: {
    *loadNotificationSettings() {
      try {
        yield put(updateProcessState(SETTINGS_NOTIFICATION_PROCESSING));
        const { data } = yield* call(new SettingsApi().settingsGet);

        yield put(updateNotificationSettings(data));
      } catch (e) {
      } finally {
        yield put(updateProcessState(SETTINGS_NOTIFICATION_PROCESSING));
      }
    },
    *updateNotificationSettings(action: PayloadAction<SettingsModel>) {
      try {
        yield put(updateProcessState(SETTINGS_NOTIFICATION_PROCESSING));
        const {
          minimalAlertRiskLevel,
          currentCountryEnabled,
          familyMembersLocationEnabled,
          allSubscribedCountriesEnabled,
        } = action.payload;

        const settingsApi = new SettingsApi();
        yield* call(settingsApi.settingsPut, {
          minimalAlertRiskLevel,
          currentCountryEnabled,
          allSubscribedCountriesEnabled,
          familyMembersLocationEnabled,
        });

        yield put(updateModalState(NOTIFICATION_SETTINGS_MODAL_NAME));
        yield put(updateNotificationSettings(action.payload));
      } catch (e) {
      } finally {
        yield put(updateProcessState(SETTINGS_NOTIFICATION_PROCESSING));
      }
    },
    *loadEndpoints() {
      try {
        yield put(updateProcessState(LOAD_ENDPOINTS_PROCESSING));
        const response = yield* call(new DiscoveryApi().discoveryEndpointsGet);

        yield put(updateEndpoints((response as any).data));
      } catch (e) {
      } finally {
        yield put(updateProcessState(LOAD_ENDPOINTS_PROCESSING));
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default settingsSlice.saga;
export const { loadNotificationSettings, loadEndpoints } =
  settingsSlice.actions;
export const settingsActions = settingsSlice.actions;
