import { Field } from 'react-final-form';

import { CustomForm } from '../../../models/types/CustomForm';
import { SectionWrapper } from '../../UI/Form/Form';
import { FormColumn } from '../../UI/Form/FormColumn/FormColumn';
import { FormRow } from '../../UI/Form/FormRow/FormRow';
import {
  getMembersOptions,
  RenameGroupFields,
} from './passOwnershipForm.models';
import { getFieldPropertiesForGroup } from '../../../utils/formGroupFieldHelpers';
import { FormSelect } from '../../UI/Form/FormSelect/FormSelect';
import { GroupMemberModel } from '../../../api/base-api';

type CreateGroupFormProps = CustomForm & {
  name?: string;
  members: GroupMemberModel[];
};

const PassOwnershipForm = ({
  members,
  className,
  disabled,
  name,
}: CreateGroupFormProps) => (
  <SectionWrapper>
    <FormColumn className={className}>
      <FormRow>
        <Field
          component={FormSelect}
          disabled={disabled}
          labelAnimation={false}
          options={getMembersOptions(members)}
          {...getFieldPropertiesForGroup({
            inputId: 'create-group-name-input',
            name: RenameGroupFields.newOwnerId,
            groupName: name,
          })}
        />
      </FormRow>
    </FormColumn>
  </SectionWrapper>
);

export default PassOwnershipForm;
