import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IPicture {
  imageHash?: string;
  memberId: string;
}

const initState: {
  [x: string]: string | undefined;
} = {};

const picturesSlice = createSlice({
  name: 'pictures',
  initialState: initState,
  reducers: {
    setHasPhoto: (state, action: PayloadAction<IPicture>) => {
      state[action.payload.memberId!] = action.payload.imageHash;
    },
    // setPhotos: (state, action: PayloadAction<Array<IPicture>>) => {
    //   action.payload.forEach((status) => {
    //     state[status.memberId] = status.photo;
    //   });
    // },
  },
});

export const photoActions = picturesSlice.actions;
export default picturesSlice.reducer;
