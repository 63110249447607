import { FieldRenderProps } from 'react-final-form';

import { useTranslation } from 'react-i18next';

import { useFormRadioGroup } from './formRadioGroup.hooks';
import { FormFieldWrapper } from '../Form';
import { ErrorLabel } from '../../Field/Field';
import RadioGroupContent from '../../RadioGroupContent/RadioGroupContent';
import { RadioGroupPropType } from '../../RadioGroupContent/radioGroupContent.types';
import { getPropertyName } from '../../../../utils/getPropertyName';

interface IFormSelectProps<T>
  extends FieldRenderProps<T>,
    RadioGroupPropType<T> {
  setDefaultValue?: boolean;
}

export const FormRadioGroup = <T,>({
  className,
  disabled,
  input: { onChange, value, name, onBlur },
  inputId,
  isLabelHidden,
  label,
  meta: { error, touched },
  items,
  placeholder,
  selectAdditionalClassName,
  setDefaultValue,
  labelAnimation = true,
  ...props
}: IFormSelectProps<T>) => {
  const { onChangeHandler } = useFormRadioGroup({
    onChange,
    labelAnimation,
    name,
    placeholder,
    items,
    promiseOptions: props.promiseOptions,
    setDefaultValue,
    value,
  });
  const { t } = useTranslation();

  return (
    <FormFieldWrapper className={className} error={touched && error}>
      <RadioGroupContent
        checked={value! as any}
        isDisabled={disabled}
        onChange={onChangeHandler as any}
        isError={touched && error}
        label={label || t(`fields.${getPropertyName(name)}.label`)}
        items={items}
        onBlur={onBlur}
        {...props}
      />
      {touched && error && <ErrorLabel>{error}</ErrorLabel>}
    </FormFieldWrapper>
  );
};
