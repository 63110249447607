import styled from '@emotion/styled/macro';

import { StandardText } from '../../Typography/Typography';
import { FormSubmitButton } from '../../UI/Form/FormSubmitButton/FormSubmitButton';

export const Description = styled(StandardText)`
  text-align: center;
`;

export const SubmitButton = styled(FormSubmitButton)`
  max-width: 550px;
`;
