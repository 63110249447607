import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { ICallbackSaga } from '../../../models/interfaces/CallbackSaga';
import { call, put } from 'typed-redux-saga';
import { updateProcessState } from '../../redux-slices/processes';
import {
  EMERGENCY_CONTACTS_LOADING_PROCESSING,
  EMERGENCY_CONTACTS_PROCESSING,
} from '../../redux-slices/processes/constants';
import { EmergencyContactsApi } from '../../../api/base-api';
import {
  addEmergencyContacts,
  editEmergencyContacts,
  removeEmergencyContact,
  setEmergencyContacts,
} from '../../redux-slices/emergencyContacts';
import {
  ISendAddEmergencyContactPayload,
  ISendRemoveEmergencyContactPayload,
} from './model';
import { updateModalState } from '../../redux-slices/modals';
import { REMOVE_EMERGENCY_CONTACT_MODAL_NAME } from '../../../pages/profile/ProfilePage/Modals/RemoveEmergencyContactModal/RemoveEmergencyContactModal';
import { EMERGENCY_CONTACT_MODAL_NAME } from '../../../pages/profile/ProfilePage/Modals/AddEmergencyContactModal/AddEmergencyContactModal';
import { EDIT_EMERGENCY_CONTACT_MODAL_NAME } from '../../../pages/profile/ProfilePage/Modals/EditEmergencyContactModal/EditEmergencyContactModal';

function* sendAddEmergencyContact(
  action: PayloadAction<ISendAddEmergencyContactPayload>,
) {
  try {
    yield put(updateProcessState(EMERGENCY_CONTACTS_PROCESSING));

    const emergencyContactsApi = new EmergencyContactsApi();

    const { data } = yield* call(
      emergencyContactsApi.emergencyContactsPost,
      action.payload.emergencyContact,
    );
    yield* put(updateModalState(EMERGENCY_CONTACT_MODAL_NAME));
    yield put(
      addEmergencyContacts({ ...action.payload.emergencyContact, id: data }),
    );

    action.payload?.success?.();
  } catch (e) {
    action.payload?.error?.();
  } finally {
    yield put(updateProcessState(EMERGENCY_CONTACTS_PROCESSING));
  }
}

function* sendEditEmergencyContact(
  action: PayloadAction<ISendAddEmergencyContactPayload>,
) {
  try {
    yield put(updateProcessState(EMERGENCY_CONTACTS_PROCESSING));

    const emergencyContactsApi = new EmergencyContactsApi();

    const { data } = yield* call(
      emergencyContactsApi.emergencyContactsEmergencyContactIdPut,
      action.payload.emergencyContact.id!,
      action.payload.emergencyContact!,
    );
    yield* put(updateModalState(EDIT_EMERGENCY_CONTACT_MODAL_NAME));
    yield put(editEmergencyContacts(action.payload.emergencyContact));

    action.payload?.success?.();
  } catch (e) {
    action.payload?.error?.();
  } finally {
    yield put(updateProcessState(EMERGENCY_CONTACTS_PROCESSING));
  }
}

function* loadEmergencyContacts(action: PayloadAction<ICallbackSaga>) {
  try {
    yield put(updateProcessState(EMERGENCY_CONTACTS_LOADING_PROCESSING));

    const emergencyContactsApi = new EmergencyContactsApi();

    const { data } = yield* call(emergencyContactsApi.emergencyContactsGet);

    yield put(setEmergencyContacts(data));
    action.payload?.success?.();
  } catch (e) {
    action.payload?.error?.();
  } finally {
    yield put(updateProcessState(EMERGENCY_CONTACTS_LOADING_PROCESSING));
  }
}

const emergencyContactSlice = createSliceSaga({
  name: 'emergencyContacts',
  caseSagas: {
    loadEmergencyContacts,
    sendAddEmergencyContact,
    sendEditEmergencyContact,
    *sendRemove(action: PayloadAction<ISendRemoveEmergencyContactPayload>) {
      try {
        yield put(updateProcessState(EMERGENCY_CONTACTS_LOADING_PROCESSING));

        const emergencyContactsApi = new EmergencyContactsApi();

        yield* call(
          emergencyContactsApi.emergencyContactsEmergencyContactIdDelete,
          action.payload.emergencyContactId,
        );

        yield* put(removeEmergencyContact(action.payload.emergencyContactId));
        yield* put(updateModalState(REMOVE_EMERGENCY_CONTACT_MODAL_NAME));
        action.payload?.success?.();
      } catch (e) {
        action.payload?.error?.();
      } finally {
        yield put(updateProcessState(EMERGENCY_CONTACTS_LOADING_PROCESSING));
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default emergencyContactSlice.saga;
export const emergencyContactActions = emergencyContactSlice.actions;
export const { actions } = emergencyContactSlice;
