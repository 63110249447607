import { createSelector } from 'reselect';

import { Modals } from './index';
import { RootState } from '../index';

export const modalsSelector = (state: RootState) => state.modals;

export const modalDetailsSelector = (name: string) =>
  createSelector(
    modalsSelector,
    (modals: Modals): any => modals[name]?.details || {},
  );

export const modalStateSelector = (name: string) =>
  createSelector(modalsSelector, (modals: Modals): any => modals[name]?.isOpen);
