import styled from '@emotion/styled';

export const Container = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 0;
  font-size: 0.875rem;
  overflow: hidden;
  position: relative;
  justify-content: space-between;
  width: 100%;
`;

export const SelectWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  width: 95%;
`;

export const SearchWrapper = styled.div`
  display: flex;
`;

export const SelectedItemValue = styled.div`
  color: ${({ theme }) => theme.color.dark1};
  font-size: 1rem;
  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SelectedCounter = styled.div`
  background-color: ${({ theme }) => theme.color.light1};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.color.lighter_blue};
  font-weight: bold;
  height: 1.25rem;
  margin-left: 0.5rem;
  text-align: center;
  width: 1.25rem;
`;
