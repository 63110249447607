import { GroupMemberModel } from '../../../api/base-api';
import { Thumbnail } from '../../Thumbnail/Thumbnail';

export enum RenameGroupFields {
  newOwnerId = 'newOwnerId',
}

export const getMembersOptions = (members: GroupMemberModel[]) =>
  members?.map((member) => ({
    label: member.displayName,
    value: member.id,
    icon: <Thumbnail id={member.id!} />,
  }));
