import { ReactElement, ReactNode } from 'react';

import { AddButton } from './AddButton';

type Props = {
  children: ReactNode | ReactElement;
  selectProps: any;
  selectOption: (value: string) => void;
};

export const MenuList = ({
  children,
  selectProps: {
    onAddButtonClick,
    value,
    addNewButtonLabel,
    defaultAddButtonText,
  },
  ...props
}: Props) => {
  const buttonCLickHandler = () => {
    props.selectOption(value);
    onAddButtonClick();
  };

  return (
    <>
      {children}
      {onAddButtonClick && (
        <AddButton onClick={buttonCLickHandler} type="button">
          {addNewButtonLabel || defaultAddButtonText}
        </AddButton>
      )}
    </>
  );
};
