import { RootState } from '../index';
import { createSelector } from 'reselect';

export const settingsSelector = (state: RootState) => state.settings;

export const notificationSettingsSelector = createSelector(
  settingsSelector,
  (settings) => settings.notificationSettings,
);

export const endpointsSelector = createSelector(
  settingsSelector,
  (settings) => settings.endpoints,
);
