import { FieldRenderProps } from 'react-final-form';

import TabOptions, { ITabOptionsProps } from '../../TabOptions/TabOptions';

interface IFormTabOptionsProps extends ITabOptionsProps, FieldRenderProps<any> {
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  readonly?: boolean;
}

const FormTabOptions = ({
  className,
  defaultValue,
  disabled,
  input,
  options,
  readonly,
  ...props
}: IFormTabOptionsProps) => (
  <div className={className}>
    <TabOptions
      {...props}
      defaultValue={defaultValue}
      disabled={disabled}
      readonly={readonly}
      onChange={input.onChange}
      options={options}
      value={input.value}
    />
  </div>
);

export default FormTabOptions;
