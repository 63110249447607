import { css } from '@emotion/react';
import styled from '@emotion/styled';

type FormColumnProps = {
  leftSpace?: boolean;
};

export const FormColumn = styled.div<FormColumnProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;

  ${({ leftSpace }) =>
    leftSpace &&
    css`
      margin-left: 2.5rem;
    `}
`;
