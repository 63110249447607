import { createSelector } from 'reselect';

import { IAddressState, MembershipState } from './index';
import { RootState } from '../index';
import { userSelector } from '../user/selectors';
import { FamilyMemberModel, Relation } from '../../../api/base-api';
import { membersNamesSelector } from '../members/selectors';
import moment from 'moment';

export const membershipSelector = (state: RootState) => state.membership;

export const planSelector = createSelector(
  membershipSelector,
  (membership: MembershipState) => membership.plan,
);

export const lastUpdateSelector = createSelector(
  membershipSelector,
  (membership: MembershipState) => membership.lastUpdate,
);

export const shouldUpdateMembershipSelector = createSelector(
  lastUpdateSelector,
  (date) => {
    const now = moment();
    const lastUpdate = !!date ? moment(date) : moment().subtract(1, 'minute');

    return (lastUpdate && now.diff(lastUpdate, 'second') >= 5) || false;
  },
);

export const isPremiumSelector = createSelector(
  planSelector,
  (plan) => plan.planType === 'Individual' || plan.planType === 'Family',
);

export const addressSelector = createSelector(
  membershipSelector,
  (membership: MembershipState) => membership.address,
);

export const paymentSelector = createSelector(
  membershipSelector,
  (membership: MembershipState) => membership.payment,
);

export const isSameAddressSelector = createSelector(
  addressSelector,
  (address: IAddressState) =>
    !(
      address.residentialAddress &&
      Object.keys(address.residentialAddress).some((key) => {
        if (
          address.residentialAddress?.hasOwnProperty(key) &&
          address.billingAddress?.hasOwnProperty(key)
        ) {
          return (
            (address.residentialAddress as any)[key] !==
            (address.billingAddress as any)[key]
          );
        }
        return false;
      })
    ),
);

export const familyMembersSelector = createSelector(
  membershipSelector,
  userSelector,
  (membership, user) => membership.family,
);

export const familyMemberArraySelector = createSelector(
  familyMembersSelector,
  (family) => Object.values(family),
);
export const familyChildrenCountSelector = createSelector(
  familyMemberArraySelector,
  (members) =>
    members.filter((member) => member.relation === Relation.Child).length,
);
export const familyAdultCountSelector = createSelector(
  familyMemberArraySelector,
  (members) =>
    members.filter((member) => member.relation === Relation.Adult).length,
);

export const familyMemberCountSelector = createSelector(
  membershipSelector,
  (membership: MembershipState) => Object.keys(membership.family).length,
);

export const ownerFamilySelector = createSelector(
  membershipSelector,
  (state) => state,
  (membership, state): FamilyMemberModel => {
    const names = membersNamesSelector(state);
    const user = userSelector(state);

    if (user.id === membership.plan.ownerMemberId) {
      return null;
    }

    return {
      personalInformation: {
        displayName: names[membership.plan.ownerMemberId],
      },
      memberId: membership.plan.ownerMemberId,
    };
  },
);

export const isOwnerFamilySelector = createSelector(
  membershipSelector,
  userSelector,
  (membership, user) => {
    return membership?.plan.ownerMemberId === user?.id;
  },
);
