import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useModal } from '../../../hooks/useModal';
import { INVITE_TO_GROUP_MODAL_NAME } from './InviteToGroupModal';
import { AssignedEntityType } from '../../../api/base-api';
import { accessCodesSagaActions } from '../../../store/saga-slices/accessCodes';
import { useActions } from '../../../hooks/useActions';
import { groupAccessCodeSelector } from '../../../store/redux-slices/accessCodes/selectors';
import { userSelector } from '../../../store/redux-slices/user/selectors';
import { useInvite } from '../../../hooks/useInvite';

export const useInviteToGroupModal = () => {
  const { t } = useTranslation();
  const { displayName } = useSelector(userSelector);
  const getGroupAccessCode = useActions(
    accessCodesSagaActions.getGroupAccessCode,
  );
  const refreshGroupAccessCode = useActions(
    accessCodesSagaActions.refreshGroupAccessCode,
  );
  const { details } = useModal(INVITE_TO_GROUP_MODAL_NAME);

  const code = useSelector(groupAccessCodeSelector(details.id));

  const {
    copyCode,
    copyLink,
    linkIsCopied,
    codeIsCopied,
    sendMail,
    expirationIn,
    isExpired,
  } = useInvite({
    name: details?.name,
    code: code?.accessCode,
    inviter: displayName,
    type: AssignedEntityType.Group,
    validTo: code?.validTo,
  });

  const onRefresh = useCallback(async () => {
    if (details.id) {
      refreshGroupAccessCode({ id: details.id });
    }
  }, [details.id, refreshGroupAccessCode]);

  const getAccessCode = useCallback(async () => {
    if (details.id) {
      getGroupAccessCode({ id: details.id });
    }
  }, [details.id, getGroupAccessCode]);

  useEffect(() => {
    getAccessCode();
  }, [details.id, getAccessCode]);

  return {
    t,
    inviteCode: code,
    sendMail,
    copyCode,
    copyLink,
    expirationIn,
    onRefresh,
    name: details.name,
    codeIsCopied,
    linkIsCopied,
    isExpired,
  };
};
