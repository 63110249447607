import { createSelector } from 'reselect';

import { RootState } from '../index';
import { compareDates } from '../../../utils/dateHelpers';

const stateSelector = (state: RootState) => state.notifications;

export const riskAlertNotificationsSelector = createSelector(
  stateSelector,
  (state) =>
    [...state.riskAlertNotifications]?.sort((a, b) =>
      compareDates(b.date, a.date),
    ),
);

export const memberRelationNotificationsSelector = createSelector(
  stateSelector,
  (state) =>
    [...state.memberRelatedNotifications]?.sort((a, b) =>
      compareDates(b.date, a.date),
    ) || [],
);

export const allNotificationsSelector = createSelector(
  riskAlertNotificationsSelector,
  memberRelationNotificationsSelector,
  (riskAlerts, memberRelations) => {
    return [...riskAlerts, ...memberRelations].sort((a, b) =>
      compareDates(b.date, a.date),
    );
  },
);

export const unReadedNotificationCountsSelector = createSelector(
  allNotificationsSelector,
  (notifications) => notifications.filter((n) => !n.isRead).length,
);

export const memberRelationHasMoreNotificationsSelector = createSelector(
  stateSelector,
  (state) => !state.memberRelatedFullLoaded,
);

export const riskAlertHasMoreNotificationsSelector = createSelector(
  stateSelector,
  (state) => !state.riskAlertFullLoaded,
);

export const allHasMoreNotificationsSelector = createSelector(
  memberRelationHasMoreNotificationsSelector,
  riskAlertHasMoreNotificationsSelector,
  (hasMoreMemberRelation, hasMoreRiskAlert) =>
    hasMoreMemberRelation || hasMoreRiskAlert,
);

export const latestMemberRelationNotificationsIdSelector = createSelector(
  memberRelationNotificationsSelector,
  (notifications) => notifications?.[notifications.length - 1]?.id,
);

export const latestRiskAlertNotificationsIdSelector = createSelector(
  riskAlertNotificationsSelector,
  (notifications) => notifications?.[notifications.length - 1]?.id,
);

export const latestAllNotificationsIdSelector = createSelector(
  allNotificationsSelector,
  (notifications) => notifications?.[notifications.length - 1]?.id,
);
