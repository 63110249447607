import styled from '@emotion/styled';

import { rgba } from '../../utils/colors';

type ContainerProps = {
  hasChildren: boolean;
};

export const SpinnerWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const SpinnerOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: ${(props) => rgba(props.theme.color.primary, 0.05)};
`;

export const SpinnerContainer = styled.div<ContainerProps>`
  position: ${(props) => (props.hasChildren ? 'absolute' : 'relative')};
  height: 100%;
`;

export const Icon = styled.img`
  height: 100%;
  width: 100%;
`;
