export const USER_LOADING_PROCESSING = 'USER_LOADING_PROCESSING';
export const UPDATE_USER_DATA_PROCESSING = 'UPDATE_USER_DATA_PROCESSING';
export const USER_PROFILE_UPDATING_PROCESSING =
  'USER_PROFILE_UPDATING_PROCESSING';
export const USER_IMAGE_UPDATING_PROCESSING = 'USER_IMAGE_UPDATING_PROCESSING';
export const FRIENDS_LOADING_PROCESSING = 'PEOPLE_LOADING_PROCESSING';
export const FRIENDS_RELOADING_PROCESSING = 'FRIENDS_RELOADING_PROCESSING';
export const FRIEND_REMOVE_PROCESSING = 'FRIEND_REMOVE_PROCESSING';
export const FRIENDS_ACCESS_CODE_LOADING_PROCESSING =
  'FRIENDS_ACCESS_CODE_LOADING_PROCESSING';
export const FRIENDS_ACCESS_CODE_REFRESH_PROCESSING =
  'FRIENDS_ACCESS_CODE_REFRESH_PROCESSING';
export const REQUESTED_FRIEND_PROCESSING = 'REQUESTED_FRIEND_PROCESSING';
export const GROUPS_LOADING_PROCESSING = 'GROUPS_LOADING_PROCESSING';
export const GROUPS_RELOADING_PROCESSING = 'GROUPS_RELOADING_PROCESSING';
export const GROUP_DELETING_PROCESSING = 'GROUP_DELETING_PROCESSING';
export const GROUP_LEAVING_PROCESSING = 'GROUP_LEAVING_PROCESSING';
export const GROUP_ENABLE_SHARE_PROCESSING = 'GROUP_ENABLE_SHARE_PROCESSING';
export const GROUP_CREATING_PROCESSING = 'GROUP_CREATING_PROCESSING';
export const GROUP_RENAMING_PROCESSING = 'GROUP_RENAMING_PROCESSING';
export const GROUP_PASS_OWNERSHIP_PROCESSING =
  'GROUP_PASS_OWNERSHIP_PROCESSING';
export const GROUP_ACCESS_CODE_LOADING_PROCESSING =
  'GROUP_ACCESS_CODE_LOADING_PROCESSING';
export const GROUP_ACCESS_CODE_REFRESH_PROCESSING =
  'GROUP_ACCESS_CODE_REFRESH_PROCESSING';

export const FAMILY_MEMBER_ACCESS_CODE_LOADING_PROCESSING =
  'FAMILY_MEMBER_ACCESS_CODE_LOADING_PROCESSING';
export const FAMILY_MEMBER_ACCESS_CODE_REFRESH_PROCESSING =
  'FAMILY_MEMBER_ACCESS_CODE_REFRESH_PROCESSING';
export const ACCEPT_INVITATION_PROCESSING = 'ACCEPT_INVITATION_PROCESSING';
export const ACCESS_CODE_PROCESSING = 'ACCESS_CODE_PROCESSING';
export const EMERGENCY_CONTACTS_PROCESSING = 'EMERGENCY_CONTACTS_PROCESSING';
export const EMERGENCY_CONTACTS_LOADING_PROCESSING =
  'EMERGENCY_CONTACTS_LOADING_PROCESSING';
export const NOTIFICATIONS_LOADING_PROCESSING =
  'NOTIFICATIONS_LOADING_PROCESSING';
export const SETTINGS_NOTIFICATION_PROCESSING =
  'SETTINGS_NOTIFICATION_PROCESSING';

export const ALL_MORE_LOADING = 'ALL_MORE_LOADING';
export const PEOPLE_MORE_LOADING = 'PEOPLE_MORE_LOADING';
export const RISKALERT_MORE_LOADING = 'RISKALERT_MORE_LOADING';

export const ADD_MEMBERSHIP_ADDRESS_PROCESSING =
  'ADD_MEMBERSHIP_ADDRESS_PROCESSING';
export const ADD_MEMBERSHIP_DATE_OF_BIRTH_PROCESSING =
  'ADD_MEMBERSHIP_DATE_OF_BIRTH_PROCESSING';
export const ADD_MEMBERSHIP_PAYMENT_PROCESSING =
  'ADD_MEMBERSHIP_PAYMENT_PROCESSING';
export const UPDATE_MEMBERSHIP_PAYMENT_PROCESSING =
  'UPDATE_MEMBERSHIP_PAYMENT_PROCESSING';
export const MEMBERSHIP_FAMILY_MEMBER_PROCESSING =
  'MEMBERSHIP_FAMILY_MEMBER_PROCESSING';
export const SETUP_ACCOUNT_PROCESSING = 'SETUP_ACCOUNT_PROCESSING';

export const MEMBERSHIP_SUMMARY_RELOADING = 'MEMBERSHIP_SUMMARY_RELOADING';

export const MEMBERSHIP_ADDRESS_RELOADING = 'MEMBERSHIP_ADDRESS_RELOADING';

export const MEMBERSHIP_ADDRESS_PROCESSING = 'MEMBERSHIP_ADDRESS_PROCESSING';

export const MEMBERSHIP_UPGRADE_PROCESSING = 'MEMBERSHIP_UPGRADE_PROCESSING';

export const MEMBERSHIP_CARD_DETAILS_RELOADING =
  'MEMBERSHIP_CARD_DETAILS_RELOADING';

export const LOAD_COUNTRY_BRIEF_PROCESSING = 'LOAD_COUNTRY_BRIEF_PROCESSING';
export const LOAD_COUNTRIES_PROCESSING = 'LOAD_COUNTRIES_PROCESSING';
export const LOAD_COUNTRY_RISK_ALERTS_PROCESSING =
  'LOAD_COUNTRY_RISK_ALERTS_PROCESSING';
export const LOAD_SUBSCRIBED_COUNTRIES_RISK_ALERTS_PROCESSING =
  'LOAD_SUBSCRIBED_COUNTRIES_RISK_ALERTS_PROCESSING';
export const LOAD_SUBSCRIBED_COUNTRIES_PROCESSING =
  'LOAD_SUBSCRIBED_COUNTRIES_PROCESSING';
export const SUBSCRIBE_COUNTRY_PROCESSING = 'SUBSCRIBE_COUNTRY_PROCESSING';
export const UNSUBSCRIBE_COUNTRY_PROCESSING = 'UNSUBSCRIBE_COUNTRY_PROCESSING';

export const LOAD_ENDPOINTS_PROCESSING = 'LOAD_ENDPOINTS_PROCESSING';

export const USER_RESEND_EMAIL_PROCESSING = 'USER_RESEND_EMAIL_PROCESSING';
export const USER_SEND_EMAIL_CONFIRMATION_TOKEN =
  'USER_SEND_EMAIL_CONFIRMATION_TOKEN';
