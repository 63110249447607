import { createSliceSaga } from 'redux-toolkit-saga';
import { call, put, select } from 'typed-redux-saga';
import { updateProcessState } from '../../redux-slices/processes';
import {
  LOAD_COUNTRIES_PROCESSING,
  LOAD_SUBSCRIBED_COUNTRIES_PROCESSING,
  SUBSCRIBE_COUNTRY_PROCESSING,
  UNSUBSCRIBE_COUNTRY_PROCESSING,
} from '../../redux-slices/processes/constants';
import { CountriesApi } from '../../../api/base-api';
import { compareStrings } from '../../../utils/compareStrings';
import { countriesSelector } from '../../redux-slices/countries/selectors';
import { countryActions } from '../../redux-slices/countries';
import { PayloadAction } from '@reduxjs/toolkit';
import { ISubscribeCountry, IUnsubscribeCountry } from '../riskAlerts/models';

const countries = createSliceSaga({
  name: 'countries-saga',
  caseSagas: {
    *loadCountries() {
      try {
        yield put(updateProcessState(LOAD_COUNTRIES_PROCESSING));
        const countries = yield* select(countriesSelector);

        if (!countries.length) {
          const countriesApi = new CountriesApi();
          const response = yield* call(countriesApi.countriesGet);
          yield put(
            countryActions.setCountries(
              response.data
                .sort((a, b) => compareStrings(a.name!, b.name!))
                .filter((el) => el.name.length > 0),
            ),
          );
        }
      } catch (e) {
      } finally {
        yield put(updateProcessState(LOAD_COUNTRIES_PROCESSING));
      }
    },
    *loadSubscribedCountries() {
      try {
        yield put(updateProcessState(LOAD_SUBSCRIBED_COUNTRIES_PROCESSING));
        const countriesApi = new CountriesApi();
        const response = yield* call(
          countriesApi.countriesSubscribedCountriesGet,
        );
        yield put(countryActions.setSubscribedCountries(response.data));
      } catch (e) {
      } finally {
        yield put(updateProcessState(LOAD_SUBSCRIBED_COUNTRIES_PROCESSING));
      }
    },
    *subscribeCountry({
      payload: { countryCode, error },
    }: PayloadAction<ISubscribeCountry>) {
      try {
        yield put(updateProcessState(SUBSCRIBE_COUNTRY_PROCESSING));
        yield put(countryActions.addSubscribedCountry(countryCode));
        const countriesApi = new CountriesApi();
        yield call(countriesApi.countriesCodeActionsSubscribePost, countryCode);
      } catch (e) {
        yield put(countryActions.removeSubscribedCountry(countryCode));
        console.log(e);
        error?.();
      } finally {
        yield put(updateProcessState(SUBSCRIBE_COUNTRY_PROCESSING));
      }
    },
    *unsubscribeCountry({
      payload: { countryCode, error },
    }: PayloadAction<IUnsubscribeCountry>) {
      try {
        yield put(updateProcessState(UNSUBSCRIBE_COUNTRY_PROCESSING));
        const countriesApi = new CountriesApi();
        yield call(
          countriesApi.countriesCodeActionsUnsubscribePost,
          countryCode,
        );
        yield put(countryActions.removeSubscribedCountry(countryCode));
      } catch (e) {
        yield put(countryActions.addSubscribedCountry(countryCode));
        console.log(e);
        error?.();
      } finally {
        yield put(updateProcessState(UNSUBSCRIBE_COUNTRY_PROCESSING));
      }
    },
  },
});

export default countries.saga;
export const countriesActions = countries.actions;
