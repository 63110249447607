import { css } from '@emotion/react';
import styled from '@emotion/styled';

type WrapperType = {
  disabled?: boolean;
};

export const Wrapper = styled.div<WrapperType>`
  min-width: 15rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: row;
  padding: 0.25rem;
  align-items: center;
  justify-content: space-between;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      border-color: ${theme.color.dark2};
    `}
`;
