import * as React from 'react';

import { useTranslation } from 'react-i18next';

import {
  ModalButtonsContainer,
  ModalDescription,
  ModalTitle,
} from '../../Modal/modal.styles';
import Button from '../../UI/Button/Button';
import { Modal } from '../../Modal/Modal';
import { useActions } from '../../../hooks/useActions';
import { updateModalState } from '../../../store/redux-slices/modals';
import { ButtonStyleType } from '../../UI/Button/button.models';

type ActionModalProps = {
  title: string;
  name: string;
  type?: ButtonStyleType;
  onConfirm: () => void;
  children: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  isLoading?: boolean;
};
export const ActionModal = ({
  title,
  name,
  type,
  onConfirm,
  children,
  confirmText,
  cancelText,
  isLoading,
}: ActionModalProps) => {
  const { t } = useTranslation();
  const UpdateModalState = useActions(updateModalState);

  return (
    <Modal name={name}>
      <ModalTitle>{title}</ModalTitle>
      <ModalDescription>{children}</ModalDescription>
      <ModalButtonsContainer>
        <Button
          fillButton
          styleType="white-blue"
          onClick={() => {
            UpdateModalState(name);
          }}
        >
          {cancelText || t('buttons.cancel')}
        </Button>
        <Button
          fillButton
          styleType={type || 'base-filled'}
          isLoading={isLoading}
          onClick={onConfirm}
        >
          {confirmText || t('buttons.confirm')}
        </Button>
      </ModalButtonsContainer>
    </Modal>
  );
};
