import 'react-phone-number-input/style.css';
import PhoneNumberInput from './phoneInput.styles';
import FieldLabel from '../Field/FieldLabel';
import { FieldWrapper } from '../Field/Field';

export const PhoneInput = (props: any) => (
  <FieldWrapper className={props.className}>
    <PhoneNumberInput
      {...props}
      value={props.value as any}
      smartCaret
      international
    />
    {!props.isLabelHidden && (
      <FieldLabel
        disabled={props.disabled}
        autoLabelAnimation={props.labelAnimation}
      >
        {props.label}
      </FieldLabel>
    )}
  </FieldWrapper>
);
