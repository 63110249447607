import i18next from 'i18next';

import { Wizard } from '../models';
import { RoutePath } from '../../../../models/RoutePath';

export enum WizardStepsCn {
  PlanPage = 'membership-plan-page',
  DescriptionPage = 'membership-description-page',
  AddressPage = 'membership-address-page',
  BirthOfDate = 'membership-birt-of-date-page',
  PaymentPage = 'membership-payment-page',
  CompletePage = 'membership-complete-page',
}

export const ADD_MEMBERSHIP_WIZARD_NAME = 'ADD_MEMBERSHIP_WIZARD_NAME';

export const addMembershipWizard: Wizard = {
  steps: [
    {
      order: 0,
      caption: i18next.t('wizard.add-membership.plan-page'),
      canonicalName: WizardStepsCn.PlanPage,
      url: RoutePath.MembershipActivatePlanStep,
      allowSkip: false,
      isValid: false,
      isAvailable: true,
      isAuth: false,
    },
    {
      order: 1,
      caption: i18next.t('wizard.add-membership.description-page'),
      canonicalName: WizardStepsCn.DescriptionPage,
      url: RoutePath.MembershipActivateDescriptionStep,
      allowSkip: false,
      isValid: false,
      isAvailable: false,
      isAuth: false,
    },
    {
      order: 2,
      caption: i18next.t('wizard.add-membership.address-page'),
      canonicalName: WizardStepsCn.AddressPage,
      url: RoutePath.MembershipActivateAddressStep,
      allowSkip: false,
      isValid: false,
      isAvailable: true,
      isAuth: false,
    },
    {
      order: 3,
      caption: i18next.t('wizard.add-membership.birth-of-date-page'),
      canonicalName: WizardStepsCn.BirthOfDate,
      url: RoutePath.MembershipActivateBirthOfDateStep,
      allowSkip: false,
      isValid: false,
      isAvailable: true,
      isAuth: false,
    },
    {
      order: 4,
      caption: i18next.t('wizard.add-membership.payment-page'),
      canonicalName: WizardStepsCn.PaymentPage,
      url: RoutePath.MembershipActivatePaymentStep,
      allowSkip: false,
      isValid: false,
      isAvailable: true,
      isAuth: false,
    },
    {
      order: 5,
      caption: i18next.t('wizard.add-membership.complete-page'),
      canonicalName: WizardStepsCn.CompletePage,
      url: RoutePath.MembershipActivateCompleteStep,
      allowSkip: false,
      isValid: false,
      isAvailable: true,
      isAuth: false,
    },
  ],
  activeStep: WizardStepsCn.PlanPage,
};
