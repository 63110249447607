export enum RoutePath {
  AuthRedirect = 'authRedirect',
  SignUpRedirect = 'signUpRedirect/:planType',
  AddMembershipRedirect = 'addMembershipRedirect',

  EmailConfirmationRedirect = 'email-confirmation',

  GroupId = ':groupId',
  MemberId = ':memberId',

  RiskAlerts = 'risk-alerts',
  RiskAlertsAbsolute = '/risk-alerts',
  RiskAlertsCountry = ':countryCode',
  RiskAlertsCountryAbsolute = '/risk-alerts/:countryCode',

  Intelligence = 'intelligence',
  IntelligenceAbsolute = '/intelligence',
  IntelligenceCountry = ':countryCode',
  IntelligenceCountryAbsolute = '/intelligence/:countryCode',

  Membership = 'membership',
  MembershipAbsolute = '/membership',
  MembershipActivate = 'membership/activate',
  MembershipActivateAbsolute = '/membership/activate',
  MembershipUpgrade = 'membership/upgrade',
  MembershipUpgradeAbsolute = '/membership/upgrade',

  MembershipActivatePlanStep = 'plan',
  MembershipActivateDescriptionStep = 'description',
  MembershipActivateAddressStep = 'address',
  MembershipActivateBirthOfDateStep = 'birth-of-date',
  MembershipActivatePaymentStep = 'payment',
  MembershipActivateCompleteStep = '..',

  MembershipUpgradePaymentStep = 'payment',

  HomeAbsolute = '/dashboard',
  PeopleAbsolute = '/dashboard/friends',
  DashboardAbsolute = '/dashboard/:groupId',
  GroupMemberAbsolute = '/dashboard/group/:groupId/:memberId',

  Dashboard = 'dashboard/*',
  Group = `group/:groupId`,
  GroupMember = `group/:groupId/:memberId`,
  Friend = ':memberId',
  InvitationsAbsolute = '/invite/:accessCode',

  Profile = '/profile',
  EditProfile = '/profile/edit',
  Invitations = '/invite',

  Login = '/login',

  SignUp = '/signup',
  SignUpCodeStep = 'code',
  SignUpPlanStep = 'plan',
  SignUpAddressStep = 'address',
  SignUpDateOfBirthStep = 'date-of-birth',
  SignUpPaymentStep = 'payment',
  SignUpSetupAccountStep = 'setup-account',
  SignUpFamilyMembersStep = 'family-members',
  SignUpCompleteStep = 'success',

  All = '*',
}
