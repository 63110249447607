import { AnySchema } from 'ajv';

import { RenameGroupFields } from './passOwnershipForm.models';
import { FieldErrorMessage } from '../../../models/interfaces/FieldErrorMessage';
import { PassGroupOwnershipModel } from '../../../api/base-api';
import { validateFormData } from '../../../utils/validations/validateFormData';

export const passOwnershipFormModel: AnySchema | any = {
  type: 'object',
  properties: {
    [RenameGroupFields.newOwnerId]: {
      type: 'string',
    },
  },

  required: [RenameGroupFields.newOwnerId],
};

export const validatePassOwnershipForm = (
  data: PassGroupOwnershipModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, passOwnershipFormModel);
