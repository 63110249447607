import { createSelector } from 'reselect';

import moment from 'moment';

import { groupsSelector } from '../groups/selectors';
import { friendsSelector } from '../friends/selectors';
import { RootState } from '../index';
import { locationSelector } from '../locations/selectors';
import { userSelector } from '../user/selectors';
import { familyMemberArraySelector } from '../membership/selectors';
import { MemberModel } from '../../../api/base-api';

const membersSelector = (state: RootState) => state.members;
const checkInsSelector = createSelector(
  membersSelector,
  (members) => members.checkIns,
);

export const memberByIdSelector = (id: string | number) =>
  createSelector(
    groupsSelector,
    friendsSelector,
    userSelector,
    familyMemberArraySelector,
    (groups, friends, user, familyMembers) => {
      if (typeof id === 'number') {
        return null;
      }
      if (user.id === id) {
        return user;
      }
      for (const group of groups) {
        for (const member of group.members || []) {
          if (member.id === id) {
            return member;
          }
        }
      }
      for (const friend of friends) {
        if (friend.id === id) {
          return friend;
        }
      }
      for (const member of familyMembers) {
        if ((member.familyMemberId || member.memberId) === id) {
          return {
            id: member.familyMemberId || member.memberId,
            displayName: member.personalInformation!.displayName,
          } as MemberModel;
        }
      }
    },
  );

export const diffUpdateLocalizationSelector = (id: string | number) =>
  createSelector(locationSelector(`${id}`), (location) => {
    if (!location?.lastCheckedAt) {
      return 20;
    }
    const current = moment();
    const validTo = moment(location?.lastCheckedAt);

    const diff = current.diff(validTo, 'minutes', false);

    return diff;
  });

export const membersCheckInIdsSelector = createSelector(
  membersSelector,
  (member) => member.checkIns.map((checkIn) => checkIn.sendingMemberId!) || [],
);

export const membersNamesSelector = createSelector(
  membersSelector,
  (member) => member.names,
);
