import { AnySchema } from 'ajv';

import { FamilyMemberFields } from './familyMemberForm.models';
import { FieldErrorMessage } from '../../../models/interfaces/FieldErrorMessage';
import { CreateGroupCommand } from '../../../api/base-api';
import { validateFormData } from '../../../utils/validations/validateFormData';

export const FamilyMemberFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [FamilyMemberFields.FirstName]: {
      type: 'string',
    },
    [FamilyMemberFields.LastName]: {
      type: 'string',
    },
    [FamilyMemberFields.Relation]: {
      type: 'string',
    },
    [FamilyMemberFields.DateOfBirth]: {
      type: 'string',
      format: 'date',
    },
  },

  required: [
    FamilyMemberFields.FirstName,
    FamilyMemberFields.LastName,
    FamilyMemberFields.Relation,
    FamilyMemberFields.DateOfBirth,
  ],
};

export const validateFamilyMemberForm = (
  data: CreateGroupCommand,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, FamilyMemberFormSchema);
