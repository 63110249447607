import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { all, call, put, select } from 'typed-redux-saga/macro';

import { PayloadAction } from '@reduxjs/toolkit';

import moment from 'moment';

import { AccountInfo } from '@azure/msal-browser';

import { setUserCoords, updateUserData } from '../../redux-slices/user';
import { updateProcessState } from '../../redux-slices/processes';
import {
  NOTIFICATIONS_LOADING_PROCESSING,
  USER_LOADING_PROCESSING,
  USER_PROFILE_UPDATING_PROCESSING,
  USER_RESEND_EMAIL_PROCESSING,
  USER_SEND_EMAIL_CONFIRMATION_TOKEN,
} from '../../redux-slices/processes/constants';
import {
  AssignedEntityType,
  InvitationsApi,
  MembersApi,
  SafetyStatusType,
} from '../../../api/base-api';
import { PCA } from '../../../index';
import {
  IAccountInfo,
  IUpdatePhotoPayload,
  IUpdateUserDataPayload,
  SendConfirmationEmailToken,
  SendMemberLocalization,
} from './model';
import { userSelector } from '../../redux-slices/user/selectors';
import { checkInSagaActions } from '../checkIn';
import { getFirstAndLastName } from '../../../utils/getFullName';
import { photoActions } from '../../redux-slices/pictures';
import { setNames } from '../../redux-slices/members';
import {
  fetchMembershipAddressData,
  fetchMembershipPaymentData,
  fetchMembershipSummaryData,
} from '../membership';
import { countriesActions } from '../countries';
import { friendsActions } from '../friends';
import { loadGroups } from '../groups';

export function* checkUsageCode() {
  const inviteCode = sessionStorage.getItem('inviteCode');
  if (!inviteCode) {
    return;
  }

  try {
    const { data: invitationDetails } = yield* call(
      new InvitationsApi().invitationsAccessCodeGet,
      inviteCode,
    );
    const { data } = yield* call(
      new InvitationsApi().invitationsAccessCodeActionsAcceptInvitationPost,
      inviteCode,
    );

    if (invitationDetails.invitationType == AssignedEntityType.FamilyMember) {
      yield fetchMembershipSummaryData();
    }

    if (invitationDetails.invitationType == AssignedEntityType.Group) {
      yield loadGroups();
    }

    if (invitationDetails.invitationType == AssignedEntityType.Member) {
      yield put(friendsActions.loadFriendsData);
    }
  } catch (e) {
  } finally {
    sessionStorage.removeItem('inviteCode');
  }
}

export function* updateUserImage({ memberId, image }: IUpdatePhotoPayload) {
  const membersApi = new MembersApi();
  yield* call(membersApi.membersMeProfilePicturePut, image[0]);
  const hash = new Date().getTime();
  yield* put(photoActions.setHasPhoto({ memberId, imageHash: `${hash}` }));
}

const userSlice = createSliceSaga({
  name: 'user-saga',
  caseSagas: {
    *loadUserData(action?: PayloadAction<IAccountInfo | null>) {
      try {
        yield put(updateProcessState(USER_LOADING_PROCESSING));

        if (action.payload?.redirectType !== 'signUp') {
          yield all([
            call(fetchMembershipSummaryData),
            call(fetchMembershipAddressData),
            call(fetchMembershipPaymentData),
          ]);
        }

        yield checkUsageCode();

        yield all([
          put(countriesActions.loadCountries()),
          put(countriesActions.loadSubscribedCountries()),
          put(friendsActions.loadFriendsData()),
        ]);

        const membersApi = new MembersApi();
        const { data } = yield* call(
          membersApi.membersMeProfilePersonalDataGet,
        );

        let account = action?.payload! as AccountInfo;
        if (!account) {
          account = PCA.getActiveAccount()!;
        }

        const { firstName, lastName } = getFirstAndLastName(
          data.displayName as string,
        );

        yield put(
          updateUserData({
            id: account.localAccountId,
            family_name: lastName,
            given_name: firstName,
            emailAddress: data.emailAddress,
            displayName: data.displayName,
            phoneNumber: data.phoneNumber,
            emailVerified: data.emailVerified,
          }),
        );
      } catch (e) {
        console.log(e);
      } finally {
        yield put(updateProcessState(USER_LOADING_PROCESSING));
      }
    },
    *updateUserData({
      payload: { error, success, data, image, memberId },
    }: PayloadAction<IUpdateUserDataPayload>) {
      try {
        yield put(updateProcessState(USER_PROFILE_UPDATING_PROCESSING));
        const membersApi = new MembersApi();
        yield* call(membersApi.membersMeProfilePersonalDataPut, { data });
        yield put(
          updateUserData({
            family_name: data.lastName,
            given_name: data.firstName,
            emailAddress: data.emailAddress,
            displayName: `${data.firstName} ${data.lastName}`,
            phoneNumber: data.phoneNumber,
          }),
        );
        const user = yield* select(userSelector);
        yield put(
          setNames({
            [user.id as string]: `${data.firstName} ${data.lastName}`,
          }),
        );

        if (image) {
          yield updateUserImage({ image, memberId });
        }

        success?.();
      } catch (e) {
        error?.(e);
      } finally {
        yield put(updateProcessState(USER_PROFILE_UPDATING_PROCESSING));
      }
    },
    *sendMemberLocalization(action: PayloadAction<SendMemberLocalization>) {
      try {
        yield put(updateProcessState(NOTIFICATIONS_LOADING_PROCESSING));

        // const locationsApi = new LocationsApi();
        //
        // yield* call(locationsApi.locationsPut, {
        //   longitude: action.payload.longitude,
        //   latitude: action.payload.latitude
        // });

        yield put(
          setUserCoords({
            lat: action.payload.latitude!,
            lng: action.payload.longitude!,
          }),
        );
        action.payload?.success?.();
      } catch (e) {
        action.payload?.error?.();
      } finally {
        yield put(updateProcessState(NOTIFICATIONS_LOADING_PROCESSING));
      }
    },
    *sendResponseCheckIn(action: PayloadAction<SendMemberLocalization>) {
      try {
        const memberApi = new MembersApi();

        memberApi.membersMeCheckInsActionsResponsePut({
          status: SafetyStatusType.CheckedIn,
        });
        const { id } = yield* select(userSelector);
        yield put(
          checkInSagaActions.updateCheckIn({
            memberId: id!,
            status: {
              checkInDate: moment().toISOString(),
              checkInStatus: SafetyStatusType.CheckedIn,
            },
          }),
        );
        action.payload?.success?.();
      } catch (e) {
        action.payload?.error?.();
      } finally {
      }
    },
    *sendResponseHelp(action: PayloadAction<SendMemberLocalization>) {
      try {
        const memberApi = new MembersApi();

        memberApi.membersMeCheckInsActionsResponsePut({
          status: 'RequestedHelp',
        });
        const { id } = yield* select(userSelector);
        yield put(
          checkInSagaActions.updateCheckIn({
            memberId: id!,
            status: {
              checkInDate: moment().toISOString(),
              checkInStatus: 'RequestedHelp',
            },
          }),
        );
        action.payload?.success?.();
      } catch (e) {
        action.payload?.error?.();
      } finally {
      }
    },
    *emailConfirmationToken(action: PayloadAction<SendConfirmationEmailToken>) {
      const memberApi = new MembersApi();
      const { token, success } = action.payload;
      try {
        yield put(updateProcessState(USER_SEND_EMAIL_CONFIRMATION_TOKEN));
        yield* call(
          memberApi.membersMeProfilePersonalDataActionsEmailConfirmationPost,
          { token },
        );
        success?.();
      } catch (e) {
      } finally {
        yield put(updateProcessState(USER_SEND_EMAIL_CONFIRMATION_TOKEN));
      }
    },
    *resendVerificationEmail(action: PayloadAction<any>) {
      const memberApi = new MembersApi();
      try {
        yield put(updateProcessState(USER_RESEND_EMAIL_PROCESSING));
        yield* call(
          memberApi.membersMeProfilePersonalDataActionsResendEmailConfirmationPost,
        );
      } catch (e) {
      } finally {
        yield put(updateProcessState(USER_RESEND_EMAIL_PROCESSING));
      }
    },
    *sendRequestHelp(action: PayloadAction<SendMemberLocalization>) {
      try {
        const memberApi = new MembersApi();

        memberApi.membersMeActionsEmergencyRequestPost();
        action.payload?.success?.();
      } catch (e) {
        action.payload?.error?.();
      } finally {
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default userSlice.saga;
export const userActions = userSlice.actions;
export const { actions } = userSlice;
