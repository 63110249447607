export enum FamilyMemberFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  Relation = 'relation',
  DateOfBirth = 'dateOfBirth',
}

export const familyRelationOptions = (adultEnabled, childEnabled, disabled) => [
  { label: 'Adult', value: 'Adult', disabled: disabled || !adultEnabled },
  { label: 'Child', value: 'Child', disabled: disabled || !childEnabled },
];
