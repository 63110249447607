import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAlert } from 'react-alert';

import { modalDetailsSelector } from '../../../store/redux-slices/modals/selectors';
import { DELETE_GROUP_MODAL_NAME } from './DeleteGroupModal';
import { useActions } from '../../../hooks/useActions';
import { groupsAction } from '../../../store/saga-slices/groups';
import { RoutePath } from '../../../models/RoutePath';
import { processSelector } from '../../../store/redux-slices/processes/selectors';
import { GROUP_DELETING_PROCESSING } from '../../../store/redux-slices/processes/constants';

export const useDeleteGroupModal = () => {
  const { success } = useAlert();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { name, id } = useSelector(
    modalDetailsSelector(DELETE_GROUP_MODAL_NAME),
  );
  const isLoading = useSelector(processSelector(GROUP_DELETING_PROCESSING));

  const deleteGroup = useActions(groupsAction.sendDeleteGroup);

  const onConfirm = useCallback(() => {
    deleteGroup({
      groupId: id,
      success: () => {
        success(t('phrase.group-has-been-deleted', { name }));
        navigate(RoutePath.HomeAbsolute);
      },
    });
  }, [deleteGroup, id, name, navigate, success, t]);

  return {
    t,
    name,
    id,
    onConfirm,
    isLoading,
  };
};
