/* tslint:disable */
/* eslint-disable */
/**
 * TravelShield.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
} from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  assertParamExists,
  createRequestFunction,
  DUMMY_BASE_URL,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
} from './base';

export interface AccessCodeResponse {
  /**
   *
   * @type {string}
   * @memberof RefreshFamilyMemberAccessCodeResponse
   */
  accessCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof RefreshFamilyMemberAccessCodeResponse
   */
  validTo?: string;
}

/**
 *
 * @export
 * @interface BuyMembershipResponse
 */
export interface BuyMembershipResponse {
  /**
   *
   * @type {string}
   * @memberof BuyMembershipResponse
   */
  membershipId?: string;
  /**
   *
   * @type {string}
   * @memberof BuyMembershipResponse
   */
  clientSecret?: string | null;
}
/**
 *
 * @export
 * @interface UpdateMembershipAddressCommand
 */
export interface UpdateMembershipAddressCommand {
  /**
   *
   * @type {RequestAddress}
   * @memberof UpdateMembershipAddressCommand
   */
  residentialAddress?: RequestAddress | null;
  /**
   *
   * @type {RequestAddress}
   * @memberof UpdateMembershipAddressCommand
   */
  billingAddress?: RequestAddress | null;
}

export type CheckInStatus = 'CheckedIn' | 'RequestedHelp' | 'Pending';
/**
 *
 * @export
 * @interface RefreshFamilyMemberAccessCodeResponse
 */
export interface RefreshFamilyMemberAccessCodeResponse {
  /**
   *
   * @type {string}
   * @memberof RefreshFamilyMemberAccessCodeResponse
   */
  accessCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof RefreshFamilyMemberAccessCodeResponse
   */
  validTo?: string;
}
export const PlanType = {
  Free: 'Free',
  Individual: 'Individual',
  Family: 'Family',
} as const;

export type PlanType = typeof PlanType[keyof typeof PlanType];

/**
 *
 * @export
 * @interface Country
 */
export interface Country {
  /**
   *
   * @type {string}
   * @memberof Country
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof Country
   */
  code?: string | null;
}
export const DayOfWeek = {
  Sunday: 'Sunday',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
} as const;

export type DayOfWeek = typeof DayOfWeek[keyof typeof DayOfWeek];

/**
 *
 * @export
 * @interface PersonalInformation
 */
export interface PersonalInformation {
  /**
   *
   * @type {string}
   * @memberof PersonalInformation
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof PersonalInformation
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof PersonalInformation
   */
  displayName?: string | null;
}

export interface CountryBriefModel {
  /**
   *
   * @type {string}
   * @memberof CountryBriefModel
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CountryBriefModel
   */
  name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CountryBriefModel
   */
  isStarred?: boolean;
  /**
   *
   * @type {string}
   * @memberof CountryBriefModel
   */
  riskLevelDate?: string;
  /**
   *
   * @type {number}
   * @memberof CountryBriefModel
   */
  riskLevel?: number;
  /**
   *
   * @type {string}
   * @memberof CountryBriefModel
   */
  riskLevelTitle?: string | null;
  /**
   *
   * @type {string}
   * @memberof CountryBriefModel
   */
  riskLevelDescription?: string | null;
  /**
   *
   * @type {Array<RiskAlertModel>}
   * @memberof CountryBriefModel
   */
  riskAlerts?: Array<RiskAlertModel> | null;
  /**
   *
   * @type {string}
   * @memberof CountryBriefModel
   */
  generalSafetyInformation?: string | null;
  /**
   *
   * @type {Array<TravelTipModel>}
   * @memberof CountryBriefModel
   */
  travelTips?: Array<TravelTipModel> | null;
}

/**
 *
 * @export
 * @interface CountryModel
 */
export interface CountryModel {
  /**
   *
   * @type {string}
   * @memberof CountryModel
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CountryModel
   */
  code?: string | null;
  /**
   *
   * @type {string}
   * @memberof CountryModel
   */
  name?: string | null;
}

export interface RequestAddress {
  /**
   *
   * @type {string}
   * @memberof RequestAddress
   */
  street?: string | null;
  /**
   *
   * @type {string}
   * @memberof RequestAddress
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof RequestAddress
   */
  country?: string | null;
  /**
   *
   * @type {string}
   * @memberof RequestAddress
   */
  zipCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof RequestAddress
   */
  state?: string | null;
  /**
   *
   * @type {string}
   * @memberof RequestAddress
   */
  number?: string | null;
}

/**
 *
 * @export
 * @interface CreateMembershipCommand
 */
export interface CreateMembershipCommand {
  /**
   *
   * @type {RequestAddress}
   * @memberof CreateMembershipCommand
   */
  residentialAddress?: RequestAddress | null;
  /**
   *
   * @type {RequestAddress}
   * @memberof CreateMembershipCommand
   */
  billingAddress?: RequestAddress | null;
  /**
   *
   * @type {string}
   * @memberof CreateMembershipCommand
   */
  dateOfBirth?: string;
  /**
   *
   * @type {PlanType}
   * @memberof CreateMembershipCommand
   */
  planType?: PlanType;
}

/**
 *
 * @export
 * @interface CreateMembershipCommandResponse
 */
export interface CreateMembershipCommandResponse {
  /**
   *
   * @type {string}
   * @memberof CreateMembershipCommandResponse
   */
  membershipId?: string;
  /**
   *
   * @type {string}
   * @memberof CreateMembershipCommandResponse
   */
  clientSecret?: string | null;
}

/**
 *
 * @export
 * @interface FamilyMemberModel
 */
export interface FamilyMemberModel {
  /**
   *
   * @type {PersonalInformation}
   * @memberof FamilyMemberModel
   */
  personalInformation?: PersonalInformation | null;
  /**
   *
   * @type {string}
   * @memberof FamilyMemberModel
   */
  dateOfBirth?: string;
  /**
   *
   * @type {Relation}
   * @memberof FamilyMemberModel
   */
  relation?: Relation;
  /**
   *
   * @type {string}
   * @memberof FamilyMemberModel
   */
  familyMemberId?: string | null;
  /**
   *
   * @type {string}
   * @memberof FamilyMemberModel
   */
  memberId?: string | null;
}

/**
 *
 * @export
 * @interface FamilyMemberModel
 */
export interface FamilyMemberModel {
  /**
   *
   * @type {PersonalInformation}
   * @memberof FamilyMemberModel
   */
  personalInformation?: PersonalInformation | null;
  /**
   *
   * @type {string}
   * @memberof FamilyMemberModel
   */
  dateOfBirth?: string;
  /**
   *
   * @type {Relation}
   * @memberof FamilyMemberModel
   */
  relation?: Relation;
  /**
   *
   * @type {string}
   * @memberof FamilyMemberModel
   */
  familyMemberId?: string | null;
  /**
   *
   * @type {string}
   * @memberof FamilyMemberModel
   */
  memberId?: string | null;
}

/**
 *
 * @export
 * @interface GetFamilyMembersResponse
 */
export interface GetFamilyMembersResponse {
  /**
   *
   * @type {Array<FamilyMemberModel>}
   * @memberof GetFamilyMembersResponse
   */
  members?: Array<FamilyMemberModel> | null;
}
/**
 *
 * @export
 * @interface OwnerMemberModel
 */
export interface OwnerMemberModel {
  /**
   *
   * @type {string}
   * @memberof OwnerMemberModel
   */
  displayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof OwnerMemberModel
   */
  memberId?: string;
}

/**
 *
 * @export
 * @interface GetFamilyMembersResponse
 */
export interface GetFamilyMembersResponse {
  /**
   *
   * @type {OwnerMemberModel}
   * @memberof GetFamilyMembersResponse
   */
  owner?: OwnerMemberModel | null;
  /**
   *
   * @type {Array<FamilyMemberModel>}
   * @memberof GetFamilyMembersResponse
   */
  members?: Array<FamilyMemberModel> | null;
}

/**
 *
 * @export
 * @interface ErrorModel
 */
export interface ErrorModel {
  /**
   *
   * @type {string}
   * @memberof ErrorModel
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof ErrorModel
   */
  title?: string | null;
  /**
   *
   * @type {number}
   * @memberof ErrorModel
   */
  status?: number;
  /**
   *
   * @type {string}
   * @memberof ErrorModel
   */
  traceId?: string | null;
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof ErrorModel
   */
  errors?: { [key: string]: Array<string> } | null;
  /**
   *
   * @type {Array<StringStringValuesKeyValuePair>}
   * @memberof ErrorModel
   */
  extraHeaders?: Array<StringStringValuesKeyValuePair> | null;
}

/**
 *
 * @export
 * @interface StringStringValuesKeyValuePair
 */
export interface StringStringValuesKeyValuePair {
  /**
   *
   * @type {string}
   * @memberof StringStringValuesKeyValuePair
   */
  key?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof StringStringValuesKeyValuePair
   */
  value?: Array<string>;
}

/**
 *
 * @export
 * @interface AddFamilyMemberResponse
 */
export interface AddFamilyMemberResponse {
  /**
   *
   * @type {string}
   * @memberof AddFamilyMemberResponse
   */
  familyMemberId?: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const AssignedEntityType = {
  Group: 'Group',
  Member: 'Member',
  FamilyMember: 'FamilyMember',
} as const;

export type AssignedEntityType =
  typeof AssignedEntityType[keyof typeof AssignedEntityType];

export interface AccessCodeModel {
  /**
   *
   * @type {string}
   * @memberof AccessCodeModel
   */
  code?: string | null;
  /**
   *
   * @type {string}
   * @memberof AccessCodeModel
   */
  validTo?: string;
}

/**
 *
 * @export
 * @interface CountryBriefModel
 */
export interface CountryBriefModel {
  /**
   *
   * @type {string}
   * @memberof CountryBriefModel
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CountryBriefModel
   */
  name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CountryBriefModel
   */
  isStarred?: boolean;
  /**
   *
   * @type {string}
   * @memberof CountryBriefModel
   */
  riskLevelDate?: string;
  /**
   *
   * @type {number}
   * @memberof CountryBriefModel
   */
  riskLevel?: number;
  /**
   *
   * @type {string}
   * @memberof CountryBriefModel
   */
  riskLevelTitle?: string | null;
  /**
   *
   * @type {string}
   * @memberof CountryBriefModel
   */
  riskLevelDescription?: string | null;
  /**
   *
   * @type {Array<RiskAlertModel>}
   * @memberof CountryBriefModel
   */
  riskAlerts?: Array<RiskAlertModel> | null;
  /**
   *
   * @type {string}
   * @memberof CountryBriefModel
   */
  generalSafetyInformation?: string | null;
  /**
   *
   * @type {Array<TravelTipModel>}
   * @memberof CountryBriefModel
   */
  travelTips?: Array<TravelTipModel> | null;
}

/**
 *
 * @export
 * @interface CountryModel
 */
export interface CountryModel {
  /**
   *
   * @type {string}
   * @memberof CountryModel
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CountryModel
   */
  name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CountryModel
   */
  isStarred?: boolean;
}

/**
 *
 * @export
 * @interface CreateGroupCommand
 */
export interface CreateGroupCommand {
  /**
   *
   * @type {string}
   * @memberof CreateGroupCommand
   */
  name?: string | null;
}

/**
 *
 * @export
 * @interface CreateMembershipCommand
 */
export interface CreateMembershipCommand {
  /**
   *
   * @type {RequestAddress}
   * @memberof CreateMembershipCommand
   */
  residentialAddress?: RequestAddress | null;
  /**
   *
   * @type {RequestAddress}
   * @memberof CreateMembershipCommand
   */
  billingAddress?: RequestAddress | null;
  /**
   *
   * @type {string}
   * @memberof CreateMembershipCommand
   */
  dateOfBirth?: string;
  /**
   *
   * @type {PlanType}
   * @memberof CreateMembershipCommand
   */
  planType?: PlanType;
}

/**
 *
 * @export
 * @interface ValidateAddressQuery
 */
export interface ValidateAddressQuery {
  /**
   *
   * @type {RequestAddress}
   * @memberof ValidateAddressQuery
   */
  residentialAddress?: RequestAddress | null;
  /**
   *
   * @type {RequestAddress}
   * @memberof ValidateAddressQuery
   */
  billingAddress?: RequestAddress | null;
}

/**
 *
 * @export
 * @interface ValidateDateOfBirthQuery
 */
export interface ValidateDateOfBirthQuery {
  /**
   *
   * @type {string}
   * @memberof ValidateDateOfBirthQuery
   */
  dateOfBirth?: string;
}

/**
 *
 * @export
 * @interface CreateIndividualMembershipCommandResponse
 */
export interface CreateIndividualMembershipCommandResponse {
  /**
   *
   * @type {string}
   * @memberof CreateIndividualMembershipCommandResponse
   */
  membershipId?: string;
  /**
   *
   * @type {string}
   * @memberof CreateIndividualMembershipCommandResponse
   */
  clientSecret?: string | null;
}

/**
 *
 * @export
 * @interface EmergencyContactModel
 */
export interface EmergencyContactModel {
  /**
   *
   * @type {string}
   * @memberof EmergencyContactModel
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EmergencyContactModel
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof EmergencyContactModel
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof EmergencyContactModel
   */
  phoneNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof EmergencyContactModel
   */
  email?: string | null;
}

export interface FamilyMemberModel {
  /**
   *
   * @type {PersonalInformation}
   * @memberof FamilyMemberModel
   */
  personalInformation?: PersonalInformation | null;
  /**
   *
   * @type {string}
   * @memberof FamilyMemberModel
   */
  dateOfBirth?: string;
  /**
   *
   * @type {Relation}
   * @memberof FamilyMemberModel
   */
  relation?: Relation;
  /**
   *
   * @type {string}
   * @memberof FamilyMemberModel
   */
  familyMemberId?: string | null;
  /**
   *
   * @type {string}
   * @memberof FamilyMemberModel
   */
  memberId?: string | null;
}

/**
 *
 * @export
 * @enum {string}
 */

export const Relation = {
  Adult: 'Adult',
  Child: 'Child',
} as const;

export type Relation = typeof Relation[keyof typeof Relation];

/**
 *
 * @export
 * @interface GetInvitationResponse
 */
export interface GetInvitationResponse {
  /**
   *
   * @type {string}
   * @memberof GetInvitationResponse
   */
  inviterName?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetInvitationResponse
   */
  inviterMemberId?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetInvitationResponse
   */
  invitationType?: AssignedEntityType;
  /**
   *
   * @type {string}
   * @memberof GetInvitationResponse
   */
  groupName?: string | null;
}

/**
 *
 * @export
 * @interface GetMemberLocationQueryResponse
 */
export interface GetMemberLocationQueryResponse {
  /**
   *
   * @type {Location}
   * @memberof GetMemberLocationQueryResponse
   */
  location?: Location | null;
  /**
   *
   * @type {Country}
   * @memberof GetMemberLocationQueryResponse
   */
  country?: Country | null;
  /**
   *
   * @type {string}
   * @memberof GetMemberLocationQueryResponse
   */
  updatedAt?: string;
}

/**
 *
 * @export
 * @interface IDiscoverableEndpoint
 */
export interface IDiscoverableEndpoint {
  /**
   *
   * @type {string}
   * @memberof IDiscoverableEndpoint
   */
  name?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof IDiscoverableEndpoint
   */
  urls?: Array<string> | null;
}

/**
 *
 * @export
 * @interface GetMembershipAddressResult
 */
export interface GetMembershipAddressResult {
  /**
   *
   * @type {RequestAddress}
   * @memberof GetMembershipAddressResult
   */
  residentialAddress?: RequestAddress | null;
  /**
   *
   * @type {RequestAddress}
   * @memberof GetMembershipAddressResult
   */
  billingAddress?: RequestAddress | null;
}

/**
 *
 * @export
 * @interface GetMembershipPaymentMethodCard
 */
export interface GetMembershipPaymentMethodCard {
  /**
   *
   * @type {string}
   * @memberof GetMembershipPaymentMethodCard
   */
  brand?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetMembershipPaymentMethodCard
   */
  last4?: string | null;
  /**
   *
   * @type {GetMembershipPaymentMethodCardExpiration}
   * @memberof GetMembershipPaymentMethodCard
   */
  expiration?: GetMembershipPaymentMethodCardExpiration | null;
}

/**
 *
 * @export
 * @interface GetMembershipPaymentMethodCardExpiration
 */
export interface GetMembershipPaymentMethodCardExpiration {
  /**
   *
   * @type {number}
   * @memberof GetMembershipPaymentMethodCardExpiration
   */
  month?: number;
  /**
   *
   * @type {number}
   * @memberof GetMembershipPaymentMethodCardExpiration
   */
  year?: number;
}

/**
 *
 * @export
 * @interface GetMembershipPaymentMethodResult
 */
export interface GetMembershipPaymentMethodResult {
  /**
   *
   * @type {DateOnly}
   * @memberof GetMembershipPaymentMethodResult
   */
  nextBilling?: string;
  /**
   *
   * @type {GetMembershipPaymentMethodCard}
   * @memberof GetMembershipPaymentMethodResult
   */
  card?: GetMembershipPaymentMethodCard | null;
}

/**
 *
 * @export
 * @interface GetMembershipSummaryResult
 */
export interface GetMembershipSummaryResult {
  /**
   *
   * @type {DateOnly}
   * @memberof GetMembershipSummaryResult
   */
  expirationDate?: string;
  /**
   *
   * @type {PlanType}
   * @memberof GetMembershipSummaryResult
   */
  planType?: PlanType;
  /**
   *
   * @type {string}
   * @memberof GetMembershipSummaryResult
   */
  ownerMemberId?: string;
}

/**
 *
 * @export
 * @interface GetMyPendingCheckInRequestResponse
 */
export interface GetMyPendingCheckInRequestResponse {
  /**
   *
   * @type {Array<GetMyPendingCheckInRequestResponseItem>}
   * @memberof GetMyPendingCheckInRequestResponse
   */
  requests?: Array<GetMyPendingCheckInRequestResponseItem> | null;
}

/**
 *
 * @export
 * @interface GetMyPendingCheckInRequestResponseItem
 */
export interface GetMyPendingCheckInRequestResponseItem {
  /**
   *
   * @type {string}
   * @memberof GetMyPendingCheckInRequestResponseItem
   */
  requestId?: string;
  /**
   *
   * @type {string}
   * @memberof GetMyPendingCheckInRequestResponseItem
   */
  sendingMemberId?: string;
  /**
   *
   * @type {string}
   * @memberof GetMyPendingCheckInRequestResponseItem
   */
  requested?: string;
}

/**
 *
 * @export
 * @interface GetPersonalDataResponse
 */
export interface GetPersonalDataResponse {
  /**
   *
   * @type {string}
   * @memberof GetPersonalDataResponse
   */
  displayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetPersonalDataResponse
   */
  phoneNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetPersonalDataResponse
   */
  emailAddress?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof GetPersonalDataResponse
   */
  emailVerified?: boolean | null;
}

/**
 *
 * @export
 * @interface GroupMemberModel
 */
export interface GroupMemberModel {
  /**
   *
   * @type {string}
   * @memberof GroupMemberModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof GroupMemberModel
   */
  displayName?: string | null;
  /**
   *
   * @type {LocalizationStatus}
   * @memberof GroupMemberModel
   */
  status?: LocalizationStatus;

  /**
   *
   * @type {string}
   * @memberof GroupMemberModel
   */
  checkInDate?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof GroupMemberModel
   */
  isOwner?: boolean;

  recentLocationLastCheckDate?: string;
  checkInStatus?: CheckInStatus;
}

/**
 *
 * @export
 * @interface GroupModel
 */
export interface GroupModel {
  /**
   *
   * @type {string}
   * @memberof GroupModel
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GroupModel
   */
  name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof GroupModel
   */
  isOwner?: boolean;
  /**
   *
   * @type {Array<GroupMemberModel>}
   * @memberof GroupModel
   */
  members?: Array<GroupMemberModel> | null;
}

/**
 *
 * @export
 * @interface LeaveGroupModel
 */
export interface LeaveGroupModel {
  /**
   *
   * @type {string}
   * @memberof LeaveGroupModel
   */
  newOwnerId?: string | null;
}

/**
 *
 * @export
 * @enum {string}
 */

export const LocalizationStatus = {
  UpToDate: 'UpToDate',
  OutOfDate: 'OutOfDate',
  Unknown: 'Unknown',
} as const;

export type LocalizationStatus =
  typeof LocalizationStatus[keyof typeof LocalizationStatus];

/**
 *
 * @export
 * @interface Location
 */
export interface Location {
  /**
   *
   * @type {number}
   * @memberof Location
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof Location
   */
  longitude?: number;
}

/**
 *
 * @export
 * @interface MemberModel
 */
export interface MemberModel {
  /**
   *
   * @type {string}
   * @memberof MemberModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof MemberModel
   */
  displayName?: string | null;
  /**
   *
   * @type {LocalizationStatus}
   * @memberof MemberModel
   */
  status?: LocalizationStatus;
  /**
   *
   * @type {string}
   * @memberof MemberModel
   */
  checkInDate?: string | null;

  recentLocationLastCheckDate?: string;

  checkInStatus?: CheckInStatus;
}

/**
 *
 * @export
 * @enum {string}
 */

export const SafetyStatusType = {
  CheckedIn: 'CheckedIn',
  RequestedHelp: 'RequestedHelp',
} as const;

export type SafetyStatusType =
  typeof SafetyStatusType[keyof typeof SafetyStatusType];

/**
 *
 * @export
 * @interface CountryBriefIntelligence
 */
export interface CountryBriefIntelligence {
  /**
   *
   * @type {CountryBriefQuickFact}
   * @memberof CountryBriefIntelligence
   */
  quickFact?: CountryBriefQuickFact | null;
  /**
   *
   * @type {string}
   * @memberof CountryBriefIntelligence
   */
  embassiesAndConsulates?: string | null;
  /**
   *
   * @type {string}
   * @memberof CountryBriefIntelligence
   */
  destinationDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof CountryBriefIntelligence
   */
  visaRequirements?: string | null;
  /**
   *
   * @type {string}
   * @memberof CountryBriefIntelligence
   */
  safetyAndSecurity?: string | null;
  /**
   *
   * @type {string}
   * @memberof CountryBriefIntelligence
   */
  localLawsAndSpecialCircumstances?: string | null;
  /**
   *
   * @type {string}
   * @memberof CountryBriefIntelligence
   */
  generalSafetyInformation?: string | null;
  /**
   *
   * @type {string}
   * @memberof CountryBriefIntelligence
   */
  health?: string | null;
  /**
   *
   * @type {string}
   * @memberof CountryBriefIntelligence
   */
  travelAndTransportation?: string | null;
  /**
   *
   * @type {string}
   * @memberof CountryBriefIntelligence
   */
  lastIntelligenceUpdateDate?: string;
}
/**
 *
 * @export
 * @interface CountryBriefQuickFact
 */
export interface CountryBriefQuickFact {
  /**
   *
   * @type {string}
   * @memberof CountryBriefQuickFact
   */
  passportValidity?: string | null;
  /**
   *
   * @type {string}
   * @memberof CountryBriefQuickFact
   */
  blankPassportPage?: string | null;
  /**
   *
   * @type {string}
   * @memberof CountryBriefQuickFact
   */
  touristVisaRequired?: string | null;
  /**
   *
   * @type {string}
   * @memberof CountryBriefQuickFact
   */
  vaccinations?: string | null;
  /**
   *
   * @type {string}
   * @memberof CountryBriefQuickFact
   */
  currencyRestrictionsForEntry?: string | null;
  /**
   *
   * @type {string}
   * @memberof CountryBriefQuickFact
   */
  currencyRestrictionsForExit?: string | null;
}
/**
 *
 * @export
 * @interface NotificationModel
 */
export interface NotificationModel {
  /**
   *
   * @type {number}
   * @memberof NotificationModel
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof NotificationModel
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof NotificationModel
   */
  body?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof NotificationModel
   */
  isRead?: boolean;
  /**
   *
   * @type {string}
   * @memberof NotificationModel
   */
  relatedMemberId?: string | null;
  /**
   *
   * @type {string}
   * @memberof NotificationModel
   */
  date?: string;
  /**
   *
   * @type {NotificationType}
   * @memberof NotificationModel
   */
  type?: NotificationType;
  /**
   *
   * @type {SafetyStatusType}
   * @memberof NotificationModel
   */
  relatedMemberSafetyStatus?: SafetyStatusType | null;
  /**
   *
   * @type {number}
   * @memberof NotificationModel
   */
  alertRiskLevel?: number | null;
}

/**
 *
 * @export
 * @enum {string}
 */

export const NotificationType = {
  FamilyMemberJoined: 'FamilyMemberJoined',
  Simple: 'Simple',
  CheckInRequest: 'CheckInRequest',
  GroupDeleted: 'GroupDeleted',
  MemberRemovedFromGroup: 'MemberRemovedFromGroup',
  MemberAddedToGroup: 'MemberAddedToGroup',
  EmergencyContactNotification: 'EmergencyContactNotification',
  TravelShieldEmergencyOperatorNotification:
    'TravelShieldEmergencyOperatorNotification',
  CheckInStatusUpdate: 'CheckInStatusUpdate',
  MembershipActivated: 'MembershipActivated',
  EmailConfirmation: 'EmailConfirmation',
  RiskAlert: 'RiskAlert',
  MemberAddedToFriends: 'MemberAddedToFriends',
} as const;

export type NotificationType =
  typeof NotificationType[keyof typeof NotificationType];

/**
 *
 * @export
 * @interface PassGroupOwnershipModel
 */
export interface PassGroupOwnershipModel {
  /**
   *
   * @type {string}
   * @memberof PassGroupOwnershipModel
   */
  newOwnerId?: string;
}

/**
 *
 * @export
 * @interface RequestFriendCheckInResponse
 */
export interface RequestFriendCheckInResponse {
  /**
   *
   * @type {string}
   * @memberof RequestFriendCheckInResponse
   */
  checkInId?: string;
}

/**
 *
 * @export
 * @interface RequestGroupCheckInResponse
 */
export interface RequestGroupCheckInResponse {
  /**
   *
   * @type {Array<RequestGroupCheckInResponseItem>}
   * @memberof RequestGroupCheckInResponse
   */
  checkIns?: Array<RequestGroupCheckInResponseItem> | null;
}

/**
 *
 * @export
 * @interface RequestGroupCheckInResponseItem
 */
export interface RequestGroupCheckInResponseItem {
  /**
   *
   * @type {string}
   * @memberof RequestGroupCheckInResponseItem
   */
  checkInId?: string;
  /**
   *
   * @type {string}
   * @memberof RequestGroupCheckInResponseItem
   */
  memberId?: string;
}

/**
 *
 * @export
 * @interface RespondToCheckInRequestCommand
 */
export interface RespondToCheckInRequestCommand {
  /**
   *
   * @type {SafetyStatusType}
   * @memberof RespondToCheckInRequestCommand
   */
  status?: SafetyStatusType;
}

/**
 *
 * @export
 * @interface RiskAlertModel
 */
export interface RiskAlertModel {
  /**
   *
   * @type {string}
   * @memberof RiskAlertModel
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof RiskAlertModel
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof RiskAlertModel
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof RiskAlertModel
   */
  countryName?: string | null;
  /**
   *
   * @type {string}
   * @memberof RiskAlertModel
   */
  countryCode?: string | null;
  /**
   *
   * @type {number}
   * @memberof RiskAlertModel
   */
  riskLevel?: number;
}

/**
 *
 * @export
 * @interface SettingsModel
 */
export interface SettingsModel {
  /**
   *
   * @type {number}
   * @memberof SettingsModel
   */
  minimalAlertRiskLevel?: number;
  /**
   *
   * @type {boolean}
   * @memberof SettingsModel
   */
  currentCountryEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SettingsModel
   */
  allSubscribedCountriesEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SettingsModel
   */
  familyMembersLocationEnabled?: boolean;
}

/**
 *
 * @export
 * @interface TravelTipModel
 */
export interface TravelTipModel {
  /**
   *
   * @type {string}
   * @memberof TravelTipModel
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof TravelTipModel
   */
  description?: string | null;
}

/**
 *
 * @export
 * @interface UpdateGroupModel
 */
export interface UpdateGroupModel {
  /**
   *
   * @type {string}
   * @memberof UpdateGroupModel
   */
  name?: string | null;
}

/**
 * CountriesApi - axios parameter creator
 * @export
 */
export const CountriesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCodeActionsSubscribePost: async (
      code: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('countriesCodeActionsSubscribePost', 'code', code);
      const localVarPath = `/countries/{code}/actions/subscribe`.replace(
        `{${'code'}}`,
        encodeURIComponent(String(code)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCodeActionsUnsubscribePost: async (
      code: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('countriesCodeActionsUnsubscribePost', 'code', code);
      const localVarPath = `/countries/{code}/actions/unsubscribe`.replace(
        `{${'code'}}`,
        encodeURIComponent(String(code)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCodeBriefGet: async (
      code: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('countriesCodeBriefGet', 'code', code);
      const localVarPath = `/countries/{code}/brief`.replace(
        `{${'code'}}`,
        encodeURIComponent(String(code)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCodeRiskAlertsGet: async (
      code: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('countriesCodeRiskAlertsGet', 'code', code);
      const localVarPath = `/countries/{code}/risk-alerts`.replace(
        `{${'code'}}`,
        encodeURIComponent(String(code)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/countries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesSubscribedCountriesGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/countries/subscribed-countries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CountriesApi - functional programming interface
 * @export
 */
export const CountriesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CountriesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countriesCodeActionsSubscribePost(
      code: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.countriesCodeActionsSubscribePost(
          code,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countriesCodeActionsUnsubscribePost(
      code: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.countriesCodeActionsUnsubscribePost(
          code,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countriesCodeBriefGet(
      code: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CountryBriefIntelligence>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.countriesCodeBriefGet(code, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countriesCodeRiskAlertsGet(
      code: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<RiskAlertModel>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.countriesCodeRiskAlertsGet(
          code,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countriesGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<CountryModel>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countriesGet(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countriesSubscribedCountriesGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.countriesSubscribedCountriesGet(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CountriesApi - factory interface
 * @export
 */
export const CountriesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CountriesApiFp(configuration);
  return {
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCodeActionsSubscribePost(
      code: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .countriesCodeActionsSubscribePost(code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCodeActionsUnsubscribePost(
      code: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .countriesCodeActionsUnsubscribePost(code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCodeBriefGet(
      code: string,
      options?: any,
    ): AxiosPromise<CountryBriefIntelligence> {
      return localVarFp
        .countriesCodeBriefGet(code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCodeRiskAlertsGet(
      code: string,
      options?: any,
    ): AxiosPromise<Array<RiskAlertModel>> {
      return localVarFp
        .countriesCodeRiskAlertsGet(code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesGet(options?: any): AxiosPromise<Array<CountryModel>> {
      return localVarFp
        .countriesGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesSubscribedCountriesGet(
      options?: any,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .countriesSubscribedCountriesGet(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CountriesApi - object-oriented interface
 * @export
 * @class CountriesApi
 * @extends {BaseAPI}
 */
export class CountriesApi extends BaseAPI {
  /**
   *
   * @param {string} code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CountriesApi
   */
  public countriesCodeActionsSubscribePost(
    code: string,
    options?: AxiosRequestConfig,
  ) {
    return CountriesApiFp(undefined)
      .countriesCodeActionsSubscribePost(code, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CountriesApi
   */
  public countriesCodeActionsUnsubscribePost(
    code: string,
    options?: AxiosRequestConfig,
  ) {
    return CountriesApiFp(undefined)
      .countriesCodeActionsUnsubscribePost(code, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CountriesApi
   */
  public countriesCodeBriefGet(code: string, options?: AxiosRequestConfig) {
    return CountriesApiFp(undefined)
      .countriesCodeBriefGet(code, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CountriesApi
   */
  public countriesCodeRiskAlertsGet(
    code: string,
    options?: AxiosRequestConfig,
  ) {
    return CountriesApiFp(undefined)
      .countriesCodeRiskAlertsGet(code, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CountriesApi
   */
  public countriesGet(options?: AxiosRequestConfig) {
    return CountriesApiFp(undefined)
      .countriesGet(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CountriesApi
   */
  public countriesSubscribedCountriesGet(options?: AxiosRequestConfig) {
    return CountriesApiFp(undefined)
      .countriesSubscribedCountriesGet(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }
}

/**
 * EmergencyContactsApi - axios parameter creator
 * @export
 */
export const EmergencyContactsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} emergencyContactId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emergencyContactsEmergencyContactIdDelete: async (
      emergencyContactId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'emergencyContactId' is not null or undefined
      assertParamExists(
        'emergencyContactsEmergencyContactIdDelete',
        'emergencyContactId',
        emergencyContactId,
      );
      const localVarPath = `/emergency-contacts/{emergencyContactId}`.replace(
        `{${'emergencyContactId'}}`,
        encodeURIComponent(String(emergencyContactId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} emergencyContactId
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emergencyContactsEmergencyContactIdPut: async (
      emergencyContactId: string,
      uNKNOWNBASETYPE?: EmergencyContactModel,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'emergencyContactId' is not null or undefined
      assertParamExists(
        'emergencyContactsEmergencyContactIdPut',
        'emergencyContactId',
        emergencyContactId,
      );
      const localVarPath = `/emergency-contacts/{emergencyContactId}`.replace(
        `{${'emergencyContactId'}}`,
        encodeURIComponent(String(emergencyContactId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        uNKNOWNBASETYPE,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emergencyContactsGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/emergency-contacts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emergencyContactsPost: async (
      uNKNOWNBASETYPE?: EmergencyContactModel,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/emergency-contacts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        uNKNOWNBASETYPE,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EmergencyContactsApi - functional programming interface
 * @export
 */
export const EmergencyContactsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    EmergencyContactsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} emergencyContactId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async emergencyContactsEmergencyContactIdDelete(
      emergencyContactId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.emergencyContactsEmergencyContactIdDelete(
          emergencyContactId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} emergencyContactId
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async emergencyContactsEmergencyContactIdPut(
      emergencyContactId: string,
      uNKNOWNBASETYPE?: EmergencyContactModel,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.emergencyContactsEmergencyContactIdPut(
          emergencyContactId,
          uNKNOWNBASETYPE,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async emergencyContactsGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<EmergencyContactModel>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.emergencyContactsGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async emergencyContactsPost(
      uNKNOWNBASETYPE?: EmergencyContactModel,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.emergencyContactsPost(
          uNKNOWNBASETYPE,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * EmergencyContactsApi - factory interface
 * @export
 */
export const EmergencyContactsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EmergencyContactsApiFp(configuration);
  return {
    /**
     *
     * @param {string} emergencyContactId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emergencyContactsEmergencyContactIdDelete(
      emergencyContactId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .emergencyContactsEmergencyContactIdDelete(emergencyContactId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} emergencyContactId
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emergencyContactsEmergencyContactIdPut(
      emergencyContactId: string,
      uNKNOWNBASETYPE?: EmergencyContactModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .emergencyContactsEmergencyContactIdPut(
          emergencyContactId,
          uNKNOWNBASETYPE,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emergencyContactsGet(
      options?: any,
    ): AxiosPromise<Array<EmergencyContactModel>> {
      return localVarFp
        .emergencyContactsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emergencyContactsPost(
      uNKNOWNBASETYPE?: EmergencyContactModel,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .emergencyContactsPost(uNKNOWNBASETYPE, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * EmergencyContactsApi - object-oriented interface
 * @export
 * @class EmergencyContactsApi
 * @extends {BaseAPI}
 */
export class EmergencyContactsApi extends BaseAPI {
  /**
   *
   * @param {string} emergencyContactId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmergencyContactsApi
   */
  public emergencyContactsEmergencyContactIdDelete(
    emergencyContactId: string,
    options?: AxiosRequestConfig,
  ) {
    return EmergencyContactsApiFp(undefined)
      .emergencyContactsEmergencyContactIdDelete(emergencyContactId, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} emergencyContactId
   * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmergencyContactsApi
   */
  public emergencyContactsEmergencyContactIdPut(
    emergencyContactId: string,
    uNKNOWNBASETYPE?: EmergencyContactModel,
    options?: AxiosRequestConfig,
  ) {
    return EmergencyContactsApiFp(undefined)
      .emergencyContactsEmergencyContactIdPut(
        emergencyContactId,
        uNKNOWNBASETYPE,
        options,
      )
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmergencyContactsApi
   */
  public emergencyContactsGet(options?: AxiosRequestConfig) {
    return EmergencyContactsApiFp(undefined)
      .emergencyContactsGet(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmergencyContactsApi
   */
  public emergencyContactsPost(
    uNKNOWNBASETYPE?: EmergencyContactModel,
    options?: AxiosRequestConfig,
  ) {
    return EmergencyContactsApiFp(undefined)
      .emergencyContactsPost(uNKNOWNBASETYPE, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }
}

/**
 * FriendsApi - axios parameter creator
 * @export
 */
export const FriendsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    friendsAccessCodeActionsRefreshPost: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/friends/access-code/actions/refresh`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    friendsAccessCodeGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/friends/access-code`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} friendId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    friendsFriendIdActionsRequestCheckInPost: async (
      friendId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'friendId' is not null or undefined
      assertParamExists(
        'friendsFriendIdActionsRequestCheckInPost',
        'friendId',
        friendId,
      );
      const localVarPath =
        `/friends/{friendId}/actions/request-check-in`.replace(
          `{${'friendId'}}`,
          encodeURIComponent(String(friendId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} friendId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    friendsFriendIdDelete: async (
      friendId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'friendId' is not null or undefined
      assertParamExists('friendsFriendIdDelete', 'friendId', friendId);
      const localVarPath = `/friends/{friendId}`.replace(
        `{${'friendId'}}`,
        encodeURIComponent(String(friendId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} friendId
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    friendsFriendIdSettingsEnableCountryChangedNotificationsPut: async (
      friendId: string,
      body?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'friendId' is not null or undefined
      assertParamExists(
        'friendsFriendIdSettingsEnableCountryChangedNotificationsPut',
        'friendId',
        friendId,
      );
      const localVarPath =
        `/friends/{friendId}/settings/enable-country-changed-notifications`.replace(
          `{${'friendId'}}`,
          encodeURIComponent(String(friendId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} friendId
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    friendsFriendIdSettingsEnableMyLocationSharingPut: async (
      friendId: string,
      body?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'friendId' is not null or undefined
      assertParamExists(
        'friendsFriendIdSettingsEnableMyLocationSharingPut',
        'friendId',
        friendId,
      );
      const localVarPath =
        `/friends/{friendId}/settings/enable-my-location-sharing`.replace(
          `{${'friendId'}}`,
          encodeURIComponent(String(friendId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    /**
     *
     * @param {number} [skip]
     * @param {number} [take]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    friendsGet: async (
      skip?: number,
      take?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/friends`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FriendsApi - functional programming interface
 * @export
 */
export const FriendsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FriendsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async friendsAccessCodeActionsRefreshPost(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AccessCodeModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.friendsAccessCodeActionsRefreshPost(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async friendsAccessCodeGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AccessCodeModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.friendsAccessCodeGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} friendId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async friendsFriendIdActionsRequestCheckInPost(
      friendId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RequestFriendCheckInResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.friendsFriendIdActionsRequestCheckInPost(
          friendId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} friendId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async friendsFriendIdDelete(
      friendId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.friendsFriendIdDelete(
          friendId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} friendId
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async friendsFriendIdSettingsEnableCountryChangedNotificationsPut(
      friendId: string,
      body?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.friendsFriendIdSettingsEnableCountryChangedNotificationsPut(
          friendId,
          body,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} friendId
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async friendsFriendIdSettingsEnableMyLocationSharingPut(
      friendId: string,
      body?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.friendsFriendIdSettingsEnableMyLocationSharingPut(
          friendId,
          body,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async friendsGet(
      skip?: number,
      take?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<MemberModel>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.friendsGet(
        skip,
        take,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * FriendsApi - factory interface
 * @export
 */
export const FriendsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FriendsApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    friendsAccessCodeActionsRefreshPost(
      options?: any,
    ): AxiosPromise<AccessCodeModel> {
      return localVarFp
        .friendsAccessCodeActionsRefreshPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    friendsAccessCodeGet(options?: any): AxiosPromise<AccessCodeModel> {
      return localVarFp
        .friendsAccessCodeGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} friendId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    friendsFriendIdActionsRequestCheckInPost(
      friendId: string,
      options?: any,
    ): AxiosPromise<RequestFriendCheckInResponse> {
      return localVarFp
        .friendsFriendIdActionsRequestCheckInPost(friendId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} friendId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    friendsFriendIdDelete(friendId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .friendsFriendIdDelete(friendId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} friendId
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    friendsFriendIdSettingsEnableCountryChangedNotificationsPut(
      friendId: string,
      body?: boolean,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .friendsFriendIdSettingsEnableCountryChangedNotificationsPut(
          friendId,
          body,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} friendId
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    friendsFriendIdSettingsEnableMyLocationSharingPut(
      friendId: string,
      body?: boolean,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .friendsFriendIdSettingsEnableMyLocationSharingPut(
          friendId,
          body,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [skip]
     * @param {number} [take]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    friendsGet(
      skip?: number,
      take?: number,
      options?: any,
    ): AxiosPromise<Array<MemberModel>> {
      return localVarFp
        .friendsGet(skip, take, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * FriendsApi - object-oriented interface
 * @export
 * @class FriendsApi
 * @extends {BaseAPI}
 */
export class FriendsApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FriendsApi
   */
  public friendsAccessCodeActionsRefreshPost(options?: AxiosRequestConfig) {
    return FriendsApiFp(undefined)
      .friendsAccessCodeActionsRefreshPost(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FriendsApi
   */
  public friendsAccessCodeGet(options?: AxiosRequestConfig) {
    return FriendsApiFp(undefined)
      .friendsAccessCodeGet(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} friendId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FriendsApi
   */
  public friendsFriendIdActionsRequestCheckInPost(
    friendId: string,
    options?: AxiosRequestConfig,
  ) {
    return FriendsApiFp(undefined)
      .friendsFriendIdActionsRequestCheckInPost(friendId, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} friendId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FriendsApi
   */
  public friendsFriendIdDelete(friendId: string, options?: AxiosRequestConfig) {
    return FriendsApiFp(undefined)
      .friendsFriendIdDelete(friendId, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} friendId
   * @param {boolean} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FriendsApi
   */
  public friendsFriendIdSettingsEnableCountryChangedNotificationsPut(
    friendId: string,
    body?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return FriendsApiFp(undefined)
      .friendsFriendIdSettingsEnableCountryChangedNotificationsPut(
        friendId,
        body,
        options,
      )
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} friendId
   * @param {boolean} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FriendsApi
   */
  public friendsFriendIdSettingsEnableMyLocationSharingPut(
    friendId: string,
    body?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return FriendsApiFp(undefined)
      .friendsFriendIdSettingsEnableMyLocationSharingPut(
        friendId,
        body,
        options,
      )
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {number} [skip]
   * @param {number} [take]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FriendsApi
   */
  public friendsGet(
    skip?: number,
    take?: number,
    options?: AxiosRequestConfig,
  ) {
    return FriendsApiFp(undefined)
      .friendsGet(skip, take, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }
}

/**
 * DiscoveryApi - axios parameter creator
 * @export
 */
export const DiscoveryApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discoveryEndpointsGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/.discovery/endpoints`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DiscoveryApi - functional programming interface
 * @export
 */
export const DiscoveryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DiscoveryApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async discoveryEndpointsGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<IDiscoverableEndpoint>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.discoveryEndpointsGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DiscoveryApi - factory interface
 * @export
 */
export const DiscoveryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DiscoveryApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discoveryEndpointsGet(
      options?: any,
    ): AxiosPromise<Array<IDiscoverableEndpoint>> {
      return localVarFp
        .discoveryEndpointsGet(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DiscoveryApi - object-oriented interface
 * @export
 * @class DiscoveryApi
 * @extends {BaseAPI}
 */
export class DiscoveryApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DiscoveryApi
   */
  public discoveryEndpointsGet(options?: AxiosRequestConfig) {
    return DiscoveryApiFp(undefined)
      .discoveryEndpointsGet(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }
}

/**
 * GroupsApi - axios parameter creator
 * @export
 */
export const GroupsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/groups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdAccessCodeActionsRefreshPost: async (
      groupId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists(
        'groupsGroupIdAccessCodeActionsRefreshPost',
        'groupId',
        groupId,
      );
      const localVarPath =
        `/groups/{groupId}/access-code/actions/refresh`.replace(
          `{${'groupId'}}`,
          encodeURIComponent(String(groupId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdAccessCodeGet: async (
      groupId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('groupsGroupIdAccessCodeGet', 'groupId', groupId);
      const localVarPath = `/groups/{groupId}/access-code`.replace(
        `{${'groupId'}}`,
        encodeURIComponent(String(groupId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} groupId
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdActionsLeavePost: async (
      groupId: string,
      uNKNOWNBASETYPE?: LeaveGroupModel,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('groupsGroupIdActionsLeavePost', 'groupId', groupId);
      const localVarPath = `/groups/{groupId}/actions/leave`.replace(
        `{${'groupId'}}`,
        encodeURIComponent(String(groupId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        uNKNOWNBASETYPE,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} groupId
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdActionsPassOwnershipPost: async (
      groupId: string,
      uNKNOWNBASETYPE?: LeaveGroupModel,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists(
        'groupsGroupIdActionsPassOwnershipPost',
        'groupId',
        groupId,
      );
      const localVarPath = `/groups/{groupId}/actions/pass-ownership`.replace(
        `{${'groupId'}}`,
        encodeURIComponent(String(groupId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        uNKNOWNBASETYPE,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdActionsRequestCheckInPost: async (
      groupId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists(
        'groupsGroupIdActionsRequestCheckInPost',
        'groupId',
        groupId,
      );
      const localVarPath = `/groups/{groupId}/actions/request-check-in`.replace(
        `{${'groupId'}}`,
        encodeURIComponent(String(groupId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdDelete: async (
      groupId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('groupsGroupIdDelete', 'groupId', groupId);
      const localVarPath = `/groups/{groupId}`.replace(
        `{${'groupId'}}`,
        encodeURIComponent(String(groupId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} groupId
     * @param {string} memberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdMembersMemberIdDelete: async (
      groupId: string,
      memberId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists(
        'groupsGroupIdMembersMemberIdDelete',
        'groupId',
        groupId,
      );
      // verify required parameter 'memberId' is not null or undefined
      assertParamExists(
        'groupsGroupIdMembersMemberIdDelete',
        'memberId',
        memberId,
      );
      const localVarPath = `/groups/{groupId}/members/{memberId}`
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'memberId'}}`, encodeURIComponent(String(memberId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} groupId
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdPut: async (
      groupId: string,
      uNKNOWNBASETYPE?: UpdateGroupModel,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('groupsGroupIdPut', 'groupId', groupId);
      const localVarPath = `/groups/{groupId}`.replace(
        `{${'groupId'}}`,
        encodeURIComponent(String(groupId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        uNKNOWNBASETYPE,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} groupId
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdSettingsEnableCountryChangedNotificationsPut: async (
      groupId: string,
      body?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists(
        'groupsGroupIdSettingsEnableCountryChangedNotificationsPut',
        'groupId',
        groupId,
      );
      const localVarPath =
        `/groups/{groupId}/settings/enable-country-changed-notifications`.replace(
          `{${'groupId'}}`,
          encodeURIComponent(String(groupId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} groupId
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdSettingsEnableMyLocationSharingPut: async (
      groupId: string,
      body?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists(
        'groupsGroupIdSettingsEnableMyLocationSharingPut',
        'groupId',
        groupId,
      );
      const localVarPath =
        `/groups/{groupId}/settings/enable-my-location-sharing`.replace(
          `{${'groupId'}}`,
          encodeURIComponent(String(groupId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsPost: async (
      uNKNOWNBASETYPE?: UpdateGroupModel,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/groups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        uNKNOWNBASETYPE,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GroupsApi - functional programming interface
 * @export
 */
export const GroupsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GroupsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async groupsGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GroupModel>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.groupsGet(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async groupsGroupIdAccessCodeActionsRefreshPost(
      groupId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AccessCodeModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.groupsGroupIdAccessCodeActionsRefreshPost(
          groupId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async groupsGroupIdAccessCodeGet(
      groupId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AccessCodeModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.groupsGroupIdAccessCodeGet(
          groupId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} groupId
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async groupsGroupIdActionsLeavePost(
      groupId: string,
      uNKNOWNBASETYPE?: LeaveGroupModel,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.groupsGroupIdActionsLeavePost(
          groupId,
          uNKNOWNBASETYPE,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} groupId
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async groupsGroupIdActionsPassOwnershipPost(
      groupId: string,
      uNKNOWNBASETYPE?: LeaveGroupModel,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.groupsGroupIdActionsPassOwnershipPost(
          groupId,
          uNKNOWNBASETYPE,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async groupsGroupIdActionsRequestCheckInPost(
      groupId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RequestGroupCheckInResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.groupsGroupIdActionsRequestCheckInPost(
          groupId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async groupsGroupIdDelete(
      groupId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.groupsGroupIdDelete(groupId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} groupId
     * @param {string} memberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async groupsGroupIdMembersMemberIdDelete(
      groupId: string,
      memberId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.groupsGroupIdMembersMemberIdDelete(
          groupId,
          memberId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} groupId
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async groupsGroupIdPut(
      groupId: string,
      uNKNOWNBASETYPE?: CreateGroupCommand,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.groupsGroupIdPut(
          groupId,
          uNKNOWNBASETYPE,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} groupId
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async groupsGroupIdSettingsEnableCountryChangedNotificationsPut(
      groupId: string,
      body?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.groupsGroupIdSettingsEnableCountryChangedNotificationsPut(
          groupId,
          body,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} groupId
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async groupsGroupIdSettingsEnableMyLocationSharingPut(
      groupId: string,
      body?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.groupsGroupIdSettingsEnableMyLocationSharingPut(
          groupId,
          body,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async groupsPost(
      uNKNOWNBASETYPE?: CreateGroupCommand,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.groupsPost(
        uNKNOWNBASETYPE,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * GroupsApi - factory interface
 * @export
 */
export const GroupsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GroupsApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGet(options?: any): AxiosPromise<Array<GroupModel>> {
      return localVarFp
        .groupsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdAccessCodeActionsRefreshPost(
      groupId: string,
      options?: any,
    ): AxiosPromise<AccessCodeModel> {
      return localVarFp
        .groupsGroupIdAccessCodeActionsRefreshPost(groupId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdAccessCodeGet(
      groupId: string,
      options?: any,
    ): AxiosPromise<AccessCodeModel> {
      return localVarFp
        .groupsGroupIdAccessCodeGet(groupId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} groupId
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdActionsLeavePost(
      groupId: string,
      uNKNOWNBASETYPE?: LeaveGroupModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .groupsGroupIdActionsLeavePost(groupId, uNKNOWNBASETYPE, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} groupId
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdActionsPassOwnershipPost(
      groupId: string,
      uNKNOWNBASETYPE?: LeaveGroupModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .groupsGroupIdActionsPassOwnershipPost(
          groupId,
          uNKNOWNBASETYPE,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdActionsRequestCheckInPost(
      groupId: string,
      options?: any,
    ): AxiosPromise<RequestGroupCheckInResponse> {
      return localVarFp
        .groupsGroupIdActionsRequestCheckInPost(groupId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdDelete(groupId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .groupsGroupIdDelete(groupId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} groupId
     * @param {string} memberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdMembersMemberIdDelete(
      groupId: string,
      memberId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .groupsGroupIdMembersMemberIdDelete(groupId, memberId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} groupId
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdPut(
      groupId: string,
      uNKNOWNBASETYPE?: CreateGroupCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .groupsGroupIdPut(groupId, uNKNOWNBASETYPE, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} groupId
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdSettingsEnableCountryChangedNotificationsPut(
      groupId: string,
      body?: boolean,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .groupsGroupIdSettingsEnableCountryChangedNotificationsPut(
          groupId,
          body,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} groupId
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsGroupIdSettingsEnableMyLocationSharingPut(
      groupId: string,
      body?: boolean,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .groupsGroupIdSettingsEnableMyLocationSharingPut(groupId, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groupsPost(
      uNKNOWNBASETYPE?: CreateGroupCommand,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .groupsPost(uNKNOWNBASETYPE, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * GroupsApi - object-oriented interface
 * @export
 * @class GroupsApi
 * @extends {BaseAPI}
 */
export class GroupsApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupsApi
   */
  public groupsGet(options?: AxiosRequestConfig) {
    return GroupsApiFp(undefined)
      .groupsGet(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} groupId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupsApi
   */
  public groupsGroupIdAccessCodeActionsRefreshPost(
    groupId: string,
    options?: AxiosRequestConfig,
  ) {
    return GroupsApiFp(undefined)
      .groupsGroupIdAccessCodeActionsRefreshPost(groupId, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} groupId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupsApi
   */
  public groupsGroupIdAccessCodeGet(
    groupId: string,
    options?: AxiosRequestConfig,
  ) {
    return GroupsApiFp(undefined)
      .groupsGroupIdAccessCodeGet(groupId, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} groupId
   * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupsApi
   */
  public groupsGroupIdActionsLeavePost(
    groupId: string,
    uNKNOWNBASETYPE?: LeaveGroupModel,
    options?: AxiosRequestConfig,
  ) {
    return GroupsApiFp(undefined)
      .groupsGroupIdActionsLeavePost(groupId, uNKNOWNBASETYPE, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} groupId
   * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupsApi
   */
  public groupsGroupIdActionsPassOwnershipPost(
    groupId: string,
    uNKNOWNBASETYPE?: LeaveGroupModel,
    options?: AxiosRequestConfig,
  ) {
    return GroupsApiFp(undefined)
      .groupsGroupIdActionsPassOwnershipPost(groupId, uNKNOWNBASETYPE, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} groupId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupsApi
   */
  public groupsGroupIdActionsRequestCheckInPost(
    groupId: string,
    options?: AxiosRequestConfig,
  ) {
    return GroupsApiFp(undefined)
      .groupsGroupIdActionsRequestCheckInPost(groupId, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} groupId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupsApi
   */
  public groupsGroupIdDelete(groupId: string, options?: AxiosRequestConfig) {
    return GroupsApiFp(undefined)
      .groupsGroupIdDelete(groupId, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} groupId
   * @param {string} memberId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupsApi
   */
  public groupsGroupIdMembersMemberIdDelete(
    groupId: string,
    memberId: string,
    options?: AxiosRequestConfig,
  ) {
    return GroupsApiFp(undefined)
      .groupsGroupIdMembersMemberIdDelete(groupId, memberId, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} groupId
   * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupsApi
   */
  public groupsGroupIdPut(
    groupId: string,
    uNKNOWNBASETYPE?: CreateGroupCommand,
    options?: AxiosRequestConfig,
  ) {
    return GroupsApiFp(undefined)
      .groupsGroupIdPut(groupId, uNKNOWNBASETYPE, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} groupId
   * @param {boolean} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupsApi
   */
  public groupsGroupIdSettingsEnableCountryChangedNotificationsPut(
    groupId: string,
    body?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return GroupsApiFp(undefined)
      .groupsGroupIdSettingsEnableCountryChangedNotificationsPut(
        groupId,
        body,
        options,
      )
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} groupId
   * @param {boolean} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupsApi
   */
  public groupsGroupIdSettingsEnableMyLocationSharingPut(
    groupId: string,
    body?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return GroupsApiFp(undefined)
      .groupsGroupIdSettingsEnableMyLocationSharingPut(groupId, body, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupsApi
   */
  public groupsPost(
    uNKNOWNBASETYPE?: CreateGroupCommand,
    options?: AxiosRequestConfig,
  ) {
    return GroupsApiFp(undefined)
      .groupsPost(uNKNOWNBASETYPE, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }
}

/**
 * HomeApi - axios parameter creator
 * @export
 */
export const HomeApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rootGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rootHead: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'HEAD',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HomeApi - functional programming interface
 * @export
 */
export const HomeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HomeApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rootGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rootGet(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rootHead(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rootHead(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * HomeApi - factory interface
 * @export
 */
export const HomeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HomeApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rootGet(options?: any): AxiosPromise<string> {
      return localVarFp
        .rootGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rootHead(options?: any): AxiosPromise<void> {
      return localVarFp
        .rootHead(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * HomeApi - object-oriented interface
 * @export
 * @class HomeApi
 * @extends {BaseAPI}
 */
export class HomeApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HomeApi
   */
  public rootGet(options?: AxiosRequestConfig) {
    return HomeApiFp(undefined)
      .rootGet(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HomeApi
   */
  public rootHead(options?: AxiosRequestConfig) {
    return HomeApiFp(undefined)
      .rootHead(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }
}

/**
 * InvitationsApi - axios parameter creator
 * @export
 */
export const InvitationsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} accessCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    invitationsAccessCodeActionsAcceptInvitationPost: async (
      accessCode: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessCode' is not null or undefined
      assertParamExists(
        'invitationsAccessCodeActionsAcceptInvitationPost',
        'accessCode',
        accessCode,
      );
      const localVarPath =
        `/invitations/{accessCode}/actions/accept-invitation`.replace(
          `{${'accessCode'}}`,
          encodeURIComponent(String(accessCode)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} accessCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    invitationsAccessCodeGet: async (
      accessCode: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessCode' is not null or undefined
      assertParamExists('invitationsAccessCodeGet', 'accessCode', accessCode);
      const localVarPath = `/invitations/{accessCode}`.replace(
        `{${'accessCode'}}`,
        encodeURIComponent(String(accessCode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MembershipApi - axios parameter creator
 * @export
 */
export const MembershipApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsActionsValidateAddressPost: async (
      address?: ValidateAddressQuery,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/memberships/actions/validate-address`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        address,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsActionsValidateDateOfBirthPost: async (
      data?: ValidateDateOfBirthQuery,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/memberships/actions/validate-date-of-birth`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        data,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsCurrentActionsUpgradePut: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/memberships/current/actions/upgrade`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsCurrentAddressGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/memberships/current/address`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },

    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsCurrentAddressPut: async (
      uNKNOWNBASETYPE?: UpdateMembershipAddressCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/memberships/current/address`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        uNKNOWNBASETYPE,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsCurrentCardGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/memberships/current/card`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        responseType: 'blob',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },

    membershipsCurrentFamilyMembersPost: async (
      member?: FamilyMemberModel,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/memberships/current/family-members`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        member,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsCurrentFamilyMembersGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/memberships/current/family-members`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} familyMemberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsCurrentFamilyMembersFamilyMemberIdAccessCodeActionsRefreshPost:
      async (
        familyMemberId: string,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'familyMemberId' is not null or undefined
        assertParamExists(
          'membershipsCurrentFamilyMembersFamilyMemberIdAccessCodeActionsRefreshPost',
          'familyMemberId',
          familyMemberId,
        );
        const localVarPath =
          `/memberships/current/family-members/{familyMemberId}/access-code/actions/refresh`.replace(
            `{${'familyMemberId'}}`,
            encodeURIComponent(String(familyMemberId)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication Bearer required
        // oauth required
        await setOAuthToObject(
          localVarHeaderParameter,
          'Bearer',
          [],
          configuration,
        );

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @param {string} familyMemberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsCurrentFamilyMembersFamilyMemberIdDelete: async (
      familyMemberId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'familyMemberId' is not null or undefined
      assertParamExists(
        'membershipsCurrentFamilyMembersFamilyMemberIdDelete',
        'familyMemberId',
        familyMemberId,
      );
      const localVarPath =
        `/memberships/current/family-members/{familyMemberId}`.replace(
          `{${'familyMemberId'}}`,
          encodeURIComponent(String(familyMemberId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} familyMemberId
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsCurrentFamilyMembersFamilyMemberIdPut: async (
      familyMemberId: string,
      member?: FamilyMemberModel,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'familyMemberId' is not null or undefined
      assertParamExists(
        'membershipsCurrentFamilyMembersFamilyMemberIdPut',
        'familyMemberId',
        familyMemberId,
      );
      const localVarPath =
        `/memberships/current/family-members/{familyMemberId}`.replace(
          `{${'familyMemberId'}}`,
          encodeURIComponent(String(familyMemberId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        member,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */

    membershipsCurrentPaymentMethodGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/memberships/current/payment-method`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsCurrentSummaryGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/memberships/current/summary`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsPost: async (
      data?: CreateMembershipCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/memberships`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        data,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MembershipApi - functional programming interface
 * @export
 */
export const MembershipApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    MembershipApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membershipsCurrentCardGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membershipsCurrentCardGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membershipsActionsValidateAddressPost(
      address?: ValidateAddressQuery,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membershipsActionsValidateAddressPost(
          address,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membershipsActionsValidateDateOfBirthPost(
      data?: ValidateDateOfBirthQuery,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membershipsActionsValidateDateOfBirthPost(
          data,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membershipsCurrentActionsUpgradePut(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BuyMembershipResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membershipsCurrentActionsUpgradePut(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membershipsCurrentAddressGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetMembershipAddressResult>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membershipsCurrentAddressGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },

    /*
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membershipsCurrentAddressPut(
      uNKNOWNBASETYPE?: UpdateMembershipAddressCommand,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membershipsCurrentAddressPut(
          uNKNOWNBASETYPE,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },

    /**
     *
     * @param {string} familyMemberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membershipsCurrentFamilyMembersFamilyMemberIdAccessCodeActionsRefreshPost(
      familyMemberId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RefreshFamilyMemberAccessCodeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membershipsCurrentFamilyMembersFamilyMemberIdAccessCodeActionsRefreshPost(
          familyMemberId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} familyMemberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membershipsCurrentFamilyMembersFamilyMemberIdDelete(
      familyMemberId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membershipsCurrentFamilyMembersFamilyMemberIdDelete(
          familyMemberId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} familyMemberId
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membershipsCurrentFamilyMembersFamilyMemberIdPut(
      familyMemberId: string,
      uNKNOWNBASETYPE?: FamilyMemberModel,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membershipsCurrentFamilyMembersFamilyMemberIdPut(
          familyMemberId,
          uNKNOWNBASETYPE,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membershipsCurrentFamilyMembersGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetFamilyMembersResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membershipsCurrentFamilyMembersGet(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membershipsCurrentFamilyMembersPost(
      uNKNOWNBASETYPE?: FamilyMemberModel,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AddFamilyMemberResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membershipsCurrentFamilyMembersPost(
          uNKNOWNBASETYPE,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membershipsCurrentPaymentMethodGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetMembershipPaymentMethodResult>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membershipsCurrentPaymentMethodGet(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membershipsCurrentSummaryGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetMembershipSummaryResult>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membershipsCurrentSummaryGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membershipsPost(
      data?: CreateMembershipCommand,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateIndividualMembershipCommandResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.membershipsPost(
        data,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * MembershipApi - factory interface
 * @export
 */
export const MembershipApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MembershipApiFp(configuration);
  return {
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsActionsValidateAddressPost(
      address?: ValidateAddressQuery,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .membershipsActionsValidateAddressPost(address, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsActionsValidateDateOfBirthPost(
      data?: ValidateDateOfBirthQuery,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .membershipsActionsValidateDateOfBirthPost(data, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsCurrentActionsUpgradePut(
      options?: any,
    ): AxiosPromise<BuyMembershipResponse> {
      return localVarFp
        .membershipsCurrentActionsUpgradePut(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsCurrentAddressGet(
      options?: any,
    ): AxiosPromise<GetMembershipAddressResult> {
      return localVarFp
        .membershipsCurrentAddressGet(options)
        .then((request) => request(axios, basePath));
    },

    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsCurrentAddressPut(
      uNKNOWNBASETYPE?: UpdateMembershipAddressCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .membershipsCurrentAddressPut(uNKNOWNBASETYPE, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} familyMemberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsCurrentFamilyMembersFamilyMemberIdAccessCodeActionsRefreshPost(
      familyMemberId: string,
      options?: any,
    ): AxiosPromise<RefreshFamilyMemberAccessCodeResponse> {
      return localVarFp
        .membershipsCurrentFamilyMembersFamilyMemberIdAccessCodeActionsRefreshPost(
          familyMemberId,
          options,
        )
        .then((request) => request(axios, basePath));
    },

    /**
     *
     * @param {string} familyMemberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsCurrentFamilyMembersFamilyMemberIdDelete(
      familyMemberId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .membershipsCurrentFamilyMembersFamilyMemberIdDelete(
          familyMemberId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} familyMemberId
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsCurrentFamilyMembersFamilyMemberIdPut(
      familyMemberId: string,
      uNKNOWNBASETYPE?: FamilyMemberModel,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .membershipsCurrentFamilyMembersFamilyMemberIdPut(
          familyMemberId,
          uNKNOWNBASETYPE,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsCurrentFamilyMembersGet(
      options?: any,
    ): AxiosPromise<GetFamilyMembersResponse> {
      return localVarFp
        .membershipsCurrentFamilyMembersGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsCurrentFamilyMembersPost(
      uNKNOWNBASETYPE?: FamilyMemberModel,
      options?: any,
    ): AxiosPromise<AddFamilyMemberResponse> {
      return localVarFp
        .membershipsCurrentFamilyMembersPost(uNKNOWNBASETYPE, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsCurrentPaymentMethodGet(
      options?: any,
    ): AxiosPromise<GetMembershipPaymentMethodResult> {
      return localVarFp
        .membershipsCurrentPaymentMethodGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsCurrentSummaryGet(
      options?: any,
    ): AxiosPromise<GetMembershipSummaryResult> {
      return localVarFp
        .membershipsCurrentSummaryGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membershipsPost(
      data?: CreateMembershipCommand,
      options?: any,
    ): AxiosPromise<CreateIndividualMembershipCommandResponse> {
      return localVarFp
        .membershipsPost(data, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MembershipApi - object-oriented interface
 * @export
 * @class MembershipApi
 * @extends {BaseAPI}
 */
export class MembershipApi extends BaseAPI {
  /**
   *
   * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembershipApi
   */
  public membershipsActionsValidateAddressPost(
    address?: ValidateAddressQuery,
    options?: AxiosRequestConfig,
  ) {
    return MembershipApiFp(undefined)
      .membershipsActionsValidateAddressPost(address, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} familyMemberId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembershipApi
   */
  public membershipsCurrentFamilyMembersFamilyMemberIdDelete(
    familyMemberId: string,
    options?: AxiosRequestConfig,
  ) {
    return MembershipApiFp(undefined)
      .membershipsCurrentFamilyMembersFamilyMemberIdDelete(
        familyMemberId,
        options,
      )
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} familyMemberId
   * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembershipApi
   */
  public membershipsCurrentFamilyMembersFamilyMemberIdPut(
    familyMemberId: string,
    uNKNOWNBASETYPE?: FamilyMemberModel,
    options?: AxiosRequestConfig,
  ) {
    return MembershipApiFp(undefined)
      .membershipsCurrentFamilyMembersFamilyMemberIdPut(
        familyMemberId,
        uNKNOWNBASETYPE,
        options,
      )
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembershipApi
   */
  public membershipsCurrentFamilyMembersGet(options?: AxiosRequestConfig) {
    return MembershipApiFp(undefined)
      .membershipsCurrentFamilyMembersGet(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembershipApi
   */
  public membershipsCurrentAddressPut(
    uNKNOWNBASETYPE?: UpdateMembershipAddressCommand,
    options?: AxiosRequestConfig,
  ) {
    return MembershipApiFp(undefined)
      .membershipsCurrentAddressPut(uNKNOWNBASETYPE, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembershipApi
   */
  public membershipsCurrentFamilyMembersPost(
    uNKNOWNBASETYPE?: FamilyMemberModel,
    options?: AxiosRequestConfig,
  ) {
    return MembershipApiFp(undefined)
      .membershipsCurrentFamilyMembersPost(uNKNOWNBASETYPE, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembershipApi
   */
  public membershipsActionsValidateDateOfBirthPost(
    data?: ValidateDateOfBirthQuery,
    options?: AxiosRequestConfig,
  ) {
    return MembershipApiFp(undefined)
      .membershipsActionsValidateDateOfBirthPost(data, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembershipApi
   */
  public membershipsCurrentActionsUpgradePut(options?: AxiosRequestConfig) {
    return MembershipApiFp(undefined)
      .membershipsCurrentActionsUpgradePut(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembershipApi
   */
  public membershipsCurrentAddressGet(options?: AxiosRequestConfig) {
    return MembershipApiFp(undefined)
      .membershipsCurrentAddressGet(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembershipApi
   */
  public membershipsCurrentPaymentMethodGet(options?: AxiosRequestConfig) {
    return MembershipApiFp(undefined)
      .membershipsCurrentPaymentMethodGet(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembershipApi
   */
  public membershipsCurrentSummaryGet(options?: AxiosRequestConfig) {
    return MembershipApiFp(undefined)
      .membershipsCurrentSummaryGet(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} familyMemberId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembershipApi
   */
  public membershipsCurrentFamilyMembersFamilyMemberIdAccessCodeActionsRefreshPost(
    familyMemberId: string,
    options?: AxiosRequestConfig,
  ) {
    return MembershipApiFp(undefined)
      .membershipsCurrentFamilyMembersFamilyMemberIdAccessCodeActionsRefreshPost(
        familyMemberId,
        options,
      )
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembershipApi
   */
  public membershipsCurrentCardGet(options?: AxiosRequestConfig) {
    return MembershipApiFp(undefined)
      .membershipsCurrentCardGet(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembershipApi
   */
  public membershipsPost(
    data?: CreateMembershipCommand,
    options?: AxiosRequestConfig,
  ) {
    return MembershipApiFp(undefined)
      .membershipsPost(data, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }
}

/**
 * InvitationsApi - functional programming interface
 * @export
 */
export const InvitationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    InvitationsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} accessCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async invitationsAccessCodeActionsAcceptInvitationPost(
      accessCode: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.invitationsAccessCodeActionsAcceptInvitationPost(
          accessCode,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} accessCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async invitationsAccessCodeGet(
      accessCode: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetInvitationResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.invitationsAccessCodeGet(
          accessCode,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * InvitationsApi - factory interface
 * @export
 */
export const InvitationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = InvitationsApiFp(configuration);
  return {
    /**
     *
     * @param {string} accessCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    invitationsAccessCodeActionsAcceptInvitationPost(
      accessCode: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .invitationsAccessCodeActionsAcceptInvitationPost(accessCode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} accessCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    invitationsAccessCodeGet(
      accessCode: string,
      options?: any,
    ): AxiosPromise<GetInvitationResponse> {
      return localVarFp
        .invitationsAccessCodeGet(accessCode, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * InvitationsApi - object-oriented interface
 * @export
 * @class InvitationsApi
 * @extends {BaseAPI}
 */
export class InvitationsApi extends BaseAPI {
  /**
   *
   * @param {string} accessCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvitationsApi
   */
  public invitationsAccessCodeActionsAcceptInvitationPost(
    accessCode: string,
    options?: AxiosRequestConfig,
  ) {
    return InvitationsApiFp(undefined)
      .invitationsAccessCodeActionsAcceptInvitationPost(accessCode, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} accessCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvitationsApi
   */
  public invitationsAccessCodeGet(
    accessCode: string,
    options?: AxiosRequestConfig,
  ) {
    return InvitationsApiFp(undefined)
      .invitationsAccessCodeGet(accessCode, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }
}

/**
 * MembersApi - axios parameter creator
 * @export
 */
export const MembersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMeActionsEmergencyRequestPost: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/members/me/actions/emergency-request`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMeCheckInsActionsResponsePut: async (
      uNKNOWNBASETYPE?: RespondToCheckInRequestCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/members/me/check-ins/actions/response`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        uNKNOWNBASETYPE,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMeCheckInsGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/members/me/check-ins`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMeProfilePersonalDataActionsEmailConfirmationPost: async (
      uNKNOWNBASETYPE?: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/members/me/profile/personal-data/actions/email-confirmation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        uNKNOWNBASETYPE,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMeProfilePersonalDataActionsResendEmailConfirmationPost: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/members/me/profile/personal-data/actions/resend-email-confirmation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMeProfilePersonalDataGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/members/me/profile/personal-data`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMeProfilePersonalDataPut: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/members/me/profile/personal-data`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMeProfilePictureGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/members/me/profile/picture`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMeProfilePicturePut: async (
      file?: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/members/me/profile/picture`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} memberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMemberIdLocationGet: async (
      memberId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'memberId' is not null or undefined
      assertParamExists('membersMemberIdLocationGet', 'memberId', memberId);
      const localVarPath = `/members/{memberId}/location`.replace(
        `{${'memberId'}}`,
        encodeURIComponent(String(memberId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} memberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMemberIdProfilePictureGet: async (
      memberId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'memberId' is not null or undefined
      assertParamExists(
        'membersMemberIdProfilePictureGet',
        'memberId',
        memberId,
      );
      const localVarPath = `/members/{memberId}/profile/picture`.replace(
        `{${'memberId'}}`,
        encodeURIComponent(String(memberId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MembersApi - functional programming interface
 * @export
 */
export const MembersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MembersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membersMeActionsEmergencyRequestPost(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membersMeActionsEmergencyRequestPost(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membersMeCheckInsActionsResponsePut(
      uNKNOWNBASETYPE?: RespondToCheckInRequestCommand,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membersMeCheckInsActionsResponsePut(
          uNKNOWNBASETYPE,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membersMeCheckInsGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetMyPendingCheckInRequestResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membersMeCheckInsGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membersMeProfilePersonalDataActionsEmailConfirmationPost(
      uNKNOWNBASETYPE?: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membersMeProfilePersonalDataActionsEmailConfirmationPost(
          uNKNOWNBASETYPE,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membersMeProfilePersonalDataActionsResendEmailConfirmationPost(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membersMeProfilePersonalDataActionsResendEmailConfirmationPost(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membersMeProfilePersonalDataGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetPersonalDataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membersMeProfilePersonalDataGet(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membersMeProfilePersonalDataPut(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membersMeProfilePersonalDataPut(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membersMeProfilePictureGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membersMeProfilePictureGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membersMeProfilePicturePut(
      file?: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membersMeProfilePicturePut(
          file,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} memberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membersMemberIdLocationGet(
      memberId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetMemberLocationQueryResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membersMemberIdLocationGet(
          memberId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} memberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async membersMemberIdProfilePictureGet(
      memberId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.membersMemberIdProfilePictureGet(
          memberId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * MembersApi - factory interface
 * @export
 */
export const MembersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MembersApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMeActionsEmergencyRequestPost(options?: any): AxiosPromise<void> {
      return localVarFp
        .membersMeActionsEmergencyRequestPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMeCheckInsActionsResponsePut(
      uNKNOWNBASETYPE?: RespondToCheckInRequestCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .membersMeCheckInsActionsResponsePut(uNKNOWNBASETYPE, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMeCheckInsGet(
      options?: any,
    ): AxiosPromise<GetMyPendingCheckInRequestResponse> {
      return localVarFp
        .membersMeCheckInsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMeProfilePersonalDataActionsEmailConfirmationPost(
      uNKNOWNBASETYPE?: any,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .membersMeProfilePersonalDataActionsEmailConfirmationPost(
          uNKNOWNBASETYPE,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMeProfilePersonalDataActionsResendEmailConfirmationPost(
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .membersMeProfilePersonalDataActionsResendEmailConfirmationPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMeProfilePersonalDataGet(
      options?: any,
    ): AxiosPromise<GetPersonalDataResponse> {
      return localVarFp
        .membersMeProfilePersonalDataGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMeProfilePersonalDataPut(options?: any): AxiosPromise<void> {
      return localVarFp
        .membersMeProfilePersonalDataPut(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMeProfilePictureGet(options?: any): AxiosPromise<void> {
      return localVarFp
        .membersMeProfilePictureGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMeProfilePicturePut(file?: any, options?: any): AxiosPromise<void> {
      return localVarFp
        .membersMeProfilePicturePut(file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} memberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMemberIdLocationGet(
      memberId: string,
      options?: any,
    ): AxiosPromise<GetMemberLocationQueryResponse> {
      return localVarFp
        .membersMemberIdLocationGet(memberId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} memberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    membersMemberIdProfilePictureGet(
      memberId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .membersMemberIdProfilePictureGet(memberId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MembersApi - object-oriented interface
 * @export
 * @class MembersApi
 * @extends {BaseAPI}
 */
export class MembersApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembersApi
   */
  public membersMeActionsEmergencyRequestPost(options?: AxiosRequestConfig) {
    return MembersApiFp(undefined)
      .membersMeActionsEmergencyRequestPost(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembersApi
   */
  public membersMeCheckInsActionsResponsePut(
    uNKNOWNBASETYPE?: RespondToCheckInRequestCommand,
    options?: AxiosRequestConfig,
  ) {
    return MembersApiFp(undefined)
      .membersMeCheckInsActionsResponsePut(uNKNOWNBASETYPE, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembersApi
   */
  public membersMeCheckInsGet(options?: AxiosRequestConfig) {
    return MembersApiFp(undefined)
      .membersMeCheckInsGet(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembersApi
   */
  public membersMeProfilePersonalDataActionsEmailConfirmationPost(
    uNKNOWNBASETYPE?: any,
    options?: AxiosRequestConfig,
  ) {
    return MembersApiFp(undefined)
      .membersMeProfilePersonalDataActionsEmailConfirmationPost(
        uNKNOWNBASETYPE,
        options,
      )
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembersApi
   */
  public membersMeProfilePersonalDataActionsResendEmailConfirmationPost(
    options?: AxiosRequestConfig,
  ) {
    return MembersApiFp(undefined)
      .membersMeProfilePersonalDataActionsResendEmailConfirmationPost(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembersApi
   */
  public membersMeProfilePersonalDataGet(options?: AxiosRequestConfig) {
    return MembersApiFp(undefined)
      .membersMeProfilePersonalDataGet(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembersApi
   */
  public membersMeProfilePersonalDataPut(options?: AxiosRequestConfig) {
    return MembersApiFp(undefined)
      .membersMeProfilePersonalDataPut(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembersApi
   */
  public membersMeProfilePictureGet(options?: AxiosRequestConfig) {
    return MembersApiFp(undefined)
      .membersMeProfilePictureGet(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembersApi
   */
  public membersMeProfilePicturePut(file?: any, options?: AxiosRequestConfig) {
    return MembersApiFp(undefined)
      .membersMeProfilePicturePut(file, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} memberId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembersApi
   */
  public membersMemberIdLocationGet(
    memberId: string,
    options?: AxiosRequestConfig,
  ) {
    return MembersApiFp(undefined)
      .membersMemberIdLocationGet(memberId, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {string} memberId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MembersApi
   */
  public membersMemberIdProfilePictureGet(
    memberId: string,
    options?: AxiosRequestConfig,
  ) {
    return MembersApiFp(undefined)
      .membersMemberIdProfilePictureGet(memberId, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }
}

/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} [count]
     * @param {number} [notificationIdGreaterThan]
     * @param {number} [notificationIdLesserThan]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsAllGet: async (
      count?: number,
      notificationIdGreaterThan?: number,
      notificationIdLesserThan?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/notifications/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (notificationIdGreaterThan !== undefined) {
        localVarQueryParameter['notificationIdGreaterThan'] =
          notificationIdGreaterThan;
      }

      if (notificationIdLesserThan !== undefined) {
        localVarQueryParameter['notificationIdLesserThan'] =
          notificationIdLesserThan;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [count]
     * @param {number} [lastNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsGet: async (
      count?: number,
      lastNotificationId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (lastNotificationId !== undefined) {
        localVarQueryParameter['lastNotificationId'] = lastNotificationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [count]
     * @param {number} [notificationIdGreaterThan]
     * @param {number} [notificationIdLesserThan]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsMemberRelatedGet: async (
      count?: number,
      notificationIdGreaterThan?: number,
      notificationIdLesserThan?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/notifications/member-related`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (notificationIdGreaterThan !== undefined) {
        localVarQueryParameter['notificationIdGreaterThan'] =
          notificationIdGreaterThan;
      }

      if (notificationIdLesserThan !== undefined) {
        localVarQueryParameter['notificationIdLesserThan'] =
          notificationIdLesserThan;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} notificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsNotificationIdActionsReadPost: async (
      notificationId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'notificationId' is not null or undefined
      assertParamExists(
        'notificationsNotificationIdActionsReadPost',
        'notificationId',
        notificationId,
      );
      const localVarPath =
        `/notifications/{notificationId}/actions/read`.replace(
          `{${'notificationId'}}`,
          encodeURIComponent(String(notificationId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [count]
     * @param {number} [notificationIdGreaterThan]
     * @param {number} [notificationIdLesserThan]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsRiskAlertsGet: async (
      count?: number,
      notificationIdGreaterThan?: number,
      notificationIdLesserThan?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/notifications/risk-alerts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (notificationIdGreaterThan !== undefined) {
        localVarQueryParameter['notificationIdGreaterThan'] =
          notificationIdGreaterThan;
      }

      if (notificationIdLesserThan !== undefined) {
        localVarQueryParameter['notificationIdLesserThan'] =
          notificationIdLesserThan;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    NotificationsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} [count]
     * @param {number} [notificationIdGreaterThan]
     * @param {number} [notificationIdLesserThan]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsAllGet(
      count?: number,
      notificationIdGreaterThan?: number,
      notificationIdLesserThan?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<NotificationModel>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsAllGet(
          count,
          notificationIdGreaterThan,
          notificationIdLesserThan,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} [count]
     * @param {number} [lastNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsGet(
      count?: number,
      lastNotificationId?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<NotificationModel>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsGet(
          count,
          lastNotificationId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} [count]
     * @param {number} [notificationIdGreaterThan]
     * @param {number} [notificationIdLesserThan]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsMemberRelatedGet(
      count?: number,
      notificationIdGreaterThan?: number,
      notificationIdLesserThan?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<NotificationModel>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsMemberRelatedGet(
          count,
          notificationIdGreaterThan,
          notificationIdLesserThan,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} notificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsNotificationIdActionsReadPost(
      notificationId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsNotificationIdActionsReadPost(
          notificationId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} [count]
     * @param {number} [notificationIdGreaterThan]
     * @param {number} [notificationIdLesserThan]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsRiskAlertsGet(
      count?: number,
      notificationIdGreaterThan?: number,
      notificationIdLesserThan?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<NotificationModel>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsRiskAlertsGet(
          count,
          notificationIdGreaterThan,
          notificationIdLesserThan,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NotificationsApiFp(configuration);
  return {
    /**
     *
     * @param {number} [count]
     * @param {number} [notificationIdGreaterThan]
     * @param {number} [notificationIdLesserThan]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsAllGet(
      count?: number,
      notificationIdGreaterThan?: number,
      notificationIdLesserThan?: number,
      options?: any,
    ): AxiosPromise<Array<NotificationModel>> {
      return localVarFp
        .notificationsAllGet(
          count,
          notificationIdGreaterThan,
          notificationIdLesserThan,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [count]
     * @param {number} [lastNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsGet(
      count?: number,
      lastNotificationId?: number,
      options?: any,
    ): AxiosPromise<Array<NotificationModel>> {
      return localVarFp
        .notificationsGet(count, lastNotificationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [count]
     * @param {number} [notificationIdGreaterThan]
     * @param {number} [notificationIdLesserThan]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsMemberRelatedGet(
      count?: number,
      notificationIdGreaterThan?: number,
      notificationIdLesserThan?: number,
      options?: any,
    ): AxiosPromise<Array<NotificationModel>> {
      return localVarFp
        .notificationsMemberRelatedGet(
          count,
          notificationIdGreaterThan,
          notificationIdLesserThan,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} notificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsNotificationIdActionsReadPost(
      notificationId: number,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .notificationsNotificationIdActionsReadPost(notificationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [count]
     * @param {number} [notificationIdGreaterThan]
     * @param {number} [notificationIdLesserThan]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsRiskAlertsGet(
      count?: number,
      notificationIdGreaterThan?: number,
      notificationIdLesserThan?: number,
      options?: any,
    ): AxiosPromise<Array<NotificationModel>> {
      return localVarFp
        .notificationsRiskAlertsGet(
          count,
          notificationIdGreaterThan,
          notificationIdLesserThan,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
  /**
   *
   * @param {number} [count]
   * @param {number} [notificationIdGreaterThan]
   * @param {number} [notificationIdLesserThan]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public notificationsAllGet(
    count?: number,
    notificationIdGreaterThan?: number,
    notificationIdLesserThan?: number,
    options?: AxiosRequestConfig,
  ) {
    return NotificationsApiFp(undefined)
      .notificationsAllGet(
        count,
        notificationIdGreaterThan,
        notificationIdLesserThan,
        options,
      )
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {number} [count]
   * @param {number} [lastNotificationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public notificationsGet(
    count?: number,
    lastNotificationId?: number,
    options?: AxiosRequestConfig,
  ) {
    return NotificationsApiFp(undefined)
      .notificationsGet(count, lastNotificationId, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {number} [count]
   * @param {number} [notificationIdGreaterThan]
   * @param {number} [notificationIdLesserThan]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public notificationsMemberRelatedGet(
    count?: number,
    notificationIdGreaterThan?: number,
    notificationIdLesserThan?: number,
    options?: AxiosRequestConfig,
  ) {
    return NotificationsApiFp(undefined)
      .notificationsMemberRelatedGet(
        count,
        notificationIdGreaterThan,
        notificationIdLesserThan,
        options,
      )
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {number} notificationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public notificationsNotificationIdActionsReadPost(
    notificationId: number,
    options?: AxiosRequestConfig,
  ) {
    return NotificationsApiFp(undefined)
      .notificationsNotificationIdActionsReadPost(notificationId, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {number} [count]
   * @param {number} [notificationIdGreaterThan]
   * @param {number} [notificationIdLesserThan]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public notificationsRiskAlertsGet(
    count?: number,
    notificationIdGreaterThan?: number,
    notificationIdLesserThan?: number,
    options?: AxiosRequestConfig,
  ) {
    return NotificationsApiFp(undefined)
      .notificationsRiskAlertsGet(
        count,
        notificationIdGreaterThan,
        notificationIdLesserThan,
        options,
      )
      .then((request) => request(globalAxios, BASE_PATH));
  }
}

/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsEnableAllSubscribedCountriesAlertsPut: async (
      body?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/settings/enable-all-subscribed-countries-alerts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsEnableMyCurrentCountryAlertsPut: async (
      body?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/settings/enable-my-current-country-alerts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsEnableMyFamilyMemberLocationAlertsPut: async (
      body?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/settings/enable-my-family-member-location-alerts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    settingsPut: async (
      uNKNOWNBASETYPE?: SettingsModel,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        uNKNOWNBASETYPE,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsMinimalAlertRiskLevelPut: async (
      body?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/settings/minimal-alert-risk-level`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async settingsEnableAllSubscribedCountriesAlertsPut(
      body?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.settingsEnableAllSubscribedCountriesAlertsPut(
          body,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async settingsEnableMyCurrentCountryAlertsPut(
      body?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.settingsEnableMyCurrentCountryAlertsPut(
          body,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async settingsEnableMyFamilyMemberLocationAlertsPut(
      body?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.settingsEnableMyFamilyMemberLocationAlertsPut(
          body,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async settingsGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.settingsGet(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async settingsMinimalAlertRiskLevelPut(
      body?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.settingsMinimalAlertRiskLevelPut(
          body,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    async settingsPut(
      uNKNOWNBASETYPE?: SettingsModel,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.settingsPut(
        uNKNOWNBASETYPE,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SettingsApiFp(configuration);
  return {
    /**
     *
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsEnableAllSubscribedCountriesAlertsPut(
      body?: boolean,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .settingsEnableAllSubscribedCountriesAlertsPut(body, options)
        .then((request) => request(globalAxios, BASE_PATH));
    },
    /**
     *
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsEnableMyCurrentCountryAlertsPut(
      body?: boolean,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .settingsEnableMyCurrentCountryAlertsPut(body, options)
        .then((request) => request(globalAxios, BASE_PATH));
    },
    /**
     *
     * @param {boolean} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsEnableMyFamilyMemberLocationAlertsPut(
      body?: boolean,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .settingsEnableMyFamilyMemberLocationAlertsPut(body, options)
        .then((request) => request(globalAxios, BASE_PATH));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsGet(options?: any): AxiosPromise<SettingsModel> {
      return localVarFp
        .settingsGet(options)
        .then((request) => request(globalAxios, BASE_PATH));
    },
    /**
     *
     * @param {number} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsMinimalAlertRiskLevelPut(
      body?: number,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .settingsMinimalAlertRiskLevelPut(body, options)
        .then((request) => request(globalAxios, BASE_PATH));
    },
    settingsPut(uNKNOWNBASETYPE?: any, options?: any): AxiosPromise<void> {
      return localVarFp
        .settingsPut(uNKNOWNBASETYPE, options)
        .then((request) => request(globalAxios, BASE_PATH));
    },
  };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
  /**
   *
   * @param {boolean} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsApi
   */
  public settingsEnableAllSubscribedCountriesAlertsPut(
    body?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return SettingsApiFp(undefined)
      .settingsEnableAllSubscribedCountriesAlertsPut(body, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {boolean} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsApi
   */
  public settingsEnableMyCurrentCountryAlertsPut(
    body?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return SettingsApiFp(undefined)
      .settingsEnableMyCurrentCountryAlertsPut(body, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {boolean} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsApi
   */
  public settingsEnableMyFamilyMemberLocationAlertsPut(
    body?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return SettingsApiFp(undefined)
      .settingsEnableMyFamilyMemberLocationAlertsPut(body, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsApi
   */
  public settingsGet(options?: AxiosRequestConfig) {
    return SettingsApiFp(undefined)
      .settingsGet(options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {number} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsApi
   */
  public settingsMinimalAlertRiskLevelPut(
    body?: number,
    options?: AxiosRequestConfig,
  ) {
    return SettingsApiFp(undefined)
      .settingsMinimalAlertRiskLevelPut(body, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }

  /**
   *
   * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsApi
   */
  public settingsPut(
    uNKNOWNBASETYPE?: SettingsModel,
    options?: AxiosRequestConfig,
  ) {
    return SettingsApiFp()
      .settingsPut(uNKNOWNBASETYPE, options)
      .then((request) => request(globalAxios, BASE_PATH));
  }
}
