import { Trans } from 'react-i18next';

import { useRemoveGroupMemberModal } from './removeGroupMemberModal.hooks';
import { ActionModal } from '../templates/ActionModal';
import { DescriptionText } from '../templates/styles';

export const REMOVE_GROUP_MEMBER_MODAL_NAME = 'REMOVE_GROUP_MEMBER_MODAL';

export const RemoveGroupMemberModal = () => {
  const { t, name, onConfirm, isLoading, groupName } =
    useRemoveGroupMemberModal();

  return (
    <ActionModal
      title={t('headers.remove-group-member')}
      confirmText={t('buttons.confirm-remove')}
      name={REMOVE_GROUP_MEMBER_MODAL_NAME}
      type="error-filled"
      isLoading={isLoading}
      onConfirm={onConfirm}
    >
      <Trans>
        <DescriptionText>
          {t('phrase.confirm-you-want-remove-member', { name, groupName })}
        </DescriptionText>
      </Trans>
    </ActionModal>
  );
};
