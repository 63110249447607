import Select from 'react-select';

import { useTranslation } from 'react-i18next';

import { useRef } from 'react';

import { MenuList } from './components/MenuList';

import { ValueContainer } from './components/ValueContainer';
import { OptionLabel } from './components/OptionLabel';
import { useMainSelect } from './mainSelect.hooks';

import { selectStyles } from './mainSelect.styles';
import { DropdownIndicator } from './components/DropdownIndicator';
import { MainSelectPropType } from './mainSelect.types';
import { FieldWrapper } from '../Field/Field';
import FieldLabel from '../Field/FieldLabel';
import { IconOption } from './components/IconOption';

export const MainSelect = ({
  optionIcon: isIconVisible = true,
  valueIdSelector,
  isLabelHidden,
  labelAnimation,
  customStyles,
  displayIcon,
  label,
  promiseOptions,
  onScrollClose = true,
  optionComponent: OptionComponent = IconOption,
  ...props
}: MainSelectPropType<any>) => {
  const { t } = useTranslation();

  const { isMulti, options, currentValue, className } = props;

  const { isLoading, valueToRender, selectOptions } = useMainSelect({
    onScrollClose,
    valueIdSelector,
    promiseOptions,
    currentValue,
    options,
    isMulti,
  });

  const components = {
    ValueContainer,
    MenuList,
    DropdownIndicator,
    Option: OptionComponent,
  };
  const refSelectContainer = useRef(null);

  return (
    <FieldWrapper ref={refSelectContainer}>
      {!isLabelHidden && (
        <FieldLabel disabled={false} autoLabelAnimation={labelAnimation}>
          {label}
        </FieldLabel>
      )}
      <Select
        defaultAddButtonText={t('buttons.add-new-option')}
        {...props}
        className={className}
        styles={(customStyles || selectStyles) as any}
        classNamePrefix="mainSelect"
        menuPortalTarget={refSelectContainer.current}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={false}
        captureMenuScroll
        menuShouldScrollIntoView
        isLoading={isLoading}
        options={selectOptions}
        components={components as any}
        formatOptionLabel={(data) =>
          OptionLabel({
            data,
            disabled: props.isDisabled,
            isIconVisible,
            currentValue,
            displayIcon,
          })
        }
        value={valueToRender}
      />
    </FieldWrapper>
  );
};
