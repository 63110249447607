// @flow
import * as React from 'react';
import styled from '@emotion/styled';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export const Column = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
