import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../../../common/utils/mediaQueries';

type FormRowProps = {
  isSubRow?: boolean;
  subRowAdjustWidth?: boolean;
};

export const FormRow = styled.div<FormRowProps>`
  display: flex;
  ${({ isSubRow }) =>
    !isSubRow &&
    css`
      flex-direction: column;
    `}
  width: 100%;
  gap: 2rem;

  ${mq.xSmall(css`
    flex-direction: row;
    gap: 1.5rem;
  `)}

  ${({ isSubRow, subRowAdjustWidth }) =>
    isSubRow &&
    css`
      gap: 1rem;
      ${mq.xSmall(css`
        gap: 1rem;
      `)}
      ${subRowAdjustWidth &&
      css`
        > div {
          width: calc(50% - 0.5rem);
          ${mq.xSmall(css`
            width: 100%;
          `)}
        }
      `}
    `}
`;
