import moment from 'moment';

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
export const DEFAULT_DATE_PARSE = 'YYYY-MM-DD';

export const DEFAULT_SHORT_DATE_FORMAT = 'MM/YY';
export const DEFAULT_SHORT_DATE_PARSE = 'MM-YY';

export const DEFAULT_DATE_MASK = '__/__/____';

export const formatDate = (
  date: string,
  format: string = DEFAULT_DATE_FORMAT.toUpperCase(),
) => moment(date).format(format);

export const formatMomentDate = (
  date: string,
  format: string = DEFAULT_DATE_PARSE.toUpperCase(),
) => moment(date, format, false);

export const parseDate = (
  date: string | moment.Moment,
  format: string = DEFAULT_DATE_PARSE,
) => moment(date, format, false).format(format);

export const isValidDate = (date: string) =>
  new Date(date).toString() !== 'Invalid Date';

export const isDateValid = (date: string) => moment(date).isValid();

export const currentDate = moment().format(DEFAULT_DATE_PARSE);
export const currentYear = moment().year();

export const getOldestDate = (dates: string[]) => {
  if (!dates || dates.length === 0) {
    return '';
  }

  const sortedDates = [...dates].sort(
    (a, b) => moment(a).valueOf() - moment(b).valueOf(),
  );

  return sortedDates[0];
};

export const getAge = (date: string): number | null =>
  date ? moment().diff(date, 'years') : null;

export const compareDates = (aDate, bDate) => {
  var momentA = moment(aDate);
  var momentB = moment(bDate);
  if (momentA > momentB) return 1;
  else if (momentA < momentB) return -1;
  else return 0;
};
