import styled from '@emotion/styled';

import { css } from '@emotion/react';

import { ThumbnailType } from './Thumbnail';

type ThumbnailProps = {
  type?: ThumbnailType;
  active?: boolean;
};

export const ThumbnailCircle = styled.div(
  ({ theme }) => css`
    width: 48px;
    height: 48px;
    background-color: ${theme.color.primary_50};
    border-radius: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
);

export const ThumbnailText = styled.div(
  ({ theme }) => css`
    color: ${theme.color.primary_500};
  `,
);

export const ThumbnailImageWrapper = styled.div<ThumbnailProps>`
  position: relative;
  width: ${({ type, theme }) => {
    switch (type) {
      case 'mini':
        return '32px';
      case 'medium':
        return '40px';
      case 'large':
        return '64px';
      case 'big':
        return '80px';
      case 'huge':
        return '164px';
      default:
        return '48px';
    }
  }};

  min-width: ${({ type, theme }) => {
    switch (type) {
      case 'mini':
        return '32px';
      case 'medium':
        return '40px';
      case 'large':
        return '64px';
      case 'big':
        return '80px';
      case 'huge':
        return '164px';
      default:
        return '48px';
    }
  }};
  height: ${({ type, theme }) => {
    switch (type) {
      case 'mini':
        return '32px';
      case 'medium':
        return '40px';
      case 'large':
        return '64px';
      case 'big':
        return '80px';
      case 'huge':
        return '164px';
      default:
        return '48px';
    }
  }};
  border: ${({ type }) => {
    switch (type) {
      case 'mini':
        return '2px solid #FFFFFF';
      case 'medium':
        return '2px solid #FFFFFF';
      case 'large':
        return '2px solid #FFFFFF';
      default:
        return 'unset';
    }
  }};
  border-radius: ${({ type }) => {
    switch (type) {
      case 'mini':
        return '16px';
      case 'medium':
        return '20px';
      case 'large':
        return '32px';
      case 'huge':
        return '164px';
      default:
        return '10000px';
    }
  }};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CircleWrapper = styled(ThumbnailImageWrapper)`
  border: 1px solid ${({ theme }) => theme.color.white};
  box-shadow: 0 1px 6px rgba(5, 109, 255, 0.05);
`;
export const CircleText = styled.div``;

export const ThumbnailImage = styled.img<ThumbnailProps>`
  width: ${({ type, theme }) => {
    switch (type) {
      case 'mini':
        return '30px';
      case 'medium':
        return '38px';
      case 'large':
        return '62px';
      case 'big':
        return '80px';
      case 'huge':
        return '164px';
      default:
        return '46px';
    }
  }};
  height: ${({ type, theme }) => {
    switch (type) {
      case 'mini':
        return '30px';
      case 'medium':
        return '38px';
      case 'large':
        return '62px';
      case 'big':
        return '80px';
      case 'huge':
        return '164px';
      default:
        return '46px';
    }
  }};
  border-radius: ${({ type }) => {
    switch (type) {
      case 'mini':
        return '16px';
      case 'medium':
        return '20px';
      case 'large':
        return '32px';
      case 'big':
        return '60px';
      case 'huge':
        return '164px';
      default:
        return '24px';
    }
  }};

  border: ${({ type }) => {
    switch (type) {
      case 'mini':
        return '2px solid #FFFFFF';
      case 'medium':
        return '2px solid #FFFFFF';
      case 'large':
        return '2px solid #FFFFFF';
      default:
        return '2px solid #FFFFFF';
    }
  }};
`;

export const ActiveDot = styled.div<ThumbnailProps>`
  border: 2px solid ${({ theme }) => theme.color.white};
  position: absolute;
  right: 0;
  bottom: 0;

  ${({ theme, active }) => css`
    background-color: ${active
      ? theme.color.active
      : theme.color.neutral_2_500};
  `}

  width: ${({ type, theme }) => {
    switch (type) {
      case 'mini':
        return '8px';
      case 'medium':
        return '16px';
      case 'large':
        return '16px';
      default:
        return '16px';
    }
  }};
  height: ${({ type, theme }) => {
    switch (type) {
      case 'mini':
        return '8px';
      case 'medium':
        return '16px';
      case 'large':
        return '16px';
      default:
        return '16px';
    }
  }};
  border-radius: ${({ type }) => {
    switch (type) {
      case 'mini':
        return '8px';
      case 'medium':
        return '16px';
      case 'large':
        return '16px';
      default:
        return '16px';
    }
  }};
`;
