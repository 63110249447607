import { createSelector } from 'reselect';

import { IWizardManagerSate, IWizardStep, Wizard } from './models';
import { RootState } from '../index';

export const wizardSelector = (state: RootState) => state.wizard;

export const currentWizardSelector = createSelector(
  wizardSelector,
  (wizardManager: IWizardManagerSate): Wizard =>
    wizardManager.wizards[wizardManager.activeWizard]!,
);

export const activeWizardSelector = createSelector(
  wizardSelector,
  (wizardManager: IWizardManagerSate): string => wizardManager.activeWizard,
);

export const wizardStepsCountSelector = createSelector(
  currentWizardSelector,
  (wizard: Wizard): number => wizard.steps.length,
);

export const activeStepCnSelector = createSelector(
  currentWizardSelector,
  (wizard: Wizard): string => wizard.activeStep,
);

export const activeStepSelector = createSelector(
  currentWizardSelector,
  (wizard: Wizard): IWizardStep =>
    wizard.steps.find((step) => step.canonicalName === wizard.activeStep)!,
);

export const activeStepDetailsSelector = createSelector(
  activeStepSelector,
  (wizard: IWizardStep): any => wizard.details,
);

export const stepsDetailsSelector = createSelector(
  currentWizardSelector,
  (wizard: Wizard): { [x: string]: any } => {
    let details = { ...wizard.details };
    wizard.steps.forEach((step) => {
      details = { ...details, ...step.details };
    });
    return details;
  },
);

export const activeStepIndexSelector = createSelector(
  currentWizardSelector,
  (wizard: Wizard): number =>
    wizard.steps.findIndex((step) => step.canonicalName === wizard.activeStep),
);

export const wizardStepsSelector = createSelector(
  currentWizardSelector,
  (wizard: Wizard): IWizardStep[] => wizard.steps,
);

export const wizardAvailableStepsSelector = createSelector(
  currentWizardSelector,
  (wizard: Wizard): IWizardStep[] =>
    wizard.steps.filter((x) => x.isAvailable).sort((a, b) => a.order - b.order),
);

export const wizardSortedStepsSelector = createSelector(
  currentWizardSelector,
  (wizard: Wizard): IWizardStep[] =>
    wizard.steps.filter((x) => x).sort((a, b) => a.order - b.order),
);

export const wizardVisibleStepIndexSelector = createSelector(
  activeStepCnSelector,
  wizardAvailableStepsSelector,
  (activeStepCn: string, visibleSteps: IWizardStep[]): number =>
    visibleSteps.findIndex((step) => step.canonicalName === activeStepCn),
);

export const lastVisibleStepSelector = createSelector(
  wizardAvailableStepsSelector,
  (visibleSteps: IWizardStep[]): IWizardStep =>
    visibleSteps[visibleSteps.length - 1],
);

export const lastVisibleStepIndexSelector = createSelector(
  wizardStepsSelector,
  lastVisibleStepSelector,
  (steps: IWizardStep[], lastVisibleStep: IWizardStep): number =>
    steps.lastIndexOf(lastVisibleStep),
);

export const activeStepCaptionSelector = createSelector(
  wizardStepsSelector,
  activeStepIndexSelector,
  (wizardSteps: IWizardStep[], activeStep: number): string =>
    wizardSteps[activeStep].caption,
);

export const activeStepUrlSelector = createSelector(
  wizardStepsSelector,
  activeStepIndexSelector,
  (wizardSteps: IWizardStep[], activeStep: number): string =>
    wizardSteps[activeStep].url,
);

export const moveBackAvailable = createSelector(
  wizardAvailableStepsSelector,
  activeStepCnSelector,
  (steps, currentCn) =>
    steps.findIndex((step) => step.canonicalName === currentCn) > 0,
);
