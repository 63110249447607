import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { call, put, select } from 'typed-redux-saga/macro';

import { updateProcessState } from '../../redux-slices/processes';
import { FriendsApi, GroupsApi, MembershipApi } from '../../../api/base-api';
import { accessCodesActions } from '../../redux-slices/accessCodes';
import {
  FAMILY_MEMBER_ACCESS_CODE_LOADING_PROCESSING,
  FRIENDS_ACCESS_CODE_LOADING_PROCESSING,
  FRIENDS_ACCESS_CODE_REFRESH_PROCESSING,
  GROUP_ACCESS_CODE_LOADING_PROCESSING,
  GROUP_ACCESS_CODE_REFRESH_PROCESSING,
} from '../../redux-slices/processes/constants';
import {
  familyMemberAccessCodeSelector,
  friendAccessCodeSelector,
  groupAccessCodeSelector,
} from '../../redux-slices/accessCodes/selectors';

const accessCodesSlice = createSliceSaga({
  name: 'access-codes-saga',
  caseSagas: {
    *getFriendsAccessCode() {
      const accessCode = yield* select(friendAccessCodeSelector);
      if (accessCode) {
        return yield;
      }

      try {
        yield put(updateProcessState(FRIENDS_ACCESS_CODE_LOADING_PROCESSING));
        const friendsApi = new FriendsApi();
        const { data } = yield* call(friendsApi.friendsAccessCodeGet);
        yield put(
          accessCodesActions.setFriendsAccessCode({
            accessCode: data,
          }),
        );
      } catch (e) {
      } finally {
        yield put(updateProcessState(FRIENDS_ACCESS_CODE_LOADING_PROCESSING));
      }
    },
    *getGroupAccessCode(action) {
      const { id } = action.payload;
      const accessCode = yield* select(groupAccessCodeSelector(id));
      if (accessCode) {
        return yield;
      }

      try {
        yield put(updateProcessState(GROUP_ACCESS_CODE_LOADING_PROCESSING));
        const groupsApi = new GroupsApi();
        const { data } = yield* call(groupsApi.groupsGroupIdAccessCodeGet, id);
        yield put(
          accessCodesActions.setGroupAccessCode({
            groupId: id,
            accessCode: data,
          }),
        );
      } catch (err) {
      } finally {
        yield put(updateProcessState(GROUP_ACCESS_CODE_LOADING_PROCESSING));
      }
    },
    *getFamilyMemberAccessCode(action) {
      const { id } = action.payload;
      const accessCode = yield* select(familyMemberAccessCodeSelector(id));

      if (accessCode) {
        return yield;
      }

      try {
        yield put(
          updateProcessState(FAMILY_MEMBER_ACCESS_CODE_LOADING_PROCESSING),
        );
        const membershipApi = new MembershipApi();
        const { data } = yield* call(
          membershipApi.membershipsCurrentFamilyMembersFamilyMemberIdAccessCodeActionsRefreshPost,
          id,
        );
        yield put(
          accessCodesActions.setFamilyAccessCode({
            memberId: id,
            accessCode: data,
          }),
        );
      } catch (err) {
      } finally {
        yield put(
          updateProcessState(FAMILY_MEMBER_ACCESS_CODE_LOADING_PROCESSING),
        );
      }
    },
    *refreshFriendsAccessCode() {
      try {
        yield put(updateProcessState(FRIENDS_ACCESS_CODE_REFRESH_PROCESSING));
        const friendsApi = new FriendsApi();
        const { data } = yield* call(
          friendsApi.friendsAccessCodeActionsRefreshPost,
        );
        yield put(
          accessCodesActions.setFriendsAccessCode({
            accessCode: data,
          }),
        );
      } catch (e) {
      } finally {
        yield put(updateProcessState(FRIENDS_ACCESS_CODE_REFRESH_PROCESSING));
      }
    },
    *refreshGroupAccessCode(action) {
      try {
        yield put(updateProcessState(GROUP_ACCESS_CODE_REFRESH_PROCESSING));
        const groupsApi = new GroupsApi();
        const { id } = action.payload;
        const { data } = yield* call(
          groupsApi.groupsGroupIdAccessCodeActionsRefreshPost,
          id,
        );
        yield put(
          accessCodesActions.setGroupAccessCode({
            groupId: id,
            accessCode: data,
          }),
        );
      } catch (err) {
      } finally {
        yield put(updateProcessState(GROUP_ACCESS_CODE_REFRESH_PROCESSING));
      }
    },
    *refreshFamilyMemberAccessCode(action) {
      try {
        yield put(updateProcessState(GROUP_ACCESS_CODE_REFRESH_PROCESSING));
        const groupsApi = new GroupsApi();
        const { id } = action.payload;
        const { data } = yield* call(
          groupsApi.groupsGroupIdAccessCodeActionsRefreshPost,
          id,
        );
        yield put(
          accessCodesActions.setGroupAccessCode({
            groupId: id,
            accessCode: data,
          }),
        );
      } catch (err) {
      } finally {
        yield put(updateProcessState(GROUP_ACCESS_CODE_REFRESH_PROCESSING));
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default accessCodesSlice.saga;
export const accessCodesSagaActions = accessCodesSlice.actions;
export const { actions } = accessCodesSlice;
