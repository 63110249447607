import { createSelector } from 'reselect';

import { RootState } from '../index';

export const countriesStateSelector = (state: RootState) => state.countries;

export const countryNameSelector = (countryCode: string) =>
  createSelector(
    countriesStateSelector,
    ({ countries }) =>
      countries.find((country) => country.code === countryCode)?.name,
  );

export const countriesSelector = createSelector(
  countriesStateSelector,
  ({ countries, subscribedCountries }) =>
    countries.filter((country) => !subscribedCountries.includes(country.code)),
);

export const subcribedCountriesSelector = createSelector(
  countriesStateSelector,
  ({ countries, subscribedCountries }) =>
    countries.filter((country) => subscribedCountries.includes(country.code)),
);

export const isSubscribedCountry = (code: string) =>
  createSelector(countriesStateSelector, ({ subscribedCountries }) => {
    return subscribedCountries.includes(code);
  });

export const defaultCountrySelector = createSelector(
  subcribedCountriesSelector,
  countriesSelector,
  (subscribedCountries, countries) =>
    subscribedCountries.length ? subscribedCountries[0] : countries[0],
);
