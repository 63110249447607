import * as React from 'react';
import { Trans } from 'react-i18next';

import { useRemoveEmergencyContactModal } from './removeEmergencyContactModal.hooks';
import { StandardText } from '../../../../../components/Typography/Typography';
import { ActionModal } from '../../../../../components/Modals/templates/ActionModal';

export const REMOVE_EMERGENCY_CONTACT_MODAL_NAME =
  'REMOVE_EMERGENCY_CONTACT_MODAL';

export const RemoveEmergencyContactModal = () => {
  const { t, emergencyContactName, onRemove } =
    useRemoveEmergencyContactModal();

  return (
    <ActionModal
      title={t('headers.remove-emergency-contact')}
      confirmText={t('buttons.confirm-remove')}
      type="error-filled"
      name={REMOVE_EMERGENCY_CONTACT_MODAL_NAME}
      onConfirm={onRemove}
    >
      <Trans>
        <StandardText>
          {t('phrase.confirm-you-want-remove-emergency-contact', {
            emergencyContactName,
          })}
        </StandardText>
      </Trans>
    </ActionModal>
  );
};
