import styled from '@emotion/styled';

import { StandardText } from '../../../Typography/Typography';
import { typography } from '../../../../common/consts/typography';

export const OptionText = styled(StandardText)`
  justify-self: flex-start;
  color: ${({ theme }) => theme.color.neutral_1_12};
  ${typography.bodyS};
`;
