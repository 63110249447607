import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';

import { FormPage } from '../../../../components/UI/Form/Form';
import { Modal } from '../../../../components/Modal/Modal';
import { ModalTitle } from '../../../../components/Modal/modal.styles';
import { FormSubmitButton } from '../../../../components/UI/Form/FormSubmitButton/FormSubmitButton';
import FamilyMemberForm from '../../../../components/Forms/FamilyMemberForm/familyMemberForm.form';
import { validateFamilyMemberForm } from '../../../../components/Forms/FamilyMemberForm/familyMemberForm.validation';
import { useFamilyMemberForm } from '../../../../components/Forms/FamilyMemberForm/familyMemberForm.hooks';

export const EDIT_FAMILY_MEMBER_MODAL_NAME = 'EDIT_FAMILY_MEMBER_MODAL';

export const EditFamilyMemberModal = () => {
  const { t } = useTranslation();

  const { onEditSubmitHandler, isLoading, defaultValues, initValues } =
    useFamilyMemberForm();

  return (
    <Modal name={EDIT_FAMILY_MEMBER_MODAL_NAME}>
      <Form
        keepDirtyOnReinitialize
        onSubmit={onEditSubmitHandler}
        validate={validateFamilyMemberForm}
        initialValues={initValues}
      >
        {({ values, dirty, valid, ...renderProps }) => (
          <FormPage onSubmit={renderProps.handleSubmit}>
            <ModalTitle>{t('headers.edit-family-member')}</ModalTitle>
            <FamilyMemberForm
              disabled={isLoading}
              defaultValues={defaultValues}
            />
            <FormSubmitButton
              fillButton
              isLoading={isLoading}
              styleType="base-filled"
            >
              {isLoading ? t('buttons.processing') : t('buttons.save')}
            </FormSubmitButton>
          </FormPage>
        )}
      </Form>
    </Modal>
  );
};
