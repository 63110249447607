import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetMyPendingCheckInRequestResponseItem } from '../../../api/base-api';
import { StringDict } from '../../../utils/stringDict';

export interface MembersModel {
  names: StringDict<string>;
  checkIns: Array<GetMyPendingCheckInRequestResponseItem>;
}

const initState: MembersModel = {
  names: {},
  checkIns: [],
};

const memberSlice = createSlice({
  name: 'members',
  initialState: initState,
  reducers: {
    setCheckIns: (
      state,
      action: PayloadAction<Array<GetMyPendingCheckInRequestResponseItem>>,
    ) => {
      state.checkIns = action.payload;
    },
    setNames: (state, action: PayloadAction<StringDict<string>>) => {
      state.names = { ...state.names, ...action.payload };
    },
  },
});

export const { setCheckIns, setNames } = memberSlice.actions;
export default memberSlice.reducer;
