import { InputHTMLAttributes, ReactNode } from 'react';

import { CustomField } from '../../../models/types/CustomField';
import {
  Label,
  NativeRadio,
  RadioContainer,
  StyledCheckMark,
  VisibleRadio,
  Wrapper,
} from './radio.styles';

export interface IRadioProps
  extends InputHTMLAttributes<HTMLInputElement>,
    CustomField {
  defaultChecked?: boolean;
  customCheckmark?: any;
  label?: ReactNode | string;
}

const Radio = ({
  checked,
  className,
  defaultChecked,
  customCheckmark: CustomCheckmark,
  disabled,
  label,
  onChange,
  ...props
}: IRadioProps) => (
  <Wrapper className={className}>
    <RadioContainer>
      <NativeRadio
        {...props}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={onChange}
        type="radio"
      />
      <VisibleRadio checked={checked!} disabled={disabled}>
        {checked &&
          (CustomCheckmark ? <CustomCheckmark /> : <StyledCheckMark />)}
      </VisibleRadio>
    </RadioContainer>
    <Label checked={checked!} disabled={disabled}>
      {label}
    </Label>
  </Wrapper>
);

export default Radio;
