import i18next from 'i18next';

import { Wizard } from '../models';
import { RoutePath } from '../../../../models/RoutePath';
import { store } from '../../../../index';
import { signUpActions } from '../../../saga-slices/signUp';

export enum WizardStepsCn {
  AddressPage = 'sign-up-address-page',
  DateOfBirthPage = 'sign-up-date-of-birth-page',
  PaymentPage = 'sign-up-payment-page',
  SetupPage = 'sign-up-setup-page',
  FamilyMembersPage = 'sign-up-family-members-page',
  SuccessPage = 'sign-up-success-page',
}

export const SIGN_UP_WIZARD_NAME = 'SIGN_UP_WIZARD_NAME';

export const signUpWizard: Wizard = {
  steps: [
    {
      order: 0,
      caption: i18next.t('wizard.sign-up.address-page'),
      canonicalName: WizardStepsCn.AddressPage,
      url: RoutePath.SignUpAddressStep,
      allowSkip: false,
      isValid: false,
      isAvailable: true,
      isAuth: false,
    },
    {
      order: 1,
      caption: i18next.t('wizard.sign-up.date-of-birth-page'),
      canonicalName: WizardStepsCn.DateOfBirthPage,
      url: RoutePath.SignUpDateOfBirthStep,
      allowSkip: false,
      isValid: false,
      isAvailable: true,
      isAuth: false,
    },
    {
      order: 2,
      caption: i18next.t('wizard.sign-up.payment-page'),
      canonicalName: WizardStepsCn.PaymentPage,
      url: RoutePath.SignUpPaymentStep,
      allowSkip: false,
      isValid: false,
      isAvailable: true,
      isAuth: false,
    },
    {
      order: 3,
      caption: i18next.t('wizard.sign-up.setup-page'),
      canonicalName: WizardStepsCn.SetupPage,
      url: RoutePath.SignUpSetupAccountStep,
      allowSkip: true,
      isValid: false,
      isAvailable: true,
      isAuth: false,
    },
    {
      order: 4,
      caption: i18next.t('wizard.sign-up.family-members-page'),
      canonicalName: WizardStepsCn.FamilyMembersPage,
      url: RoutePath.SignUpFamilyMembersStep,
      allowSkip: true,
      isValid: false,
      isAvailable: false,
      isAuth: false,
    },
    {
      order: 5,
      caption: i18next.t('wizard.sign-up.success-page'),
      canonicalName: WizardStepsCn.SuccessPage,
      url: RoutePath.SignUpCompleteStep,
      allowSkip: false,
      isValid: false,
      isAvailable: true,
      isAuth: false,
      beforeChange: () => {
        store.dispatch(signUpActions.checkUsageCode());
      },
    },
  ],
  activeStep: WizardStepsCn.AddressPage,
};
