import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AccessState,
  IFamilyAccessCodeUpdate,
  IFriendAccessCodeUpdate,
  IGroupAccessCodeUpdate,
} from './model';

const initState: AccessState = {
  friend: undefined,
  groups: {},
  family: {},
};

const accessCodesSlice = createSlice({
  name: 'access-codes',
  initialState: initState,
  reducers: {
    setFriendsAccessCode: (
      state,
      action: PayloadAction<IFriendAccessCodeUpdate>,
    ) => {
      state.friend = {
        accessCode: action.payload.accessCode.code,
        validTo: action.payload.accessCode.validTo,
      };
    },
    setGroupAccessCode: (
      state,
      action: PayloadAction<IGroupAccessCodeUpdate>,
    ) => {
      state.groups[action.payload.groupId] = {
        accessCode: action.payload.accessCode.code,
        validTo: action.payload.accessCode.validTo,
      };
    },
    setFamilyAccessCode: (
      state,
      action: PayloadAction<IFamilyAccessCodeUpdate>,
    ) => {
      state.family[action.payload.memberId] = {
        accessCode: action.payload.accessCode.accessCode,
        validTo: action.payload.accessCode.validTo,
      };
    },
  },
});

export const accessCodesActions = accessCodesSlice.actions;
export default accessCodesSlice.reducer;
