/* eslint-disable no-continue */

type Props = {
  inputId: string;
  name: string;
  groupName?: string;
};

export const getFieldPropertiesForGroup = ({
  inputId,
  name,
  groupName,
}: Props) => {
  const fieldPath = getFormGroupPath(groupName!, name);
  const fieldId = getFormGroupPath(groupName!, inputId);
  return {
    name: fieldPath,
    inputId: fieldId,
  };
};

export function getFormGroupPath(...names: string[]) {
  const fieldPath = [];

  for (let i = 0; i < names.length; i++) {
    const name = names[i];

    if (!name) continue;
    fieldPath.push(name);
  }

  return fieldPath.join('.');
}

export function getFormGroupId(...names: string[]) {
  const fieldId = [];

  for (let i = 0; i < names.length; i++) {
    const name = names[i];

    if (!name) continue;
    fieldId.push(name);
  }

  return fieldId.join('-');
}
