import { AnySchema } from 'ajv';

import { EmergencyContactFields } from './emergencyContactForm.models';
import { FieldErrorMessage } from '../../../models/interfaces/FieldErrorMessage';
import { CreateGroupCommand } from '../../../api/base-api';
import { validateFormData } from '../../../utils/validations/validateFormData';

export const emergencyContactFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [EmergencyContactFields.FirstName]: {
      type: 'string',
    },
    [EmergencyContactFields.LastName]: {
      type: 'string',
    },
    [EmergencyContactFields.Email]: {
      type: 'string',
      format: 'email',
    },
    [EmergencyContactFields.PhoneNumber]: {
      type: 'string',
      format: 'phone',
    },
  },

  required: [
    EmergencyContactFields.FirstName,
    EmergencyContactFields.LastName,
    EmergencyContactFields.Email,
    EmergencyContactFields.PhoneNumber,
  ],
};

export const validateEmergencyContactForm = (
  data: CreateGroupCommand,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, emergencyContactFormSchema);
