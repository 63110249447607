import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { Alignment } from '../../../models/types/Alignment';
import { typography } from '../../../common/consts/typography';
import { mqMax } from '../../../common/utils/mediaQueries';
import { THEME } from '../../../common/consts/theme';

type StyledInputType = {
  disabled?: boolean;
  readOnly?: boolean;
  alignment?: Alignment;
};

export const StyledInput = styled.input<StyledInputType>`
  color: ${({ theme }) => theme.color.primary_900};
  padding: 0.5rem 1rem;
  border: 0.063rem solid ${({ theme }) => theme.color.neutral_2_400};
  border-radius: 0.75rem;
  box-sizing: border-box;
  z-index: 1;
  background: ${({ theme }) => theme.color.primary_50};
  height: 100%;
  width: 100%;
  opacity: 1;
  ${typography.bodyXS};

  ${mqMax.smallIp(css`
    color: ${THEME.color.white} !important;
    background-color: ${THEME.color.primary_600} !important;
    border-color: ${THEME.color.primary_50} !important;
    color: ${THEME.color.white} !important;
  `)}
  &:focus {
    outline: none;
  }

  ${({ theme, disabled }) =>
    disabled &&
    css`
      color: ${theme.color.primary_900};
    `}

  ${({ readOnly }) =>
    readOnly &&
    css`
      padding: 0;
    `}

  ${({ alignment }) =>
    alignment &&
    css`
      text-align: ${alignment};
    `}
`;
