import moment from 'moment';
import { DEFAULT_EXPIRATION_DATE_CARD_FORMAT } from '../creditCardHelpers';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js/max';

export enum ValidationCustomFormat {
  Phone = 'phone',
  CreditCardExpirationDate = 'creditCardExpirationDate',
}

export const addCustomFormats = (avj: any) => {
  avj.addFormat(ValidationCustomFormat.Phone, preparePhoneFormat());
  avj.addFormat(
    ValidationCustomFormat.CreditCardExpirationDate,
    prepareCreditCardExpirationDateFormat(),
  );
};

const preparePhoneFormat = () => ({
  type: 'string',
  validate: (x: any) => {
    try {
      if (x.length < 5) {
        return false;
      }
      const phoneNumber = parsePhoneNumber(x);

      if (phoneNumber.format('E.164') === x) {
        return isValidPhoneNumber(x);
      }
      return false;
    } catch (e) {
      return false;
    }
  },
});

const prepareCreditCardExpirationDateFormat = () => ({
  type: 'string',
  validate: (x: string) => x.match(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/),
  compare: (currentDate: string, compareDate: string) => {
    if (
      moment(currentDate, DEFAULT_EXPIRATION_DATE_CARD_FORMAT).isBefore(
        moment(compareDate),
        'month',
      )
    )
      return -1;

    if (
      moment(currentDate, DEFAULT_EXPIRATION_DATE_CARD_FORMAT).isAfter(
        moment(compareDate),
        'month',
      )
    )
      return 1;
    return 0;
  },
});
