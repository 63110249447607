import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { THEME } from '../../../../common/consts/theme';
import { mqMax } from '../../../../common/utils/mediaQueries';

type Props = {
  isSelected: boolean;
  disabled?: boolean;
};

export const LabelContainer = styled('div', {
  shouldForwardProp: isPropValid,
})<Props>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${({ theme }) => theme.color.black};
  ${({ theme, disabled }) =>
    disabled &&
    css`
      color: ${theme.color.dark2};
    `};

  ${mqMax.smallIp(css`
    color: ${THEME.color.white};
  `)}
`;
