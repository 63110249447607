import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SettingsModel as NotificationSettingsModel } from '../../../api/base-api';
import { StringDict } from '../../../utils/stringDict';

export type IEndpoint = {
  name: string;
  urls: string[];
};

type SettingsModels = {
  notificationSettings: NotificationSettingsModel;
  endpoints?: StringDict<string[]>;
};

const initState: SettingsModels = {
  notificationSettings: {},
  endpoints: {},
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState: initState,
  reducers: {
    updateNotificationSettings: (state, action: PayloadAction<any>) => {
      state.notificationSettings = action.payload;
    },
    updateEndpoints: (state, action: PayloadAction<Array<IEndpoint>>) => {
      action.payload.map((endpoint) => {
        state.endpoints[endpoint.name] = endpoint.urls;
      });
    },
  },
});

export const { updateNotificationSettings, updateEndpoints } =
  settingsSlice.actions;
export default settingsSlice.reducer;
