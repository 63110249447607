import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NotificationModel, NotificationType } from '../../../api/base-api';

const initState: {
  riskAlertNotifications: Array<NotificationModel & { received?: boolean }>;
  memberRelatedNotifications: Array<NotificationModel & { received?: boolean }>;
  riskAlertFullLoaded: boolean;
  memberRelatedFullLoaded: boolean;
} = {
  riskAlertNotifications: [], // RiskAlertNotificationsMock,
  memberRelatedNotifications: [], // FriendsNotificationsMock,
  memberRelatedFullLoaded: false,
  riskAlertFullLoaded: false,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: initState,
  reducers: {
    addNotifications: (
      state,
      action: PayloadAction<Array<NotificationModel & { received?: boolean }>>,
    ) => {
      const memberRelatedNotifications = action.payload.filter(
        (notify) => !!notify.relatedMemberId,
      );
      const riskAlertNotifications = action.payload.filter(
        (notify) => notify.type === NotificationType.RiskAlert,
      );

      state.memberRelatedNotifications.push(
        ...(memberRelatedNotifications as any),
      );
      state.riskAlertNotifications.push(...(riskAlertNotifications as any));
    },
    addMemberRelatedNotifications: (
      state,
      action: PayloadAction<Array<NotificationModel>>,
    ) => {
      state.memberRelatedNotifications.push(...action.payload);
    },
    addRiskAlertNotifications: (
      state,
      action: PayloadAction<Array<NotificationModel>>,
    ) => {
      state.riskAlertNotifications.push(...action.payload);
    },
    setMemberRelatedFullLoaded: (state) => {
      state.memberRelatedFullLoaded = true;
    },
    setRiskAlertFullLoaded: (state) => {
      state.riskAlertFullLoaded = true;
    },
    setNotificationReaded: (state, action: PayloadAction<number>) => {
      const notificationIndex = state.riskAlertNotifications.findIndex(
        (notify) => notify.id == action.payload,
      );
      if (notificationIndex !== -1) {
        state.riskAlertNotifications[notificationIndex].isRead = true;
      } else {
        const notificationIndex = state.memberRelatedNotifications.findIndex(
          (notify) => notify.id == action.payload,
        );
        if (notificationIndex !== -1) {
          state.memberRelatedNotifications[notificationIndex].isRead = true;
        }
      }
    },
    markReceived: (state, action: PayloadAction<number>) => {
      const notificationIndex = state.riskAlertNotifications.findIndex(
        (notify) => notify.id == action.payload,
      );
      if (notificationIndex !== -1) {
        state.riskAlertNotifications[notificationIndex].received = true;
      } else {
        const notificationIndex = state.memberRelatedNotifications.findIndex(
          (notify) => notify.id == action.payload,
        );
        if (notificationIndex !== -1) {
          state.memberRelatedNotifications[notificationIndex].received = true;
        }
      }
    },
  },
});

export const {
  addNotifications,
  setNotificationReaded,
  markReceived,
  addRiskAlertNotifications,
  addMemberRelatedNotifications,
  setMemberRelatedFullLoaded,
  setRiskAlertFullLoaded,
} = notificationsSlice.actions;
export default notificationsSlice.reducer;
