import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import moment from 'moment';

import {
  AccessCodeModel,
  GetMemberLocationQueryResponse,
  GroupModel,
} from '../../../api/base-api';

type locationSharingType = {
  id: string;
  body: boolean;
};

type SetAccessCodeType = {
  accessCode: AccessCodeModel;
  groupId: string;
};

export type ExtendGroupModel = {
  locationSharing: boolean;
  accessCode: AccessCodeModel;
} & GroupModel;

const initState: { groups: Array<ExtendGroupModel> } = { groups: [] };

const groupsSlice = createSlice({
  name: 'groups',
  initialState: initState,
  reducers: {
    setGroups: (state, action: PayloadAction<Array<GroupModel>>) => {
      state.groups = action.payload.map((group) => group) as ExtendGroupModel[];
    },
    addGroup: (state, action: PayloadAction<GroupModel>) => {
      state.groups.push(action.payload as ExtendGroupModel);
    },
    setLocationSharing: (state, action: PayloadAction<locationSharingType>) => {
      const groupIndex = state.groups.findIndex(
        (group) => group.id === action.payload.id,
      );

      state.groups[groupIndex].locationSharing = action.payload.body;
    },
    setAccessCode: (state, action: PayloadAction<SetAccessCodeType>) => {
      const groupIndex = state.groups.findIndex(
        (group) => group.id === action.payload.groupId,
      );

      state.groups[groupIndex].accessCode = action.payload.accessCode;
    },
    setGroupMemberCheckedIn: (state, action: PayloadAction<string>) => {
      for (const group of state.groups) {
        const memberIndex = group.members!.findIndex(
          (member) => member.id === action.payload,
        );
        if (memberIndex !== -1) {
          group.members![memberIndex].checkInStatus = 'Pending';
        }
      }
    },
    setGroupMemberLocation: (
      state,
      action: PayloadAction<{ [key: string]: GetMemberLocationQueryResponse }>,
    ) => {
      for (const group of state.groups) {
        Object.keys(action.payload).forEach((payloadKey) => {
          const memberIndex = group.members!.findIndex(
            (member) => member.id === payloadKey,
          );
          if (memberIndex !== -1) {
            group.members![memberIndex].recentLocationLastCheckDate =
              moment().format();
          }
        });
      }
    },
    setGroupCheckedIn: (state, action: PayloadAction<Array<string>>) => {
      for (const group of state.groups) {
        const memberIndexes =
          group.members?.reduce<Array<number>>((prevValue, member, index) => {
            if (action.payload.includes(member.id!)) {
              prevValue.push(index);
            }
            return prevValue;
          }, []) || [];

        memberIndexes.forEach((index) => {
          if (group.members) group.members[index].checkInStatus = 'Pending';
        });
      }
    },
  },
});

export const {
  setGroups,
  setLocationSharing,
  setAccessCode,
  setGroupMemberCheckedIn,
  setGroupCheckedIn,
  addGroup,
  setGroupMemberLocation,
} = groupsSlice.actions;
export default groupsSlice.reducer;
