import { useCallback } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useActions } from '../../../hooks/useActions';
import { groupsAction } from '../../../store/saga-slices/groups';
import { RoutePath } from '../../../models/RoutePath';

export const usePassOwnershipForm = () => {
  const leaveGroup = useActions(groupsAction.sendLeaveGroup);
  const passOwnershipGroup = useActions(groupsAction.sendPassOwnershipGroup);
  const navigate = useNavigate();
  const { groupId } = useParams();

  const onLeaveGroupSubmitHandler = useCallback(
    ({ newOwnerId }: any) => {
      leaveGroup({
        newOwnerId,
        groupId: groupId!,
        success: () => navigate(RoutePath.HomeAbsolute),
      });
    },
    [leaveGroup, groupId, navigate],
  );

  const onPassOwnershipSubmitHandler = useCallback(
    ({ newOwnerId }: any) => {
      passOwnershipGroup({ groupId: groupId!, memberId: newOwnerId });
    },
    [passOwnershipGroup, groupId],
  );

  return { onLeaveGroupSubmitHandler, onPassOwnershipSubmitHandler };
};
