import { createSelector } from 'reselect';

import { RootState } from '../index';
import { locationsSelect } from '../locations/selectors';
import { MapMemberModel } from './model';
import { membersNamesSelector } from '../members/selectors';

const mapSelector = (state: RootState) => state.map;

export const coordsSelector = createSelector(
  mapSelector,
  (mapState) => mapState.coords,
);

export const mapMembersSelector = createSelector(
  mapSelector,
  locationsSelect,
  membersNamesSelector,
  (mapState, locations, names) =>
    mapState.members
      .map(
        (memberId) =>
          ({
            id: memberId,
            location: locations[memberId]?.location,
            displayName: names[memberId],
          } as MapMemberModel),
      )
      .filter((x) => !!x?.location) as Array<MapMemberModel>,
);

export const defaultMembersSelector = createSelector(
  mapSelector,
  (mapState) => mapState.defaultMembers,
);
