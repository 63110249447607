import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { all, call, put, select } from 'typed-redux-saga/macro';

import { PayloadAction } from '@reduxjs/toolkit';

import moment from 'moment';

import { MembersApi } from '../../../api/base-api';
import { diffUpdateLocalizationSelector } from '../../redux-slices/members/selectors';
import { setCheckIns, setNames } from '../../redux-slices/members';
import { localizationActions } from '../../redux-slices/locations';
import { photoActions } from '../../redux-slices/pictures';
import { memberPhotoSelector } from '../../redux-slices/pictures/selectors';
import { StringDict } from '../../../utils/stringDict';
import { endpointsSelector } from '../../redux-slices/settings/selectors';

export function* getImageFromUrl(memberId: string, hash?: string) {
  const endpoints = yield* select(endpointsSelector);

  const response = yield fetch(
    `${endpoints.Avatars}/${memberId}${hash ? `?date=${hash}` : ''}`,
    {
      method: 'HEAD',
    },
  );

  return response;
}

function* loadMemberLocalization(memberId: string) {
  try {
    const diff = yield* select(diffUpdateLocalizationSelector(memberId));
    const shouldUpdate = diff > 10;

    if (!shouldUpdate) {
      return;
    }

    const membersApi = new MembersApi();

    const { data } = yield* call(
      membersApi.membersMemberIdLocationGet,
      memberId,
    );

    yield put(
      localizationActions.setLocation({
        memberId,
        ...data,
      }),
    );
  } catch (e) {
    yield put(
      localizationActions.setLocation({
        memberId,
        location: undefined,
        updatedAt: undefined,
      }),
    );
  }
}

export function* getImage(memberId: string) {
  try {
    const memberImageHash = yield* select(memberPhotoSelector(memberId));
    const response = yield* getImageFromUrl(memberId, memberImageHash);

    return response;
  } catch (e) {
    return null;
  }
}

export function* loadMemberPhoto(memberId: string) {
  // const memberHasImage = yield* select(memberPhotoSelector(memberId));
  // if (memberHasImage === undefined) {
  try {
    const response = yield getImage(memberId);
    const hash =
      response?.status === 200 ? moment().toDate().getTime() : undefined;
    yield put(
      photoActions.setHasPhoto({
        memberId,
        imageHash: hash ? `${hash}` : undefined,
      }),
    );
  } catch (e) {}
}

export function* loadMembersPhoto(action: PayloadAction<Array<string>>) {
  try {
    const calls = [];

    for (const id of action.payload) {
      calls.push(call(loadMemberPhoto, id));
    }
    yield* all(calls);
  } catch (e) {
    console.log(e);
  }
}

export function* loadMembersLocalization(action: PayloadAction<Array<string>>) {
  try {
    const calls = [];

    for (const id of action.payload) {
      calls.push(call(loadMemberLocalization, id));
    }
    yield* all(calls);
  } catch (e) {
    console.log(e);
  }
}

export function* loadCheckInRequests() {
  try {
    const membersApi = new MembersApi();

    const { data } = yield* call(membersApi.membersMeCheckInsGet);

    yield put(setCheckIns(data.requests!));
  } catch (e) {
    console.log(e);
  }
}

export function* loadMemberNames(action: PayloadAction<StringDict<string>>) {
  try {
    yield put(setNames(action.payload));
  } catch (e) {
    console.log(e);
  }
}

const membersSlice = createSliceSaga({
  name: 'members-saga',
  caseSagas: {
    loadMembersLocalization,
    loadCheckInRequests,
    loadMembersPhoto,
    loadMemberNames,
  },
  sagaType: SagaType.TakeLatest,
});

export default membersSlice.saga;
export const membersActions = membersSlice.actions;
export const { actions } = membersSlice;
