import { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useActions } from '../../../hooks/useActions';
import { useProcessing } from '../../../hooks/useProcessing';
import { groupsAction } from '../../../store/saga-slices/groups';
import { GROUP_RENAMING_PROCESSING } from '../../../store/redux-slices/processes/constants';
import { modalDetailsSelector } from '../../../store/redux-slices/modals/selectors';
import { RENAME_GROUP_MODAL_NAME } from '../../Modals/RenameGroupModal/RenameGroupModal';
import { validateRenameGroupForm } from './renameGroupForm.validation';
import { useFormErrorsActions } from '../../FormErrorProvider/FormErrorProvider.hooks';

export const useRenameGroupForm = () => {
  const SendRenameGroup = useActions(groupsAction.sendRenameGroup);
  const isLoading = useProcessing(GROUP_RENAMING_PROCESSING);
  const { id, name } = useSelector(
    modalDetailsSelector(RENAME_GROUP_MODAL_NAME),
  );
  const { addErrors, validateErrors } = useFormErrorsActions();

  const validateForm = useCallback(
    (data: any) => validateRenameGroupForm(data, validateErrors(data)),
    [validateErrors],
  );

  const onSubmitHandler = useCallback(
    (values: any) => {
      const payload = {
        groupId: id,
        name: values.name,
        error: (err: any) => {
          addErrors(err, values);
        },
      };

      SendRenameGroup(payload);
    },
    [SendRenameGroup, addErrors, id],
  );

  const defaultValue = useMemo(() => name, [name]);

  return { onSubmitHandler, isLoading, defaultValue, validateForm };
};
