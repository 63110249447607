import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { CustomForm } from '../../../models/types/CustomForm';
import { useCreateGroupForm } from './createGroupForm.hooks';
import { FormSubmitButton } from '../../UI/Form/FormSubmitButton/FormSubmitButton';
import { FormPage } from '../../UI/Form/Form';
import CreateGroupForm from './createGroupForm.form';
import Spinner from '../../Spinner/Spinner';
import { ModalTitle } from '../../Modal/modal.styles';
import FormErrorActivator from '../../FormErrorProvider/FormErrorActivator/FormErrorActivator';

type CreateGroupFormProps = CustomForm & {
  description?: string;
  onSentName?: (name: string) => void;
};

const CreateGroupFormPage = ({ onSentName }: CreateGroupFormProps) => {
  const { t } = useTranslation();

  const { onSubmitHandler, isLoading, validateForm } = useCreateGroupForm({
    onSentName,
  });

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={onSubmitHandler}
      validate={validateForm}
    >
      {({ values, dirty, valid, ...renderProps }) => (
        <FormErrorActivator>
          <FormPage onSubmit={renderProps.handleSubmit}>
            <ModalTitle>{t('headers.create-new-group')}</ModalTitle>
            <CreateGroupForm disabled={isLoading} />
            <FormSubmitButton
              isLoading={isLoading}
              disabled={!valid}
              fillButton
              styleType="base-filled"
            >
              <Spinner isVisible={isLoading} />
              {t('buttons.create')}
            </FormSubmitButton>
          </FormPage>
        </FormErrorActivator>
      )}
    </Form>
  );
};

export default CreateGroupFormPage;
