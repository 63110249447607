import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';

import { useFamilyMemberForm } from '../../../../../components/Forms/FamilyMemberForm/familyMemberForm.hooks';
import { Modal } from '../../../../../components/Modal/Modal';
import { validateFamilyMemberForm } from '../../../../../components/Forms/FamilyMemberForm/familyMemberForm.validation';
import { FormPage } from '../../../../../components/UI/Form/Form';
import { ModalTitle } from '../../../../../components/Modal/modal.styles';
import FamilyMemberForm from '../../../../../components/Forms/FamilyMemberForm/familyMemberForm.form';
import { FormSubmitButton } from '../../../../../components/UI/Form/FormSubmitButton/FormSubmitButton';

export const FAMILY_MEMBER_MODAL_NAME = 'ADD_FAMILY_MEMBER_MODAL';

export const AddFamilyMemberModal = () => {
  const { t } = useTranslation();

  const { onAddSubmitHandler, isLoading } = useFamilyMemberForm();

  return (
    <Modal name={FAMILY_MEMBER_MODAL_NAME}>
      <Form
        keepDirtyOnReinitialize
        onSubmit={onAddSubmitHandler}
        validate={validateFamilyMemberForm}
      >
        {({ values, dirty, valid, ...renderProps }) => (
          <FormPage onSubmit={renderProps.handleSubmit}>
            <ModalTitle>{t('headers.add-family-member')}</ModalTitle>
            <FamilyMemberForm disabled={isLoading} />
            <FormSubmitButton
              fillButton
              isLoading={isLoading}
              styleType="base-filled"
            >
              {t('buttons.save')}
            </FormSubmitButton>
          </FormPage>
        )}
      </Form>
    </Modal>
  );
};
