import { createSelector } from 'reselect';

import { RootState } from '../index';
import { userSelector } from '../user/selectors';
import { MemberModel } from '../../../api/base-api';

const stateFriendsSelector = (state: RootState) => state.friends;

export const friendsSelector = createSelector(
  stateFriendsSelector,
  userSelector,
  (friendsState, user): MemberModel[] =>
    friendsState.friends.filter((friend) => friend.id !== user.id),
);

export const friendSelector = (friendId: string) =>
  createSelector(friendsSelector, (friends) =>
    friends.find((friend) => friend.id === friendId),
  );

export const friendIdsSelector = createSelector(friendsSelector, (friends) =>
  friends.map((friend) => friend.id!),
);

export const hasMoreFriendsSelector = createSelector(
  stateFriendsSelector,
  (friends) => !friends.friendsFullLoaded,
);
