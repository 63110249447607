import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CheckInModel } from './model';

export interface ICheckInStatus {
  status: CheckInModel;
  memberId: string;
}

const initState: { [x: string]: CheckInModel } = {};

const checkInSlice = createSlice({
  name: 'check-in',
  initialState: initState,
  reducers: {
    setCheckIn: (state, action: PayloadAction<ICheckInStatus>) => {
      state[action.payload.memberId!] = action.payload.status;
    },
    setCheckIns: (state, action: PayloadAction<Array<ICheckInStatus>>) => {
      action.payload.forEach((status) => {
        state[status.memberId] = status.status;
      });
    },
  },
});

export const checkInActions = checkInSlice.actions;
export default checkInSlice.reducer;
