import { Field } from 'react-final-form';

import { CustomForm } from '../../../models/types/CustomForm';
import { SectionWrapper } from '../../UI/Form/Form';
import { FormRow } from '../../UI/Form/FormRow/FormRow';
import {
  Column,
  Divider,
  DividerHorizontal,
  NotificationFormColumn,
} from './notificationSettingsForm.styles';
import { useNotificationSettingsModal } from '../../Modals/NotificationSettingsModal/notificationSettingsModal.hooks';
import FormSwitch from '../../UI/Form/FormSwitch/FormSwitch';
import { SectionGroupTitle } from '../../Modals/NotificationSettingsModal/notificationSettingsModal.styles';
import { NotificationSettingsFields } from './notificationSettingsForm.models';
import FormTabOptions from '../../UI/Form/FormTabOptions/FormTabOptions';

type CreateGroupFormProps = CustomForm & {
  name?: string;
};

const NotificationSettingsForm = ({
  defaultValue,
  className,
  disabled,
  name,
}: CreateGroupFormProps) => {
  const {
    minimalAlertRiskLevel,
    allSubscribedCountriesEnabled,
    currentCountryEnabled,
    familyMembersLocationEnabled,
  } = useNotificationSettingsModal();

  return (
    <SectionWrapper>
      <NotificationFormColumn className={className}>
        <Column>
          <SectionGroupTitle>Minimal Risk Level</SectionGroupTitle>
          <FormRow>
            <Field
              options={[1, 2, 3, 4].map((value) => ({
                label: value,
                value,
              }))}
              defaultValue={minimalAlertRiskLevel}
              component={FormTabOptions}
              divider={() => <Divider />}
              name={NotificationSettingsFields.MinimalRiskLevel}
            />
          </FormRow>
        </Column>
        <Column>
          <SectionGroupTitle>Include</SectionGroupTitle>
          <FormRow>
            <Field
              type="checkbox"
              component={FormSwitch}
              defaultChecked={currentCountryEnabled}
              name={NotificationSettingsFields.CurrentCountry}
            />
          </FormRow>
        </Column>
        <DividerHorizontal />
        <FormRow>
          <Field
            type="checkbox"
            component={FormSwitch}
            defaultChecked={allSubscribedCountriesEnabled}
            name={NotificationSettingsFields.SubscribeCountry}
          />
        </FormRow>
        <DividerHorizontal />
        <FormRow>
          <Field
            type="checkbox"
            component={FormSwitch}
            defaultChecked={familyMembersLocationEnabled}
            name={NotificationSettingsFields.FamilyMemberLocation}
          />
        </FormRow>
      </NotificationFormColumn>
    </SectionWrapper>
  );
};

export default NotificationSettingsForm;
