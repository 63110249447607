import { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useAlert } from 'react-alert';

import { useTranslation } from 'react-i18next';

import { useActions } from '../../../hooks/useActions';
import { useProcessing } from '../../../hooks/useProcessing';
import { EMERGENCY_CONTACTS_PROCESSING } from '../../../store/redux-slices/processes/constants';

import { modalDetailsSelector } from '../../../store/redux-slices/modals/selectors';
import { emergencyContactActions } from '../../../store/saga-slices/emergencyContacts';
import { EMERGENCY_CONTACT_MODAL_NAME } from '../../../pages/profile/ProfilePage/Modals/AddEmergencyContactModal/AddEmergencyContactModal';
import { EDIT_EMERGENCY_CONTACT_MODAL_NAME } from '../../../pages/profile/ProfilePage/Modals/EditEmergencyContactModal/EditEmergencyContactModal';

export const useEmergencyContactForm = () => {
  const addEmergencyContact = useActions(
    emergencyContactActions.sendAddEmergencyContact,
  );
  const editEmergencyContact = useActions(
    emergencyContactActions.sendEditEmergencyContact,
  );
  const isLoading = useProcessing(EMERGENCY_CONTACTS_PROCESSING);
  const { id } = useSelector(
    modalDetailsSelector(EMERGENCY_CONTACT_MODAL_NAME),
  );
  const { defaultValues } = useSelector(
    modalDetailsSelector(EDIT_EMERGENCY_CONTACT_MODAL_NAME),
  );
  const { success } = useAlert();
  const { t } = useTranslation();

  const onAddSubmitHandler = useCallback(
    ({ firstName, lastName, email, phoneNumber }: any) => {
      addEmergencyContact({
        emergencyContact: {
          firstName,
          lastName,
          email,
          phoneNumber,
        },
        success: () => {
          success(
            t('phrase.emergency-contact-added-success', {
              name: `${firstName} ${lastName}`,
            }),
          );
        },
      });
    },
    [addEmergencyContact, success, t],
  );

  const onEditSubmitHandler = useCallback(
    ({ firstName, lastName, email, phoneNumber }: any) => {
      editEmergencyContact({
        emergencyContact: {
          id: defaultValues.id,
          firstName,
          lastName,
          email,
          phoneNumber,
        },
        success: () => {
          // eslint-disable-next-line no-restricted-globals
          success(t('phrase.emergency-contact-added-success', { name }));
        },
      });
    },
    [editEmergencyContact, id, success, t],
  );

  // eslint-disable-next-line no-restricted-globals
  const defaultValue = useMemo(() => name, [name]);

  return {
    onAddSubmitHandler,
    onEditSubmitHandler,
    isLoading,
    defaultValue,
    defaultValues,
  };
};
