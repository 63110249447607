import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MapModel {
  coords: google.maps.LatLngLiteral;
  members: Array<string>;
  defaultMembers: Array<string>;
}

const initState: MapModel = {
  coords: { lat: 0, lng: 0 },
  members: [],
  defaultMembers: [],
};

const mapSlice = createSlice({
  name: 'map',
  initialState: initState,
  reducers: {
    setCoords: (state, action: PayloadAction<google.maps.LatLngLiteral>) => {
      state.coords = action.payload;
    },
    setMembers: (state, action: PayloadAction<Array<string>>) => {
      state.members = action.payload;
    },
    setDefaultMembers: (state, action: PayloadAction<Array<string>>) => {
      state.defaultMembers = action.payload;
    },
  },
});

export const { setCoords, setMembers, setDefaultMembers } = mapSlice.actions;
export default mapSlice.reducer;
