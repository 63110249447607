export const getFirstAndLastName = (displayName: string) => {
  const fullName = displayName.split(' ');
  if (fullName.length < 2) {
    return { firstName: displayName };
  }

  return {
    firstName: fullName[0],
    lastName: fullName[1],
  };
};
