import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';

import { memberByIdSelector } from '../../store/redux-slices/members/selectors';
import { stringToHslColor } from '../../utils/stringToHsl';
import hashCode from '../../utils/hashCode';
import { locationSelector } from '../../store/redux-slices/locations/selectors';
import { memberPhotoSelector } from '../../store/redux-slices/pictures/selectors';
import { endpointsSelector } from '../../store/redux-slices/settings/selectors';

export const useThumbnail = (id: string | number) => {
  const member = useSelector(memberByIdSelector(id));
  const memberPhotoHash = useSelector(memberPhotoSelector(`${id}`));

  const memberLocation = useSelector(locationSelector(`${id}`));
  const endpoints = useSelector(endpointsSelector);
  const image = useMemo(() => {
    if (typeof id == 'number') {
      return id;
    }
    if (memberPhotoHash) {
      return `${endpoints.Avatars}/${id}?date=${memberPhotoHash}`;
    }
    return null;
  }, [id, memberPhotoHash, endpoints]);

  const splitedName = useMemo(() => member?.displayName?.split(' '), [member]);

  const shortName = useMemo(
    () =>
      splitedName
        ?.map((chars) => chars[0])
        .join('')
        .toUpperCase(),
    [splitedName],
  );

  const backgroundColor = useMemo(() => {
    const hashSource = splitedName
      ?.map((part, index) => (index == 0 ? part[0] : part[part.length - 1]))
      .join('');
    return (
      (hashSource && stringToHslColor(`${hashCode(hashSource)}`, 80, 80)) || ''
    );
  }, [splitedName]);
  const textColor = useMemo(() => {
    const hashSource = splitedName
      ?.map((part, index) => (index == 0 ? part[0] : part[part.length - 1]))
      .join('');
    return (
      (hashSource && stringToHslColor(`${hashCode(hashSource)}`, 80, 50)) || ''
    );
  }, [splitedName]);

  const isActive = useMemo(() => {
    const now = moment();
    const updatedAt =
      memberLocation?.updatedAt && moment(memberLocation?.updatedAt);

    return (updatedAt && now.diff(updatedAt, 'hour') <= 24) || false;
  }, [memberLocation]);

  return {
    image,
    shortName,
    backgroundColor,
    color: textColor,
    isActive,
  };
};
