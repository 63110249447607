import { useActions } from './hooks/useActions';
import { useEffect, useRef } from 'react';
import { continuouslyActions } from './store/saga-slices/continuously';
import { settingsActions } from './store/saga-slices/settings';

export const useApp = () => {
  const actionId = useRef<number>();
  const loadNotifications = useActions(continuouslyActions.loadNotifications);
  const loadCheckIns = useActions(continuouslyActions.loadCheckIns);
  const loadEndpoints = useActions(settingsActions.loadEndpoints);

  useEffect(() => {
    loadNotifications();
    loadCheckIns();
    loadEndpoints();
  }, []);

  return {};
};
