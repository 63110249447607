import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../common/utils/mediaQueries';

export const PageTitle = styled.h1`
  text-align: center;
  font: normal normal 1.5rem/2.25rem ${({ theme }) => theme.font.poppins};
  color: ${({ theme }) => theme.color.primary};
  letter-spacing: 0.08em;

  ${mq.small(css`
    letter-spacing: 0.11em;
  `)};

  ${({ theme }) =>
    mq.xSmall(css`
      font: normal normal 2rem/2.5rem ${theme.font.poppins};
    `)};
`;

export const SectionTitle = styled.h2`
  font: normal 500 1.5rem/2rem ${({ theme }) => theme.font.poppins};
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.color.neutral_2_900};
`;

export const SectionSubTitle = styled.h3`
  font: normal bold 1rem/1.5rem ${({ theme }) => theme.font.poppins};
  color: ${({ theme }) => theme.color.white};
  letter-spacing: 0.031rem;
`;

export const StandardText = styled.p`
  font: normal normal 1rem/1.5rem ${({ theme }) => theme.font.poppins};
  color: ${({ theme }) => theme.color.neutral_2_700};
  letter-spacing: 0.5px;
  white-space: pre;
`;

export const StandardTextBold = styled.p`
  font: normal 500 1rem/1.5rem ${({ theme }) => theme.font.poppins};
  color: ${({ theme }) => theme.color.neutral_1_13};
`;
