import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import moment from 'moment';

import {
  GetMemberLocationQueryResponse,
  MemberModel,
} from '../../../api/base-api';

const initState: { friends: Array<MemberModel>; friendsFullLoaded: boolean } = {
  friends: [],
  friendsFullLoaded: false,
};

const friendsSlice = createSlice({
  name: 'friends',
  initialState: initState,
  reducers: {
    setFriends: (state, action: PayloadAction<Array<MemberModel>>) => {
      state.friends = action.payload;
    },
    setFriendCheckedIn: (state, action: PayloadAction<string>) => {
      const friendIndex = state.friends.findIndex(
        (friend) => friend.id === action.payload,
      );
      if (friendIndex !== -1) {
        state.friends[friendIndex].checkInStatus = 'Pending';
      }
    },
    updateFriends: (state, action: PayloadAction<MemberModel>) => {
      state.friends.push(action.payload);
    },
    setFriendsFullLoaded: (state) => {
      state.friendsFullLoaded = true;
    },
    setFriendLocation: (
      state,
      action: PayloadAction<{ [key: string]: GetMemberLocationQueryResponse }>,
    ) => {
      Object.keys(action.payload).forEach((payloadKey) => {
        const friendIndex = state.friends.findIndex(
          (friend) => friend.id === payloadKey,
        );
        if (friendIndex !== -1) {
          state.friends[friendIndex].recentLocationLastCheckDate =
            moment().format();
        }
      });
    },
    setFriendsCheckedIn: (state, action: PayloadAction<Array<string>>) => {
      const friendIndexes = state.friends.reduce<Array<number>>(
        (prevValue, friend, index) => {
          if (action.payload.includes(friend.id!)) {
            prevValue.push(index);
          }

          return prevValue;
        },
        [],
      );

      friendIndexes.forEach((index) => {
        state.friends[index].checkInStatus = 'Pending';
      });
    },
    removeFriend: (state, action) => {
      state.friends = state.friends.filter(
        (friend) => friend.id !== action.payload,
      );
    },
  },
});

export const {
  setFriends,
  setFriendCheckedIn,
  setFriendsCheckedIn,
  setFriendLocation,
  setFriendsFullLoaded,
  removeFriend,
  updateFriends,
} = friendsSlice.actions;
export default friendsSlice.reducer;
