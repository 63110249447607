import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CountryBriefIntelligence } from '../../../api/base-api';
import { IAddCountryBriefPayload } from './model';

interface CountyBriefState {
  [x: string]: CountryBriefIntelligence;
}

const initState: CountyBriefState = {};

const countryBriefSlice = createSlice({
  name: 'country-brief',
  initialState: initState,
  reducers: {
    addCountryBrief: (
      state,
      action: PayloadAction<IAddCountryBriefPayload>,
    ) => {
      state[action.payload.countryCode] = action.payload.countryBrief;
    },
  },
});

export const countryBriefActions = countryBriefSlice.actions;
export default countryBriefSlice.reducer;
